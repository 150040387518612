import GrfStrategy from './GrfStrategy';

export default class GrfBookingStrategy extends GrfStrategy {

    constructor() {
        super();

        this.state_fill_colors = {
            //bookable states in the API
            'bookable':         'none',
            //'bookable':             '#4488aa',
            'booked':           '#eeeeee',
            'nonbookable':      '#dddddd',
            'reserved':         '#996666',
            //in cart
            'inMyCart':         '#0044aa',
            // diagnostic: intermediate between selection and cart operation
            'addingToCart':     '#0044aa', // or more eye-catching green ='#00FF00',
            'removingFromCart': 'none', // // or more eye-catching red = '#FF0000',
        }

        this.state_fill_opacity = {
            //bookable states in the API
            'bookable':         'none',
            //'bookable':            1,
            'booked':           'none',
            'nonbookable':      'none',
            'reserved':         'none',
            //in cart
            'inMyCart':         'none',
            // diagnostic: intermediate between selection and cart operation
            'addingToCart':     0.9,
            'removingFromCart': 0.9,
        }

        this.state_stroke_colors = {
            //bookable states in the API
            'bookable':         'none',
            //'bookable':         '#008800',
            'booked':           '#880000',
            'nonbookable':      '#880088',
            'reserved':         '#000088',
            //in cart
            'inMyCart':         '#0044aa',
            // diagnostic: intermediate between selection and cart operation
            'addingToCart':     '#00FF00',
            'removingFromCart': '#FF0000',
        }
    }

    fillColor(op) {
        const symbol = this.bottom();

        if (symbol) {
            const state = symbol.getAttribute('bookableState')

            if (state in this.state_fill_colors) {
                const fillColor = this.state_fill_colors[state];
                if (fillColor && fillColor !== 'none')
                    return fillColor;
            }
        }

        return super.fillColor(op);
    }

    fillOpacity(op) {
        const symbol = this.bottom();

        if (symbol) {
            const state = symbol.getAttribute('bookableState')

            if (state in this.state_fill_opacity) {
                const fillOpacity = this.state_fill_opacity[state];
                if (fillOpacity && fillOpacity !== 'none')
                    return fillOpacity;
            }
        }

        return super.fillOpacity(op);
    }

    strokeColor(op) {
        const symbol = this.bottom();

        if (symbol) {
            const state = symbol.getAttribute('bookableState')

            if (state in this.state_stroke_colors) {
                const strokeColor = this.state_stroke_colors[state];
                if (strokeColor && strokeColor !== 'none')
                    return strokeColor;
            }
        }
        
        return super.strokeColor(op);
    }
}
