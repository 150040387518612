import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { TGALoader } from 'three/examples/jsm/loaders/TGALoader';
import OpObject from '@/visual-events/model/OpObject';
import OpFactory from '@/visual-events/model/OpFactory';
import { THREE_TO_OP } from '@/visual-events/model/OpCoordinates.js';

import visualEvents from '@/visual-events/VisualEvents';
import { FileServiceTextureLoader } from './FileServiceTextureLoader';

export default class CADdy3DLoader {
    
    static load3D(model, url) {
        let scope = model;

        let urlModel = url + '/model.json';
        let urlLight = url + '/light.json';

        let promise = new Promise(
            function (resolve, reject) {
                let loader3D = new CADdy3DLoader();

                loader3D.loadModel(urlModel).then(
                    json => {
                        loader3D.model = json;
                        loader3D.initContent(json);
                        return loader3D.loadMeshes(json, url);
                    }
                ).then(
                    data => {

                        const json = data.json;
                        const meshes = data.meshes;
                        
                        const result = loader3D.buildOpObjects(json, meshes);

                        result.symbols.forEach(s => {
                            scope.symbols[s.filename] = s;
                        });
                        scope.space = result.space;

                        return loader3D.loadLight(urlLight);
                    }
                ).then(
                    light => {
                        scope.light = light;
                        resolve();
                    }
                ).catch(
                    function () {
                        reject();
                    }
                )
            }
        );
        return promise;
    }


    constructor() {

        /** @type {OpObject[]} */
        this.model = [];
        
        /** @type {ObjectInformation[]} */
        this.objectInformation = [];

    }

    async loadModel(url, onProgress) {
        const fileLoader = new THREE.FileLoader();
        fileLoader.requestHeader = {
            'x-api-key': visualEvents.apiKey,
            'VisualEvents-ApiKey': visualEvents.apiKey,
            'VisualEvents-Language': visualEvents.language
        };
      
        const data = await fileLoader.loadAsync(url, onProgress);

        const json = JSON.parse(data);
        return json;
    }


    loadModelFile(url) {

        var scope = this;

        let promise = new Promise(
            function (resolve, reject) {
                const fileLoader = new THREE.FileLoader();
                fileLoader.requestHeader = {
                    'x-api-key': visualEvents.apiKey,
                    'VisualEvents-ApiKey': visualEvents.apiKey,
                    'VisualEvents-Language': visualEvents.language
                };
              
                fileLoader.loadAsync(url).then(
                    data => {
                        const json = JSON.parse(data);
                        scope.initContent(json);
                        resolve(json);
                    }
                ).catch(
                    err => {
                        console.error('CADdy3DLoader::loadModelFile File ' + url + ' could not be loaded: ' + err);
                        reject();
                    }
                );
            }
        );

        return promise;
    }
   
    loadMeshes(json, url) {

        var scope = this;

        let promise = new Promise(

            function (resolve, reject) {

                const meshes = {};

                scope.objectInformation.forEach((info) => {

                    var requestHeaders = {
                        'x-api-key': visualEvents.apiKey,
                        'VisualEvents-ApiKey': visualEvents.apiKey,
                        'VisualEvents-Language': visualEvents.language
                    };

                    var fileServiceImageLoader = new FileServiceTextureLoader();
                    fileServiceImageLoader.setRequestHeader(requestHeaders);

                    var manager = new THREE.LoadingManager();
                    manager.addHandler(/\.tga$/i, new TGALoader());
                    manager.addHandler(/\.jpg$/i, fileServiceImageLoader);
                    manager.addHandler(/\.png$/i, fileServiceImageLoader);
        
                    var gltfLoader = new GLTFLoader(manager);
                    gltfLoader.requestHeader = requestHeaders;
                    gltfLoader.loadAsync(url + '/' + info.filename).then(
                        gltf => {
        
                            info.loaded = true;
                            
                            const finalObject = scope.traverseGltf(gltf);
    
                            const t = scope.toMatrix4(info.transform);
                            t.invert();

                            finalObject.applyMatrix4(THREE_TO_OP);
                            finalObject.applyMatrix4(t);
    
                            meshes[info.filename] = finalObject;
    
                            if (scope._checkLoaded()) {
                                resolve({ json: json, meshes: meshes });
                            }
                        }
                    ).catch(
                        err => {
                            reject();
                        }
                    );
        
                });

            }

        );

        return promise;

    }

    traverseGltf(gltf) {

        var castShadow = true;
        var finalObject = new THREE.Object3D();

        /**
         * @param {Mesh} child
        */
        gltf.scene.traverse((child) => {

            if (child.isMesh) {

                // var objectBoundingBox = new Box3().setFromObject(child);
                // scope.boundingBox = scope.boundingBox.union(objectBoundingBox);

                if (Array.isArray(child.material)) {

                    child.material.forEach((mat) => {

                        if (mat.transparent)
                            castShadow = false;

                    });

                } else {

                    if (child.material.transparent)
                        castShadow = false;

                }

                var clonedMesh = child.clone();

                if (castShadow) {

                    clonedMesh.castShadow = true;
                    clonedMesh.receiveShadow = true;

                }

                finalObject.add(clonedMesh);

            }

        });

        if (castShadow) {
        
            finalObject.castShadow = true;
            finalObject.receiveShadow = true;

        }

        return finalObject;
    }

    buildOpObjects(json, meshes) {

        const space = new OpObject('XOpSpace', '3D space');
        const symbols = [];

        json.forEach(item => {

            this._buildOpObjects(item, meshes, space, symbols);

        })

        return { space : space, symbols : symbols };
    }

    _buildOpObjects(item, meshes, parent, symbols) {

        if (item.type == 'XOpGroup') {

            const group = OpFactory.createGroup(item.name);
            group.attributes = this.prepareUserAttributes(item.attributes);

            item.children.forEach((innerChild) => {

                this._buildOpObjects(innerChild, meshes, group, symbols);

            });

            parent.add(group);

        }

        if (item.type == 'XOpSolid3Plain') {

            const mesh = meshes[item.filename];
            const op = OpFactory.createMesh(item.type, item.name, mesh);
            op.attributes = this.prepareUserAttributes(item.attributes);
            op.setTransform(this.toMatrix4(item.transform));
            parent.add(op);

        }

        if (item.type == 'XOpSolid3Feature') {

            const mesh = meshes[item.filename];
            const op = OpFactory.createMesh(item.type, item.name, mesh);
            op.attributes = this.prepareUserAttributes(item.attributes);
            op.setTransform(this.toMatrix4(item.transform));
            parent.add(op);

        }

        if (item.type == 'XOpSceneItem') {

            const mesh = OpFactory.createReference(item.name, item.refId, this.toMatrix4(item.transform));
            mesh.attributes = this.prepareUserAttributes(item.attributes);
            parent.add(mesh);

            if (!symbols.find(x => x.filename === item.refId)) {

                const symbol = OpFactory.createSymbol(item.name, item.refId);

                const mesh = meshes[item.filename];
                const op = OpFactory.createMesh(item.type, item.name, mesh);
                symbol.add(op);

                symbols.push(symbol);
            }

        }
    }

    /**
     * the CADdy++ user attributes contain either simple strings or strings which represent json objects.
     * 
     * In model.json the user attributes are stored as name value pairs: 
     * 
     * {
     *      attribute1 : value1,
     *      attribute2 : value2,
     *      ...
     * } 
     * 
     * prepareUserAttributes parses these values on their part in order convert the attributes into the form,
     * which is required in the OpObjects, i.e. a json in depth:
     * 
     * {
     *      attribute1 : value1, // if simple string value
     *      attribute2 : { ... }, // if json value
     *      ...
     * } 
     * 
     * @param {*} value 
     */
    prepareUserAttributes(userattributes) {
        const attributes = {};
        
        Object.keys(userattributes).forEach(key => {
            try {
                attributes[key] = JSON.parse(userattributes[key])
            } catch(e) { // simple values
                attributes[key] =  userattributes[key];
            }
        });

        return attributes;
    }

    initContent(json) {

        json.forEach((child) => {

            this._initContent(child);

        })

    }

    /**
     * 
     * @param {OpObject} child 
     */
    _initContent(child) {

        var error = child.refId === 'error' ? 'error' : '';

        if (child.type == 'XOpGroup') {

            child.children.forEach((innerChild) => {

                this._initContent(innerChild);

            });

        }

        if (child.type == 'XOpSolid3Plain') {

            if (!(this.objectInformation.find(x => x.id === child.name))) {

                this.objectInformation.push(new ObjectInformation(child.name, child.id, child.filename, child.type, false, error, child.transform, null));

            }

        }

        if (child.type == 'XOpSolid3Feature') {

            if (!(this.objectInformation.find(x => x.id === child.name))) {

                this.objectInformation.push(new ObjectInformation(child.name, child.id, child.filename, child.type, false, error, child.transform, null));

            }

        }

        if (child.type == 'XOpSceneItem') {

            if (!(this.objectInformation.find(x => x.id === child.refId))) {

                this.objectInformation.push(new ObjectInformation(child.refId, child.id, child.filename, child.type, false, error, child.transform, null));

            }

        }

    }

    /**
     * Checks if all assets (OBJ files) have been loaded.
     * 
     * @returns True, only if all assets have been loaded, false otherwise.
     */
    _checkLoaded() {
        var allAssetsLoaded = true;

        this.objectInformation.forEach((info) => {

            var assettLoaded = info.loaded;
            if (assettLoaded === false) {

                allAssetsLoaded = false;

            }

        });

        return allAssetsLoaded;
    }

    async loadLight(url) {

        let fileLoader = new THREE.FileLoader();
        fileLoader.requestHeader = {
            'x-api-key': visualEvents.apiKey,
            'VisualEvents-ApiKey': visualEvents.apiKey,
            'VisualEvents-Language': visualEvents.language
        };
                
        const data = await fileLoader.loadAsync(url);
        
        const light = JSON.parse(data);
        return light;
    }

    toMatrix4(value) {
        const transform = new THREE.Matrix4();
        if (value !== '') {
            const elements = value.split(',');
            transform.set(
                Number(elements[0]), Number(elements[1]), Number(elements[2]), Number(elements[3]),
                Number(elements[4]), Number(elements[5]), Number(elements[6]), Number(elements[7]),
                Number(elements[8]), Number(elements[9]), Number(elements[10]), Number(elements[11]),
                Number(elements[12]), Number(elements[13]), Number(elements[14]), Number(elements[15])
            );
        }

        return transform;    
    }

}

class ObjectInformation {

    /**
     * Holds information relevant for the loading and cloning process
     * @param {string} id
     * @param {string} modelId
     * @param {string} filename
     * @param {string} type
     * @param {boolean} loaded 
     * @param {string} error 
     * @param {string} transform 
     * @param {Object3D} object3d 
     */
    constructor(id, modelId, filename, type, loaded, error, transform, object3d) {

        this.id = id;
        this.modelId = modelId;
        this.filename = filename;
        this.type = type;
        this.loaded = loaded;
        this.error = error;
        this.transform = transform;
        this.object3d = object3d;

    }

}