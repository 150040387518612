
/**
 * utility class to access sub items in json by pathes
 * 
 * examples:
 * 
 * const json = {
 *   subItem : {
 *    'a': 100,
 *    'b': [ { id: 1, name: 'harry' }, { id: 2, name: 'bob' }, { id: 3, name: 'dave' }],
 *    'c.ext': 'Hello World' 
 *   }
 * }
 * 
 * 'subitem.a'
 * 'subitem.b[2]'
 * 'subitem.b[1].name'
 * 
 * If names contain dots use "" to clearify, e.g.
 * 
 * 'subItem."c.ext"
 * 
 */
export default class JsonPath {

    /**
     * example: getValue(json, 'b[1]')  -> { id: 2, name: 'bob' }
     * @param {*} json 
     * @param {*} path 
     * @returns 
     */
    static getValue(json, path) {
        return JsonPath.pointerTo(json, path);
    }

    /**
     * set value at path in json
     * 
     * if the last portion of the path is not yet in json, add the sub data
     * 
     * example: setValue(json, 'test', true)
     * 
     * the example becomes:
     * 
     * const json = {
     *  ...
     *    'c.ext': 'Hello World', 
     *    'test': true
     * }
     * @param {*} json 
     * @param {*} path 
     * @param {*} value 
     */
    static setValue(json, path, value) {
        const [head, tail] = JsonPath.splitLast(path);
        JsonPath.pointerTo(json, head)[tail] = value;
    }

    /**
     * removes the sub items at path in json
     * 
     * example: removeValue(json, 'test')
     * 
     * restore the initial example json.
     * @param {*} json 
     * @param {*} path 
     */

    static removeValue(json, path) {
        const [head, tail] = JsonPath.splitLast(path);
        delete JsonPath.pointerTo(json, head)[tail];
    }

    /**
     * return a reference to the sub item path in json
     * @param {*} json 
     * @param {*} path 
     * @returns 
     */
    static pointerTo(json, path) {

        if (path === '')
            return json;

        const tokens = JsonPath.split(path);
        const depth = tokens.length-1;

        const o = [json];
        
        for (let i=0; i<depth; i++) {
            const t = tokens[i];
            if (!o[i])
                return undefined;
            o[i+1] = o[i][t];
        }

        const t = tokens[depth];
        if (!o[depth])
            return undefined;
        return o[depth][t];
    }

    /**
     * split the last portion of path
     * and return it together with the path in front of that
     * 
     * Any "" brackets in the head portions remain unchanged. The tail is not bracketed.
     * @param {*} path 
     * @returns 
     */
    static splitLast(path) {

        path = replaceDots(path);

        if (path.endsWith(']')) {
            const p = path.lastIndexOf('[');
            return [
                restoreDots(path.substring(0, p)), 
                restoreDotsAndBrackets(path.substring(p+1, path.length-1))
            ];
        } else {
            const p = path.lastIndexOf('.');
            return [
                restoreDots(path.substring(0, p)), 
                restoreDotsAndBrackets(path.substring(p+1))
            ];
        }
    }

    /**
     * return an array with all portions 
     * 
     * m[1][2][3]."test.ext"[0]   ->  1, 2, 3, test.ext, 0
     * 
     * brackets remain unsplit. The " are removed in those array items.
     * @param {*} path 
     * @returns 
     */
    static split(path) {

        path = replaceDots(path);

        const tokens = []
        path.split('.').forEach(token => {
            const p = token.indexOf('[');
            if (p > -1 )  { //array
                const array = token.substring(0, p); 
                tokens.push(array);
                const indices = token.substring(p).split(']').map(arg => arg.substring(1));
                tokens.push(...indices.slice(0,indices.length-1));
            } else
                tokens.push(token);
        });

        return tokens.map(t => restoreDotsAndBrackets(t));
    }
}

//internal: replace . by \n and reverse; remove bracketing ""
function replaceDots(path) {
    const a= path.split("");
    let bracket = false;
    for (let p = 0; p < path.length; p++) {
        const c = a[p];
        if (c === '"') bracket = !bracket;
        if (bracket && c === '.') a[p] = '\n';
    }
    return a.join("");
}
function restoreDots(t) { return t.replace('\n','.'); }
function removeBrackets(t) { return (t.startsWith('"') && t.endsWith('"')) ? t.substring(1,t.length-1) : t; }
function restoreDotsAndBrackets(t) { return removeBrackets(restoreDots(t)); }

