<template>
    <div class="d-flex flex-column justify-content-between gap-3 border" style="height: 50vh; width: 100%; overflow-y: scroll; overflow-x: hidden;">
        <div class="row h-100">
            <div class="col-sm-12">
                <ul class="nav nav-tabs nav-fill" id="sidepanel-tab" role="tablist">
                    <li class="nav-item" role="presentation">
                        <button class="nav-link active" id="placement-tab" data-bs-toggle="tab" data-bs-target="#placement" type="button" role="tab" aria-controls="placement" aria-selected="true" @click="onSelectTab('placement')">Platzierung</button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link" id="numeration-tab" data-bs-toggle="tab" data-bs-target="#numeration" type="button" role="tab" aria-controls="numeration" aria-selected="false" @click="onSelectTab('numeration')">Nummerierung</button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link" id="settings-tab" data-bs-toggle="tab" data-bs-target="#settings" type="button" role="tab" aria-controls="settings" aria-selected="false" @click="onSelectTab('settings')">Einstellungen</button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link" id="attributes-tab" data-bs-toggle="tab" data-bs-target="#attributes" type="button" role="tab" aria-controls="attributes" aria-selected="false" @click="onSelectTab('attributes')">Eigenschaften</button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link" id="bookings-tab" data-bs-toggle="tab" data-bs-target="#bookings" type="button" role="tab" aria-controls="bookings" aria-selected="false" @click="onSelectTab('bookings')">Buchungssimulator</button>
                    </li>
                </ul>
                <div class="tab-content p-2" id="sidepanel-tab-content">
                    <div class="tab-pane fade show active" id="placement" role="tabpanel" aria-labelledby="placement-tab">
                        <div class="row">
                            <div class="col-sm-12 mb-3">
                                <legend>Platzierungsmodus</legend>     
                                <div class="d-flex flex-wrap gap-2 justify-content-start">
                                    <input type="radio" class="btn-check" name="options-placement" id="placement-single" autocomplete="off" v-model="mode" value="SINGLE" @change="onChangeMode">
                                    <label class="btn btn-outline-primary" for="placement-single" title="Einzel"><i class="fa-solid fa-square"></i></label>

                                    <input type="radio" class="btn-check" name="options-placement" id="placement-rect" autocomplete="off" v-model="mode" value="RECT" @change="onChangeMode">
                                    <label class="btn btn-outline-primary" for="placement-rect" title="Rechteck"><i class="fa-solid fa-bars"></i></label>

                                    <input type="radio" class="btn-check" name="options-placement" id="placement-slanted" autocomplete="off" v-model="mode" value="SLANTED" @change="onChangeMode">
                                    <label class="btn btn-outline-primary" for="placement-slanted" title="Schräg"><i class="fa-solid fa-signal" style="transform: rotate(-90deg);"></i></label>

                                    <input type="radio" class="btn-check" name="options-placement" id="placement-arena" autocomplete="off" v-model="mode" value="ARENA" @change="onChangeMode">
                                    <label class="btn btn-outline-primary" for="placement-arena" title="Arena"><i class="fa-solid fa-cubes-stacked" style="transform: rotate(180deg);"></i></label>

                                    <input type="radio" class="btn-check" name="options-placement" id="placement-round" autocomplete="off" v-model="mode" value="ROUND" @change="onChangeMode">
                                    <label class="btn btn-outline-primary" for="placement-round" title="Rund"><i class="fa-solid fa-wifi"></i></label>

                                    <input type="radio" class="btn-check" name="options-placement" id="placement-circle" autocomplete="off" v-model="mode" value="CIRCLE" @change="onChangeMode">
                                    <label class="btn btn-outline-primary" for="placement-circle" title="Vollkreis"><i class="fa-solid fa-circle"></i></label>

                                    <input type="radio" class="btn-check" name="options-placement" id="placement-half-circle" autocomplete="off" v-model="mode" value="HALF_CIRCLE" @change="onChangeMode">
                                    <label class="btn btn-outline-primary" for="placement-half-circle" title="Teilkreis"><i class="fa-solid fa-circle-half-stroke"></i></label>

                                    <input type="radio" class="btn-check" name="options-placement" id="placement-delete" autocomplete="off" v-model="mode" value="DELETE" @change="onChangeMode">
                                    <label class="btn btn-outline-danger" style="margin-left: auto;" for="placement-delete" title="Löschen"><i class="fa-solid fa-trash"></i></label>
                                </div>   
                            </div>

                            <div class="col-sm-12 mb-3">
                                <fieldset>
                                    <legend>Versatz</legend>
                                    <div class="d-flex gap-2 justify-content-start">
                                        <input type="radio" class="btn-check" name="options-offset" id="offset-none" autocomplete="off" value = "SHIFT_NONE" v-model = "shiftMode" @click="onClickShiftMode('SHIFT_NONE')">
                                        <label class="btn btn-outline-primary" for="offset-none" title="Kein Versatz"><i class="fa-solid fa-xmark"></i></label>

                                        <input type="radio" class="btn-check" name="options-offset" id="offset-half" autocomplete="off"  value = "SHIFT_ALTERNATE" v-model = "shiftMode"  @click="onClickShiftMode('SHIFT_ALTERNATE')">
                                        <label class="btn btn-outline-primary" for="offset-half" title="Halb Versetzt"><i class="fa-solid fa-bars-staggered"></i></label>

                                        <input type="radio" class="btn-check" name="options-offset" id="offset-triangle" autocomplete="off"  value = "SHIFT_TRIANGLE" v-model = "shiftMode"  @click="onClickShiftMode('SHIFT_TRIANGLE')">
                                        <label class="btn btn-outline-primary" for="offset-triangle" title="Dreiecksanordnung"><i class="fa-solid fa-play" style="transform: rotate(-90deg)"></i></label>
                                    </div>
                                </fieldset>
                            </div>
                            
                            <legend>Symbole</legend>
                            <div class="col-sm-6">
                                <div class="input-group mt-3">
                                    <span class="input-group-text" id="row-addon">Reihen</span>
                                    <input type="number" v-model="cntY" min="1" max="50" @input="onChangeCntY" class="form-control" placeholder="Anzahl Reihen" aria-label="Rows" aria-describedby="row-addon">
                                </div>
                            </div>   
                            <div class="col-sm-6">
                                <div class="d-inline-flex flex-row align-items-baseline">
                                    <label for="range-row-offset" class="form-label">Abstand&nbsp;Reihen&nbsp;</label>
                                    <input type="number" v-model="distY" min="0" max="500" @input="onChangeDistY" class="form-control">
                                    <label for="range-row-offset" class="form-label">&nbsp;cm</label>
                                </div>
                                <input type="range"  v-model="distY" min="0" max="500" @input="onChangeDistY" class="form-range" id="range-row-offset">
                            </div>  
                            <div class="col-sm-6">
                                <div class="input-group mt-3">
                                    <span class="input-group-text" id="count-addon">Anzahl</span>
                                    <input type="number" v-model="cntX" min="1" max="50" @input="onChangeCntX" class="form-control" placeholder="Anzahl pro Reihe" aria-label="Count" aria-describedby="count-addon">
                                </div>
                            </div>    
                            <div class="col-sm-6">
                                <div class="d-inline-flex flex-row align-items-baseline">
                                    <label for="range-col-offset" class="form-label">Abstand&nbsp;</label>
                                    <input type="number" v-model="distX" min="0" max="500" @input="onChangeDistX" class="form-control">
                                    <label for="range-col-offset" class="form-label">&nbsp;cm</label>
                                </div>
                                <input type="range" v-model="distX" min="0" max="500" @input="onChangeDistX"  class="form-range" id="range-col-offset">
                            </div>         
                            <div class="col-sm-6">
                                <div class="d-inline-flex flex-row align-items-baseline">
                                    <label for="range-row-angle" class="form-label">Ausrichtung&nbsp;Reihe&nbsp;</label>
                                    <input type="number" v-model="angleX" min="0" max="360" @input="onChangeAngleX" class="form-control">
                                    <label for="range-row-angle" class="form-label">&nbsp;°</label>
                                </div>
                                <input type="range" v-model="angleX" min="0" max="360" @input="onChangeAngleX"  class="form-range" id="range-row-angle">
                            </div>         
                            <div class="col-sm-6">
                                <div class="d-inline-flex flex-row align-items-baseline">
                                    <label for="range-row-shear" class="form-label">Schräge&nbsp;</label>
                                    <input type="number" v-model="shear" min="-90" max="90" @input="onChangeAngleShear" class="form-control">
                                    <label for="range-row-shear" class="form-label">&nbsp;°</label>
                                </div>
                                <input type="range" v-model="shear" min="-90" max="90" @input="onChangeAngleShear"  class="form-range" id="range-row-shear">
                            </div>         
                        </div>
                    </div>
                    <div class="tab-pane fade" id="numeration" role="tabpanel" aria-labelledby="numeration-tab">
                        <div class="col-sm-12">
                            <div class="input-group mt-3">
                                <span class="input-group-text" id="starting-number-addon">Startnummer</span>
                                <input type="number" class="form-control" aria-label="Count" aria-describedby="starting-number-addon" v-model="startNumber" @input="onChangeStartNumber">
                            </div>
                            <hr>
                        </div>   
                        <div class="col-sm-12">
                            <fieldset>
                                <legend>In der Reihe</legend>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="numeration-row" id="numeration-row-left" v-model="modeInRow" value="LEFT" @change="onChangeModeInRow">
                                    <label class="form-check-label" for="numeration-row-left">
                                        Von links
                                    </label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="numeration-row" id="numeration-row-right" v-model="modeInRow" value="RIGHT" @change="onChangeModeInRow">
                                    <label class="form-check-label" for="numeration-row-right">
                                        Von rechts
                                    </label>
                                </div>
                            </fieldset>
                        </div> 
                        <div class="col-sm-12">
                            <fieldset>
                                <legend>Alternierend</legend>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="numeration-alternating" id="numeration-alternating-none" v-model="modeAlternate" value="NORMAL" @change="onChangeModeAlternate">
                                    <label class="form-check-label" for="numeration-alternating-none">
                                        Nicht (fortlaufend)
                                    </label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="numeration-alternating" id="numeration-alternating-outside" v-model="modeAlternate" value="OUTER" @change="onChangeModeAlternate">
                                    <label class="form-check-label" for="numeration-alternating-outside">
                                        Von außen
                                    </label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="numeration-alternating" id="numeration-alternating-inside" v-model="modeAlternate" value="INNER" @change="onChangeModeAlternate">
                                    <label class="form-check-label" for="numeration-alternating-inside">
                                        Von innen
                                    </label>
                                </div>
                            </fieldset>
                        </div> 
                        <div class="col-sm-12">
                            <fieldset>
                                <legend>Reihen</legend>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="numeration-rows" id="numeration-rows-front" v-model="modeRows" value="FORWARD" @change="onChangeModeRows">
                                    <label class="form-check-label" for="numeration-rows-front">
                                        Von vorne
                                    </label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="numeration-rows" id="numeration-rows-back" v-model="modeRows" value="BACKWARD" @change="onChangeModeRows">
                                    <label class="form-check-label" for="numeration-rows-back">
                                        Von hinten
                                    </label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="numeration-rows" id="numeration-rows-per-row" v-model="modeRows" value="PER_ROW" @change="onChangeModeRows">
                                    <label class="form-check-label" for="numeration-rows-per-row">
                                        Pro Reihe
                                    </label>
                                </div>
                            </fieldset>
                        </div> 
                    </div>
                    <div class="tab-pane fade" id="settings" role="tabpanel" aria-labelledby="settings-tab">
                        <div class="col-sm-12">
                            <legend>Raster</legend>
                            <div class="form-check form-switch">
                                <input class="form-check-input" type="checkbox" role="switch" id="raster-enabled" v-model="rasterEnabled" @change="onChangeRasterEnabled">
                                <label class="form-check-label" for="raster-toggle">Raster aus/ein</label>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="input-group mt-3">
                                <label for="range-col-offset" class="form-label">Rastergröße {{rasterSize}}</label>
                                <input type="range" v-model="rasterSize" min="1" max="50" @input="onChangeRasterSize"  class="form-range" id="range-col-offset">
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="attributes" role="tabpanel" aria-labelledby="attributes-tab">
                        <div class="col-sm-12">
                            <legend>Eigenschaften</legend>
                        </div>
                        <div class="col-sm-12">
                            <button id="updateaction" @click="onClearSelection">Selektion löschen</button>
                        </div>
                        <div class="col-sm-12">
                            <label>Ausgewählte Objekte: {{selectionListIDs}}</label>
                        </div>
                        
                        <div class="col-sm-12" v-for="prop in selectionProperties" :key="prop.name">
                            <label class="col-sm-5">{{prop.name}}</label>
                            <input class="col-sm-5" type="text" v-model="prop.value" @input="onChangeAttribute(prop.name)" 
                                :style="{backgroundColor:prop.defined ? '' : 'lightgrey'}">
                            <button class="col-sm-2" v-if="prop.deletable" @click="onDeleteAttribute(prop.name)">Löschen</button>
                        </div>

                        <div v-if="!showCreateAttribute">
                            <button class="col-sm-6" @click="showCreateAttribute = true">Neues Attribut</button>
                        </div>
                        <div v-if="showCreateAttribute">
                            <div class="col-sm-12">
                                <label class="col-sm-3">Attribut Name: </label>
                                <input class="col-sm-3" type="text" v-model="newAttributeName">
                                <button class="col-sm-3" @click="onCreateAttribute()">Erzeugen</button>
                                <button class="col-sm-3" @click="showCreateAttribute = false">Abbrechen</button>
                            </div>
                        </div>

                    </div>
                    <div class="tab-pane fade" id="bookings" role="tabpanel" aria-labelledby="bookings-tab">
                        <div class="col-sm-12">
                            <legend>Buchungen</legend>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import theApp from '@/frame/Application';

import settings from '@/visual-events/data/Settings';
import Action from '@/frame/ActionStack'
import Logger from '@/frame/Logger';

import ActPlaceBlock from '@/visual-events/actions/ActPlaceBlock';

export default {
  name: 'SidePanel',
  setup () {
    return {
        logger: new Logger('SidePanel')
    }
  },
  data () {
    return {
        //TODO: evtl besser direkt mit der Action verbinden? und Settings lesen und schreiben von settings in der action?
        //TODO: settings Persistenz?
        mode : settings.grid.mode,
        cntX : settings.grid.cntX,
        cntY : settings.grid.cntY,
        distX : settings.grid.distX / 10.,
        distY : settings.grid.distY / 10.,
        angleX : settings.grid.angleX,
        angleY : settings.grid.angleY,
        shear : 90.0 - (settings.grid.angleY*1 - settings.grid.angleX*1) % 180.0,

        alternate: settings.grid.alternate,
        keepRatioXY: settings.grid.keepRatioXY,

        startNumber : settings.numeration.startNumber,
        modeInRow : settings.numeration.modeInRow,
        modeAlternate : settings.numeration.modeAlternate,
        modeRows : settings.numeration.modeRows,

        rasterEnabled : settings.raster.enabled,
        rasterSize : settings.raster.size,

        selection: [],
        showCreateAttribute: false,
        newAttributeName: ''
    }
  },
  computed: {
    shiftMode: {
        get() {
        if (!this.alternate && !this.keepRatioXY)
            return 'SHIFT_NONE'
        else if (this.alternate)
            return 'SHIFT_ALTERNATE'  
        else if (this.keepRatioXY)
            return 'SHIFT_TRIANGLE'
        else
            return 'SHIFT_UNKNOWN'
        },
        set(newValue) {
            switch (newValue) {
                case 'SHIFT_NONE': this.alternate = false; this.keepRatioXY = false; break;
                case 'SHIFT_ALTERNATE': this.alternate = true; this.keepRatioXY = false; break;
                case 'SHIFT_TRIANGLE': this.alternate = true; this.keepRatioXY = true; break;
            }
        }
    },
    selectionListIDs:{
        get() {
            let strgids = '';
            this.selection.forEach(function(op)
                {
                    if (strgids == '')
                        strgids = '' + op.id;
                    else
                        strgids = strgids + ', ' + op.id;
                }
            );
            return strgids;
        }
    },
    selectionProperties:{
        get(){
            let globalproperties = [];
            let select = this.selection;
            for (let i=0; i<this.selection.length; i++)
            {
                let op = this.selection[i];
                let localproperties = this.getProperties(op);
                if (i==0)
                    localproperties.forEach(prop => globalproperties.push(Object.assign({}, prop)));
                else
                {
                    localproperties.forEach(propl => {
                        let found = globalproperties.find(el => el.name==propl.name);
                        if (typeof found === 'undefined')
                        {
                            found = Object.assign({}, propl);
                            globalproperties.push(found);
                            found.defined = false;
                        }
                        else
                        {
                            if (found.value !== propl.value)
                                found.defined = false;
                        }
                    });
                    globalproperties.forEach(propg => {
                        let found = localproperties.find(el => el.name==propg.name);
                        if (typeof found === 'undefined')
                            propg.defined = false;
                        if (propg.defined===false)
                            propg.value = '<undefiniert>';
                    });
                }
            }
            return globalproperties;
        }
    }
  },
  mounted () {
    theApp.addDialog('SidePanel', this);
    const action = Action.findByClass(ActPlaceBlock)
    if (action) 
        action.dialog = this;
  },
  unmounted () {
    theApp.removeDialog('SidePanel');
    const action = Action.findByClass(ActPlaceBlock)
    if (action) 
        action.dialog = null;
  },
  methods: {
    update() {
        const action = Action.findByClass(ActPlaceBlock)
        if (!action)
            return;

        this.mode = action.grid.mode
        this.cntX = action.grid.cntX
        this.cntY = action.grid.cntY
        this.distX = action.grid.distX / 100.
        this.distY = action.grid.distY / 100.
        this.angleX = action.grid.angleX
        this.angleY = action.grid.angleY
        this.shear = 90.0 - (action.grid.angleY*1 - action.grid.angleX*1) % 180.0

        this.alternate =  action.grid.alternate,
        this.keepRatioXY = action.grid.keepRatioXY

        this.startNumber = action.renumberTool.startNumber,
        this.modeInRow = action.renumberTool.modeInRow,
        this.modeAlternate = action.renumberTool.modeAlternate,
        this.modeRows = action.renumberTool.modeRows

    },
    onChangeMode() {
        theApp.executeCommand(`val.val "mode" ${this.mode}`)
    },
    onChangeCntX () {
      theApp.executeCommand(`val.val "cntX" ${this.cntX}`)
    },
    onChangeCntY () {
      theApp.executeCommand(`val.val "cntY" ${this.cntY}`)
    },
    onChangeDistX () {
        //TODO: wieso so ein Faktor, steht in Zusammenhang mit dem Range im input tag!
      theApp.executeCommand(`val.val "distX" ${this.distX*10.0}`)
    },
    onChangeDistY () {
      theApp.executeCommand(`val.val "distY" ${this.distY*10.0}`)
    },
    onChangeStartNumber() {
      theApp.executeCommand(`val.val "startNumber" ${this.startNumber}`)
    },
    onChangeModeInRow () {
      theApp.executeCommand(`val.val "modeInRow" ${this.modeInRow}`)
    },
    onChangeModeAlternate () {
      theApp.executeCommand(`val.val "modeAlternate" ${this.modeAlternate}`)
    },
    onChangeModeRows () {
      theApp.executeCommand(`val.val "modeRows" ${this.modeRows}`)
    },
    onChangeAngleX () {
      this.angleY = (this.angleX*1 + 90.0 - this.shear*1) % 360.0
      theApp.executeCommand(`val.val "angleX" ${this.angleX}`)
      theApp.executeCommand(`val.val "angleY" ${this.angleY}`)
    },
    onChangeAngleY () {
      this.shear = 90.0 - (settings.grid.angleY*1 - settings.grid.angleX*1) % 180.0
      theApp.executeCommand(`val.val "angleY" ${this.angleY}`)
    },
    onChangeAngleShear () {
      this.angleY = (this.angleX*1 + 90.0 - this.shear*1) % 360.0
      theApp.executeCommand(`val.val "angleY" ${this.angleY}`)
    }, 
    onClickShiftMode (mode) {
        let alternate = false;
        let keepRatioXY = false;
        switch (mode) {
            case 'SHIFT_ALTERNATE': 
                alternate = true;
                break;
            case 'SHIFT_TRIANGLE': 
                alternate = true;
                keepRatioXY = true;
                break;
        }
        theApp.executeCommand(`val.val "alternate" ${alternate}`)
        theApp.executeCommand(`val.val "keepRatioXY" ${keepRatioXY}`)
    },
    onChangeRasterEnabled () {
        theApp.executeCommand(`val.val "rasterEnabled" ${this.rasterEnabled}`)
    },
    onChangeRasterSize () {
        theApp.executeCommand(`val.val "rasterSize" ${this.rasterSize}`)
    },
    onSelectTab(selectedtab) {
        this.logger.log(`selectd tab ${selectedtab}`);

        if (selectedtab === 'bookings') {
            if (!Action.find('ActBooking'))
                theApp.sendCommand(".VisualEvents.booking");
        }
        else
        {
            const action = Action.findByClass(ActPlaceBlock);

            if (!action)
                theApp.sendCommand(".VisualEvents.placeBlock" + ' ' + selectedtab);
            else
            {
                if (selectedtab === 'attributes')
                    action.SetAttributeState(true);
                else
                    action.SetAttributeState(false);    
            }
        }
    },
    onClearSelection() {
        this.logger.log(`clear selection`);
        const action = Action.findByClass(Action.findByClass(ActPlaceBlock));
        if (action) 
            action.clearSelection();
    },
    onChangeAttribute(name) {
        this.logger.log(`change Attribute`);
        const action = Action.findByClass(ActPlaceBlock);
        if (action) {
            const value = this.selectionProperties.find(el => el.name==name).value;
            action.changeAttribute(name, value);
        } 
    },
    onDeleteAttribute(attname) {
        this.logger.log(`delete Attribute`);
        const action = Action.findByClass(ActPlaceBlock);
        if (action) 
            action.deleteAttribute(attname);

    },
    onCreateAttribute() {
        this.logger.log(`create Attribute`);
        const action = Action.findByClass(ActPlaceBlock);
        if (action) {
            action.createAttribute(this.newAttributeName);
            this.showCreateAttribute = false;
        }
    },
    getProperties(op)
    {
        let localprops = [];

        let attobject = {};
        for (let attname in op.attributes)
            attobject[attname] = op.attributes[attname];
 
        for (let attname in attobject)
        {
            let localprop = {};
            localprop.name  = attname;
            localprop.value = attobject[attname];
            localprop.deletable = !(attname === '$ticketing.item');
            localprop.defined = true;
            localprops.push(localprop);
        }
        return localprops;
    }
  }
}
</script>
