import OpObject from '@/visual-events/model/OpObject'

export default class OpMesh extends OpObject {

    constructor (subtype, name, mesh) {
        super (subtype, name);

        this.mesh = mesh;
    }

    copy () {

        const copy = new OpMesh(this.type, this.name, this.mesh.clone());
        
        copy.setTransform(this.transform);

        return copy;
    }

}
