export const ModeAlternate = {
    NORMAL: 'NORMAL',
    OUTER: 'OUTER',
    INNER: 'INNER'
};

export const ModeInRow = {
    LEFT: 'LEFT',
    RIGHT: 'RIGHT'
};

export const ModeRows = {
    PER_ROW: 'PER_ROW',
    FORWARD: 'FORWARD',
    BACKWARD: 'BACKWARD'
};

/**
 * Tool for matrix number generation
 * 
 * opts.attributeColumn:  attribute name, which keeps the seat number
 * opts.attributeRow:  attribute name, which keeps the row number (TODO)
 */
export class RenumberTool {

    constructor() {
        this.modeInRow = ModeInRow.LEFT;
        this.modeAlternate = ModeAlternate.NORMAL;
        this.modeRows = ModeRows.PER_ROW;
        this.startNumber = 1;

        //TODO: Optionen für Reihennummern
        // vorne nach hinten, hinten nach vorne

        this.attributeColumn = '$seatNo';
    }

    RenumberTexts(symbols, cntAll, cntX, cntY, arrayCntX) {

        let cntRows = Math.abs(cntY);
        if (cntRows == 0)
            return;

        let cntBeforeRow = 0;
        for (let row = 0; row < cntRows; row++) {
            let cntColIsInRow = Math.abs(arrayCntX[row]);
            for (let col = 0; col < cntColIsInRow; col++) {
                let no = this.DetermineNumber(col, cntAll, cntBeforeRow, cntColIsInRow, this.modeAlternate, this.modeInRow, this.modeRows, this.startNumber);
                const op = symbols[row * cntX + col];
                if (op)
                {
                    op.setAttribute(this.attributeColumn, no.toString());
                }
            }
            cntBeforeRow += cntColIsInRow;
        }
    }

    DetermineNumber(col, cntAll, cntBeforeRow, cntColsInRow, modeAlternate, modeInRow, modeRows, startNumber) {
        let start = startNumber;

        switch (modeRows)
        {
            case ModeRows.PER_ROW:
                start = startNumber;
                break;
            case ModeRows.FORWARD:
                start = startNumber + cntBeforeRow;
                break;
            case ModeRows.BACKWARD:
                start = startNumber + cntAll - (cntBeforeRow + cntColsInRow);
                break;
        }

        let idx = 0;
        switch (modeInRow)
        {
            case ModeInRow.LEFT:
                idx = col;
                break;
            case ModeInRow.RIGHT:
                idx = cntColsInRow - 1 - col;
                break;
        }

        let noInRow = 0;

        switch (modeAlternate)
        {
            case ModeAlternate.NORMAL:
                noInRow = idx;
                break;
            case ModeAlternate.INNER:
                if (idx < parseInt((cntColsInRow + 1) / 2))
                    noInRow = 2 * ((cntColsInRow + 1) / 2 - idx - 1);
                else
                    noInRow = 2 * (idx - (cntColsInRow + 1) / 2) + 1;
                break;
            case ModeAlternate.OUTER:
                if (idx < parseInt((cntColsInRow + 1) / 2))
                    noInRow = 2 * idx;
                else
                    noInRow = 2 * (cntColsInRow - idx) - 1;
                break;
        }

        return noInRow + start;
    }

}