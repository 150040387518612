import OpObject from '@/visual-events/model/OpObject'
import { CADdyType } from '@/visual-events/loader/CADdyType'

/**
 * symbols are op trees, which can be referenced in other trees by XOpReference objects.
 * 
 * The id is deliberately choosen string, but must be unique among the OpSymbol object
 * currently living in the model. 
 * 
 * Actually the id often comes from import, e.g.
 * - the id of 2D symbols imported from CADdy++ exported XOpSymbols 
 *   is a tree address, something like /WST-24.0/Bodenfenster_240x280
 *   (maybe in future containing the CADdy++ symbol library name and the version no, 
 *    because in CADdy++ it is possible, that in two different libraries the name
 *    is equal)
 * - the id of geometry belonging to a CADdy++ Webexport might be
 *   the name of the gltf-File of the XOpSceneItem or for Solids Solid_<no>
 * 
 * TODO: transform, style (2D or 3D) required?
 */

export default class OpSymbol extends OpObject {
    constructor (name, id) {
        super (CADdyType.SYMBOL, name, id);
        this.filename = id;
    }

    //TODO: transform, attributes copy?
    //TODO: copy without changing the id is nonsense, how to handle this???!!
    copy () {
        return new OpSymbol(this.name, this.filename);
    }    
}