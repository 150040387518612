import theApp from '@/frame/Application';

import Action from '@/frame/Action';
import FltPick from '@/visual-events/actions/FltPick';
import { PickFlags } from '@/visual-events/actions/FltPick';
import Logger from '@/frame/Logger';

/**
 * Allow for selecting objects with drag mode, only if the camera-controls is disabled. 
 * Otherwise it collides with the left mouse button panning.
 */
export const DeleteFlags = Object.freeze({
    NO_DRAG:                0x0008, // Do not allow dragging operation to select objects in order to delete them
});

/**
 * let the user select op objects in the view and delete these
 * TODO: better filtering than pickTypes, e.g. callback which checks, whether the symbol is a bookable Item
 */
export default class FltDelete extends Action {
    constructor(view, pickTypes = [], deleteFlags = 0) {
      super();

      this.logger = new Logger('FltDelete');
        
      this.flags = deleteFlags;
      this.types = pickTypes;
      this.view = view;
    }

    actionStart () {
        this.logger.log(`FltDelete::Start`);

        let pickFlags = this.flags && DeleteFlags.NO_DRAG ? PickFlags.IGNORE_MSL | PickFlags.NO_DRAG : PickFlags.IGNORE_MSL;
        this.addFilter(new FltPick(pickFlags, this.types));

        return true;
    }

    actionDestroy () {
        this.logger.log(`FltDelete::Destroy`);
    }

    actionSelection (event) {
        this.logger.log(`FltDelete::Selection`);

        event.objects.forEach(op => op.removeFromParent());

        if (event.objects.length !== 0) 
            theApp.model.changed2d = true;

        return null;
    }
}    
