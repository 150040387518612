/**
 * default settings of the ticketing app
 */
export default {

        version: 1,

        // During the design process of the ticketing plans, some of the ticketing properties
        // are determined automatically or semi automatically, e.g. numbers of the placed bookable items
        // These are prepared algorithms, which can be referred to with names. The target attributes
        // are configuered here
        computedProperties: [
            //
            // when placing bookable items in a grid the items receive numbers
            // - $rowNo: the row number is attached to the groups containing the symbols of each row
            // - $seatNo: attached to each items inside of the rows, usually seats, but maybe tables in other cases
            //
            {
                id: 'grid_number_row',
                name: 'Reihennummerierung',
                attribute: '$rowNo'
            },
            {
                id: 'grid_number_seat',
                name: 'Sitznummerierung',
                attribute: '$seatNo'
            },
            {
                id: 'grid_number_table',
                name: 'Tischnummerierung',
                attribute: '$tableNo'
            },
            //
            // when placing bookable items there may be a set of category properties, which are offered to fill in
            // in the placing gui, in order to attach these to all items of the current grid
            //
            // any computed property with a name like category_<name> will be offered, e.g.
            //
            {
                id: 'category_block',
                name: 'Block',
                attribute: '$block'
            },
            {
                id: 'category_price',
                name: 'Preiskategorie',
                attribute: '$priceCategory'
            },
        ],

        // The itemId is required to identify the bookable items in the embedding system
        // Apart from defining the ids manually, the GUI provides a button to generate ids automatically according to
        autoIncrement: false,  // true: generate unique ids as sequence 1, 2, 3, ....   false: use the generator pattern

        // TODO: vielleicht idGenerator mit in die Booking modes       
        idGenerator: 'block-${"$ticketing.item".attributes.Block}-row-${"$ticketing.item".attributes.Reihe}-seat-${"$ticketing.item".attributes.Sitz}',
                
        // There are different types of bookable items, which need a specific behavior in the booking app and specific settings
        //
        // - single item booking
        // - contingent booking
        //

        bookingModes: [
            {
                id: 'single',
                name: 'Einzelplatzbuchung',
                defaultProperties : [
                    {
                        name: 'Block',
                        value:  '',
                    },
                    {
                        // Reihe is automatically taken from the 'row' generated when placing the seat rows
                        name: 'Reihe',
                        value: undefined,
                        attribute:  '$rowNo',
                    },
                    {
                        // Sitz is automatically taken from the 'number' generated when placing the seats
                        name: 'Sitz',
                        value:  undefined,
                        attribute: '$seatNo'
                    }
                ],
                //TODO: apply displayStrategies in GrfBookingStrategy, attention: id, not name!!
                displayStrategies : [
                    {
                        id: 'bookable',
                        style: { fillColor: 'none', fillOpacity: 'none', strokeColor: 'none' }
                    },
                    {
                        id: 'booked',
                        style: { fillColor: '#994444', fillOpacity: 'none', strokeColor: 'none' }
                    },
                    {
                        id: 'inMyCart',
                        style: { fillColor: '#0044aa', fillOpacity: 'none', strokeColor: 'none' }
                    },
                    {
                        id: 'addingToCart',
                        style: { fillColor: '#0044aa', fillOpactity: 0.9, strokeColor: 'none' } // or more eye-catching green ='#00FF00',
                    },
                    {
                        id: 'removingFromCart',
                        style: { fillColor: 'none', fillOpactity: 0.9, strokeColor: 'none' } // or more eye-catching green ='#00FF00',
                    }
                ]
            },
            {
                id: 'block',
                name: 'Blockbuchung',
                defaultProperties : [
                    {
                        name: 'Block',
                        value:  '',
                    },
                    {
                        // mandatory for contingent wise ticketing
                        name: '$maxBookable',
                        value:  undefined,
                    }
                ]
            },
            {
                id: 'table',
                name: 'Tischbuchung',
                defaultProperties : [
                    {
                        name: 'Block',
                        value:  '',
                    },
                    {
                        // mandatory for contingent wise ticketing
                        name: '$maxBookable',
                        value:  undefined,
                    },
                    {
                        // Tisch is automatically taken from the 'number' generated when placing the tables
                        name: 'Tisch',
                        value:  undefined,
                        from: '$tableNo'
                    }
                ]
            }
        ]
}
