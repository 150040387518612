export const CADdyType = Object.freeze({
    // 
    // containers
    //
    GROUP: 'XOpGroup', 
    DRAWING: 'XOpDraft',
    SKETCHBOARD: 'XOpSkB2Draft',
    SPACE: 'XOpSpace',
    // PLANE: 'XOpSkB2Constr',
    // 
    // instancing
    //
    SYMBOL: 'XOpSym',
    SYMBOL_REFERENCE: 'XOpSymbol',
    // 
    // 2D elements (OpShapePath)
    //
    FACE: 'XOpFace',
    POINT: 'XOpPoint2',
    LINE: 'XOpStraSeg2',
    CIRCLE: 'XOpCirSeg2',
    ELLIPSE: 'XOpEllSeg2',
    SPLINE: 'XOpSplSeg2',
    TEXT: 'XOpText2',
    POLYLINE: 'XOpPolyLine2',
    // IMAGE: 'XOpImage2',
    // INFINITE_LINE: 'XOpStraight2',
    // HATCH: 'XOpHatch2',
    //
    // 3D Geometry (OpMesh)
    //
    SOLID: 'XOpSolid3Plain',
    FEATURE: 'XopSolid3Feature',
    SCENE_ITEM: 'XOpSceneItem'
});
