import JsonPath from '@/frame/JsonPath';
import Geometry from '@/visual-events/model/Geometry'
import Subject from '@/frame/Subject';
import { Matrix4 } from 'three';
import Logger from '@/frame/Logger';

export default class OpObject extends Subject {
    //TODO: Wenn hier im constructor name ist, sollte vielleicht in allen abgeleiteten Klassen auch name vorgesehen werden?!
    //TODO: visible für alle, pickable?
    //TODO: !!! klare Trennung zwischen den verschiedenen Arten von OpObject und den Objekten, z.B. XOpText, die nur
    // in Loadern zum Transport der eingelesenen Information dienen: SVGTextDefinition; dto. für 3D-Objekte
    // refId und xref gehören eigentlich auch nicht hierein.
    //TODO: Modify für Texte muss vielleicht anders gemacht sein: SVGTextInformation enthält x,y
    //TODO: XOpDraft mit width, height, evtl. viewbox

    static #logger = new Logger('OpObject')

    /**
     * 2D/3D object model.
     * @param {string} type 
     * @param {string} name 
     */
    constructor(type, name) {

        super();

        this.id = ++OpObject.nextId;
        this.type = type;
        this.name = name;
        this.transform = new Matrix4();
        this.attributes = {};
        this.children = [];
        this.parent = null;

        this.isVisible = true;

        this.box = null;
    }

    copy () {

        const copy = new OpObject(this.type, this.name);

        //TODO: überlegen, welche member in copy kopiert werden und welche auf Defaultwert zurückgestellt werden
        //dann auch konsequenterweise in den abgeleiteten Klassen!
        copy.visible = this.visible;
        copy.setTransform(this.transform);

        return copy;
    }

//----------------------------------------------------------------------------
// transform
//
    setTransform (transform) {
        const t = this.transform.clone();
        t.invert();
        t.multiply(transform);
        this.multiplyTransform (t);
    }

    multiplyTransform (transform) {
        this.transform.multiply(transform)
        this.notify( { name: 'multiplyTransform', data: { sender: this, transform: transform }});
    }

//----------------------------------------------------------------------------
// op tree structure
//

    add (child) {
        child.removeFromParent();
        child.parent = this;
        this.children.push(child);
        this.notify( { name: 'add', data:  { parent: this, child: child }});
    }

    remove (child) {
        if (child.parent === this) {
            child.parent = null;
            this.children = this.children.filter(op => op !== child);
            this.notify( { name: 'remove', data:  { parent: this, child: child }});
        }
    }

    removeFromParent () {
        if (this.parent)
            this.parent.remove(this);
    }

    /**
     * find the OpObject below this with 'id'
     * TODO:ScanTree artige Schnittstelle
     * @param {*} id 
     * @returns 
     */
    findOpObjectById(id) {
        let result = null;

        let iterateModel = (children, id) => {
            children.some((child) => {
                if (child.id === id) {
                    result = child;
                    return true;
                }
                iterateModel(child.children, id);
            });
        }

        const children = this.children;
        iterateModel(children, id);

        return result;
    }
    
    computeBox() {
        if (!this.box)
            this.box = Geometry.computeBox(this);

        return this.box;
    }

//----------------------------------------------------------------------------
// user attributes
//
    getAttribute(key) {
        
        try {
            return JsonPath.getValue(this.attributes, key);
        } catch(e) {
            return undefined;
        }   
    }

    setAttribute(key, object) {
        try {
            JsonPath.setValue(this.attributes, key, object);            
            this.notify( { name: 'setAttribute', data:  { sender: this, key: key }});
        } catch(e) { }
    }

    removeAttribute(key) {
        try {
            JsonPath.removeValue(this.attributes, key);            
            this.notify( { name: 'setAttribute', data:  { sender: this, key: key }});
        } catch(e) { }
    }

    logAttributes(root = '')
    {
        for (let key in this.attributes)
        {
            let value = this.attributes[key];
            OpObject.#logger.log(`OpObject[${this.id}].attributes[${key}] = ${value}`)
        }
    }

//----------------------------------------------------------------------------
// style
//
    // for the face filling
    //op.style.fill             color like '#808080' or 'none'  (no filling)
    //op.style.fillOpacity      completely transparent 0 - 1 opaque 

    // for the contour          
    //op.style.stroke           color like '#000000' or 'none'  (no contour)
    //op.style.strokeOpacity
    //op.style.strokeWidth      in mm
    //op.style.strokeLineJoin
    //op.style.strokeLineCap
    //op.style.strokeMiterLimit

    //visible, pickable, 

    //z-order

//----------------------------------------------------------------------------
// notifications
//

    onNotify(notification) {
        OpObject.#logger.log(`onNotify(${notification.name})`);
        if (this.type === 'XOpText2') {
            switch(notification.name) {
                case  'setAttribute': {

                    const sender = notification.data.sender;
                    const key = notification.data.key;
                    if (this.xref) {
                        const p= this.xref.indexOf('/');
                        if (p) {
                            const att = this.xref.substring(p+1);
                            if (att === key)
                                this.text = sender.getAttribute(key);
                        }
                    }
                }
                break;

                case 'multiplyTransform': {
                    // the relative position of this with respect to
                    // the owner is to be maintained
                    const sender = notification.data.sender;
                    const m = sender.transform.clone();
                    const mInv = sender.transform.clone();
                    mInv.invert();
                    m.multiply(notification.data.transform);
                    m.multiply(mInv);
                    this.transform.premultiply(m);
                }
                break;
            } 
        }
    }
}

// unique id generator
OpObject.nextId = 0;