export default class Model {
  /**
   * override this to perform a model animation step
   * time is the point of time
   * @param {} time in ms
   */
  move (time) {
    // Debug.log('Model::move');

    // if you need the elapsed time instead of the point of time
    // define this.last and calculate:
    // const elapsed = time - this.last;
    // this.last = time;
  }
}
