import * as Event from './Event';
import ActDefault from './ActDefault';
import FltDefault from './FltDefault';
import Logger from './Logger';

/**
 * do not call removeFilter in destroyAction
 * 
 * Before destroyAction is called, all filters in front of the action or filter are already
 * removed. Trying to change the action stack during destroyAction by yourself would
 * disrupt the stack and lead to failure lateron.
 */
export class InvalidRemoveFilterCall extends Error {
  constructor(caller) {
    super(`do not call removeFilter in ${caller}.actionDestroy`);
    this.name = 'InvalidRemoveFilterCall';
  }
}

export class ActionStack {
  constructor () {
    this.logger = new Logger('ActionStack');
    this.stack = [];
    this.DefaultActionClass = ActDefault;
    this.DefaultFilterClass = FltDefault;
    this.addDefaultAction();
    this.addDefaultFilter();
    // Import events module
    const events = require('events');

    // Create an eventEmitter object
    this.eventEmitter = new events.EventEmitter();
    this.eventEmitter.on('command', (commandLine) => {
      // process.nextTick(() => {
      //   this.emit("event");
      // });
      const command = new Event.CommandEvent(commandLine);
      this.processEvent(command);
    });

    this.actionDestroyCaller = null;
  }

  /**
   * find the action of class 'clazz', if it is running, otherwise null
   * @param {*} clazz 
   * @returns the action resp. filter
   */
  findByClass (clazz) {
    return this.stack.find(action => action instanceof clazz ? action : null);
  }

  /**
   * @deprecated
   * find the action 'name', if it is running, otherwise null
   * 
   * attention: The class check based on constructor.name did not work in webpack deployments unless build with debug symbols 
   * (seems to be caused by webpack renaming classes.Observed in FltEditAttributes with AttributesPanel; 
   * with other action classes it worked, unknown why)
   * @param {*} name
   * @returns the action resp. filter
   */
  findByClassName (name) {
    return this.stack.find(action => action.constructor.name === name ? action : null);
  }

  addUIListeners (component) {
    // TODO: all method names ES6 conform (now like in Java) and complete
    // TODO:actionWheel einführen?
    // TODO: focuc events? if yes, seperately?
    component.addEventListener('click', this.mouseClicked);
    component.addEventListener('mousemove', this.mouseMoved);
    component.addEventListener('mousedown', this.mousePressed);
    component.addEventListener('mouseup', this.mouseReleased);
    component.addEventListener('mouseenter', this.mouseEntered);
    component.addEventListener('mouseleave', this.mouseExited);
    component.addEventListener('wheel', this.mouseWheelMoved);
    component.addEventListener('keypress', this.keyPressed);
    component.addEventListener('keydown', this.keyTyped);
    component.addEventListener('keyup', this.keyReleased);
  }

  removeUIListeners (component) {
    component.removeEventListener('click', this.mouseClicked);
    component.removeEventListener('mousemove', this.mouseMoved);
    component.removeEventListener('mousedown', this.mousePressed);
    component.removeEventListener('mouseup', this.mouseReleased);
    component.removeEventListener('mouseenter',this.mouseEntered);
    component.removeEventListener('mouseleave', this.mouseExited);
    component.removeEventListener('wheel', this.mouseWheelMoved);
    component.removeEventListener('keypress', this.keyPressed);
    component.removeEventListener('keydown', this.keyTyped);
    component.removeEventListener('keyup',  this.keyReleased);
  }

  throwExceptionOnInvalidCall() {
    if (this.actionDestroyCaller)
      throw new InvalidRemoveFilterCall(this.actionDestroyCaller?.constructor?.name);
  }

  replaceAction (action) {
    this.throwExceptionOnInvalidCall();

    this.stopAll();
    this.addFilter(action);
  }

  addFilter (action) {
    this.throwExceptionOnInvalidCall();

    this.removeDefaultFilter();
    this.stack.push(action);
    if (!action.actionStart()) {
      this.stack.pop();
      if (!this.stack.length) { this.addDefaultAction(); }
    }
    if (this.getFilter(action) == null) { this.addDefaultFilter(); }
  }

  removeFilter (action) {
    this.throwExceptionOnInvalidCall();
    
    const filter = this.getFilter(action);
    if (filter != null) { this.removeAction(filter); }

    if (!this.stack.length) { this.addDefaultAction(); }
    this.addDefaultFilter();
  }

  removeAction (action) {
    const filter = this.getFilter(action);
    if (filter != null) { this.removeAction(filter); }

    //callback actionDestroy, no removeFilter during that call!
    this.actionDestroyCaller = action;
    action.actionDestroy();
    this.actionDestroyCaller = null;
    
    this.stack.pop();
    if (this.stack.length<2)
      this.activeAction = null;
  }

  stopAll () {
    if (this.stack.length) {
      this.removeAction(this.stack[0]);
      // should lead to an empty stack, since each action and filter
      // is responsible to remove all filters in front of it
    }

    if (!this.stack.length) { this.addDefaultAction(); }
    this.addDefaultFilter();
  }

  /**
   * find the filter action in front of 'action', if there is one
   * @param action
   * @return filter or null
   */
  getFilter (action) {
    for (let idx = 0; idx < this.stack.length - 1; idx++) {
      if (action === this.stack[idx]) { return this.stack[idx + 1]; }
    }
    return null;
  }

  // MouseWheelEvent
  mouseWheelMoved = (event) => {
    event.preventDefault();
    this.processEvent(new Event.RawMouseEvent(event));
  }

  // MouseEvent
  mouseDragged = (event) => {
    event.preventDefault();
    this.processEvent(new Event.RawMouseEvent(event));
  }

  // MouseEvent
  mouseMoved = (event) => {
    this.logger.log("ActionStack::mouseMoved");
    event.preventDefault();
    this.processEvent(new Event.RawMouseEvent(event));
  }

  // MouseEvent
  mouseClicked = (event) => {
    this.logger.log("ActionStack::Click");
    event.preventDefault();
    this.processEvent(new Event.RawMouseEvent(event));
  }

  // MouseEvent
  mouseEntered = (event) => {
    this.logger.log("ActionStack::mouseEntered");
    event.preventDefault();
    this.processEvent(new Event.RawMouseEvent(event));
  }

  // MouseEvent
  mouseExited = (event) => {
    this.logger.log("ActionStack::mouseExited");
    event.preventDefault();
    this.processEvent(new Event.RawMouseEvent(event));
  }

  // MouseEvent
  mousePressed = (event) => {
    event.preventDefault();
    this.processEvent(new Event.RawMouseEvent(event));
  }

  // MouseEvent
  mouseReleased = (event) => {
    event.preventDefault();
    this.processEvent(new Event.RawMouseEvent(event));
  }

  // KeyEvent
  keyTyped = (event) => {
    this.processEvent(new Event.RawKeyEvent(event));
  }

  // KeyEvent
  keyPressed = (event) => {
    this.processEvent(new Event.RawKeyEvent(event));
  }

  // KeyEvent
  keyReleased = (event) => {
    this.processEvent(new Event.RawKeyEvent(event));
  }

  // ActionEvent
  actionPerformed = (event) => {
    // this.processEvent(new Event.CommandEvent(event.getActionCommand()));
  }

  /**
   * replace the standard default filter class
   *
   * This filter should usually be derived from FltDefault
   * or rebuild its behavior for some general concepts, such as
   * - commands, which immediately translate to events, such as val.point
   * - catch hotkeys and convert these to commands
   * - translate raw mouse move and clicks to dynamic and point events
   * - interprete the ESC key as standard break event
   * @param clazz
   */
  setDefaultFilterClass (clazz) {
    this.DefaultFilterClass = clazz;
  }

  /**
   * replace the standard default action
   *
   * This action usually should be derived from ActDefault
   * in order to handle all commands, which have not yet been
   * catched by filters and actions.
   * @param clazz
   */
  setDefaultActionClass (clazz) {
    this.DefaultActionClass = clazz;
  }

  addDefaultAction () {
    try {
      const action = new this.DefaultActionClass();
      this.stack.push(action);
      action.actionStart();
    } catch (ex) {
      // TODO: allgemeines Fehlerhandling, Traces/Logs?
      console.log(ex); // eslint-disable-line no-console
    }
  }

  addDefaultFilter () {
    try {
      const action = new this.DefaultFilterClass();
      this.stack.push(action);
      action.actionStart();
    } catch (ex) {
      console.log(ex); // eslint-disable-line no-console
    }
  }

  removeDefaultFilter () {
    this.removeAction(this.stack[this.stack.length - 1]);
  }

  /**
 * propagate the event through the stack of filters and actions
 *
 * Filters and actions, which are interested in the event, can perform
 * their work
 * - do some state change
 * - decide to stop propagation of the event or
 * - possibly replace the event by a modified or new event, which is handed over to the next
 *
 * Usually the first filter to receive the event is the default filter.
 * The last action in the stack is the default filter.
 *
 * @param {*} event
 */
  processEvent (event) {
    for (let idx = this.stack.length - 1; idx > -1; idx--) {
      const action = this.stack[idx];

      if (event instanceof Event.RawMouseEvent) {
        event = action.actionMouse(event);
      } else if (event instanceof Event.RawKeyEvent) {
        event = action.actionKey(event);
      } else if (event instanceof Event.BreakEvent) {
        event = action.actionBreak(event);
      } else if (event instanceof Event.CommandEvent) {
        event = action.actionCommand(event);
      } else if (event instanceof Event.ValueEvent) {
        event = action.actionValue(event);
      } else if (event instanceof Event.DynamicEvent) {
        event = action.actionDynamic(event);
      } else if (event instanceof Event.PointEvent) {
        event = action.actionPoint(event);
      } else if (event instanceof Event.PointUpEvent) {
        event = action.actionPointUp(event);
      } else if (event instanceof Event.SelectionEvent) {
        event = action.actionSelection(event);
      }
      if (event == null) { return; }
    }
  }
}

export default new ActionStack();
