import * as THREE from 'three';

export default class Pick {

    static pick(view, raw, pickable = []) {
        const opRoot = view.getRoot();
        if (!opRoot)
            return null;

        let raycaster = new THREE.Raycaster();
        const relative = view.getRelative(raw);
        raycaster.setFromCamera(relative, view.camera);

        let op = null;

        let hits = [];
        const intersects = raycaster.intersectObjects(view.scene.children, true);
        if (intersects.length > 0) {
            let opId = null;
            intersects.forEach((itr) => {
                if (itr.object.parent && itr.object.parent.type === 'Group') {
                    //TODO: Pick.pick macht sehr spezielle Annahmen über den Aufbau des Szenegraphen bzgl. Gruppen und opId, das ist wackelig
                    let group = itr.object.parent;
                    if (group.userData['opId']) {
                        opId = group.userData['opId'];
                        if (opId) {
                            op = opRoot.findOpObjectById(opId);
                            if (pickable.length < 1 || pickable.includes(op.type)) 
                                    hits.push(op);
                        }
                    }
                }
                return false;
            });

            //const picked = hits.map(o=>`${o.id} ${o.type}`);
            //GrfUtils.logger.log(`Pick::pick ${picked}`);
        }

        return hits;
    }
}