import theApp from '@/frame/Application';

import Action from '@/frame/Action';
import CommandLineScanner from '@/frame/CommandLineScanner';
import FltPlaceBlock from './FltPlaceBlock';
import FltEditAttributes from './FltEditAttributes';
import FltDelete from './FltDelete';
import Logger from '../../frame/Logger';

const State = Object.freeze({
  PLACE_BLOCK:          'State.PLACE_BLOCK',        // a block has been selected for editing
  DELETE:               'State.DELETE',             // deleting mode
  EDIT_ATTRIBUTES:      'State.EDIT_ATTRIBUTES'     // editing attributes
});
  
export default class ActTicketing extends Action {
    constructor(args) {
      super();

      this.logger = new Logger('ActTicketing');

      this.args = args;
      this.state = State.PLACE_BLOCK;

      this.viewPanel = theApp.findViewByName('Symbole');
      this.rootPanel = this.viewPanel.getRoot();

      this.view2D = theApp.findViewByName('2D Ansicht');

      //this.fltPlaceBlock = new FltPlaceBlock(this.view2D, this.viewPanel);
    }

    actionStart () {
        this.logger.log('ActTicketing::actionStart');

        this.addFilter(new FltPlaceBlock(this.view2D, this.viewPanel));
        this.state = State.PLACE_BLOCK;

        this.view2D.addCameraControls();
        this.view2D.fitToCanvas();
  
        return true;
    }

    actionDestroy () {
        this.logger.log('ActTicketing::actionDestroy');
        this.view2D.removeCameraControls();
    }

    actionBreak () {
        this.logger.log('ActTicketing::Break');
        return true;
    }

    actionCommand (event) {
        this.logger.log(`ActTicketing::Command    ${event.commandLine}`);

        const scanner = new CommandLineScanner(event.commandLine);

        switch(scanner.getCommand()) {
            case '.ActTicketing.placeBlocks': {
                    if (this.state !== State.PLACE_BLOCK) {
                        this.view2D.enableCameraControls();
                        this.addFilter(new FltPlaceBlock(this.view2D, this.viewPanel));
                        this.logger.log(`  ${this.state} --> State.PLACE_BLOCK`);
                        this.state = State.PLACE_BLOCK;
                    }
                }
                break;

            case '.ActTicketing.setDeleteMode': {
                    const deleteMode = scanner.getArg(1) == 'true';

                    if (deleteMode && this.state !== State.DELETE) {
                        this.view2D.disableCameraControls();
                        this.addFilter(new FltDelete(this.view2D, ['XOpSymbol']));
                        this.logger.log(`  ${this.state} --> State.DELETE`);
                        this.state = State.DELETE;
                    } else if (!deleteMode && this.state !== State.PLACE_BLOCK) {
                        // assumed, that the deleteMode is toggled only in den EditObjectPanel
                        this.view2D.enableCameraControls();
                        this.addFilter(new FltPlaceBlock(this.view2D, this.viewPanel));
                        this.logger.log(`  ${this.state} --> State.PLACE_BLOCK`)
                        this.state = State.PLACE_BLOCK;
                    }
                }
                break;
    
            case '.ActTicketing.editAttributes': {
                    if (this.state !== State.EDIT_ATTRIBUTES) {
                        this.view2D.disableCameraControls();
                        this.addFilter(new FltEditAttributes(this.view2D));
                        this.logger.log(`  ${this.state} --> State.EDIT_ATTRIBUTES`);
                        this.state = State.EDIT_ATTRIBUTES;
                    }
                }
                break;
        }
            
        return event;
    }
}    
