import theApp from '@/frame/Application';
import Logger from '@/frame/Logger';

export default class SelectionList  extends Set {
    static logger = new Logger('SelectionList');

    constructor (args) {
        super(args);
        this.highlight = true;
        
        this.highlightFillColor   = '#ff0000'
        this.highlightStrokeColor = '#ff0000'

        this._log = false;
    }

    //
    // All functions that change the highlight state must somehow
    // request an update. Currently we only do have model change,
    // but we need some object based infrastructure.
    //

    setHighlight(flag) {
        if (this.highlight !== flag)
        {
            this.highlight = flag;

            this.requestChange();
        }
    }

    add(object) {
        if (!this.has(object))
        {
            super.add(object);

            if (this.highlight)
                this.requestChange();
        }

        if (this._log)
            this.log('add');
    }

    delete(object) {
        if (this.has(object))
        {
            super.delete(object);

            if (this.highlight)
                this.requestChange();
        }

        if (this._log)
            this.log('delete');
    }

    clear()
    {
        if (this.size > 0)
        {
            super.clear();

            if (this.highlight)
                this.requestChange();
        }

        if (this._log)
            this.log('clear');
    }

    requestChange() {
        theApp.model.changed2d = true;      // ToDo: that should really be responsibility of the OpObject
    }

    log(context = null)
    {
        if (context === null)
            context = 'log';

        SelectionList.logger.log(`SelectionList::${context}: N_selected = ${this.size}`);
        this.forEach(op => {
            SelectionList.logger.log(`   op = { ${op.id}, ${op.name}}`);
        })
    }
}

