import {Vector3, Matrix4, Quaternion, Box3, ShapeGeometry} from 'three';
import { CADdySVGLoader } from '@/visual-events/loader/CADdySVGLoader.js';
import theApp from '@/frame/Application';

//TODO: in diesem Fall vielleicht lieber nur die Funktionen exportieren ohne Klasse? auch rad2Deg, deg2Rad u.ä. 
const rad2Deg = rad => rad * 180.0 / Math.PI; 
const deg2Rad = deg => deg * Math.PI / 180.0;

// buffers
const _q1 = new Quaternion();
const _v1 = new Vector3();
const _m1 = new Matrix4();

/**
 * static convenience methods 
 * mainly for working with Three.js Math objects
 */
export default class Geometry {
 
    /**
     * determine the scaling portions of the transform in each axis
     * 
     * e.g. getScaleX is useful to determine the scale of XOpSkBDraft, which is needed in
     * actions to calculate the world coordinates from the event coordinates delivered 
     * in sheet coordinates:
     * 
     * let scale = Geometry.getScaleX(draft.transform);
     *
     * @returns scale factor
     */
     static getScaleX(t) {
        const te = t.elements;
        return _v1.set( te[ 0 ], te[ 1 ], te[ 2 ] ).length();
    }
    
    static getScaleY(t) {
        const te = t.elements;
        return _v1.set(  te[ 4], te[ 5 ], te[ 6 ] ).length();
    }

    static getScaleZ(t) {
        const te = t.elements;
        return _v1.set( te[ 8 ], te[ 9 ], te[ 10 ] ).length();
    }

    /**
     * extract the translational portion of the transform as float array
     * @param {*} t 
     * @param {*} p 
     */
    static getTranslation(t) {
        const te = t.elements;
        return [ te[ 12 ], te[ 13 ], te[ 14 ] ];
    }

    /**
     * get the rotation angle in degree of the transform
     */
    static getRotationAngle(t) {
        Geometry._prepareQuaternion(t, _q1);
        return (_q1.z < 0 ? -2 : 2) * rad2Deg(Math.acos(_q1.w));
    }
    
    //TODO  static getRotationAxis(t) {

    // extracted from Three.hs Matrix4.js decompose
    static _prepareQuaternion(t) {
        const te = t.elements;

		let sx = _v1.set( te[ 0 ], te[ 1 ], te[ 2 ] ).length();
		const sy = _v1.set( te[ 4 ], te[ 5 ], te[ 6 ] ).length();
		const sz = _v1.set( te[ 8 ], te[ 9 ], te[ 10 ] ).length();

		// if determine is negative, we need to invert one scale
		const det = t.determinant();
		if ( det < 0 ) sx = - sx;

        // scale the rotation part
		_m1.copy( t );

		const invSX = 1 / sx;
		const invSY = 1 / sy;
		const invSZ = 1 / sz;

		_m1.elements[ 0 ] *= invSX;
		_m1.elements[ 1 ] *= invSX;
		_m1.elements[ 2 ] *= invSX;

		_m1.elements[ 4 ] *= invSY;
		_m1.elements[ 5 ] *= invSY;
		_m1.elements[ 6 ] *= invSY;

		_m1.elements[ 8 ] *= invSZ;
		_m1.elements[ 9 ] *= invSZ;
		_m1.elements[ 10 ] *= invSZ;

		_q1.setFromRotationMatrix( _m1 );
    }

    /**
     * compute the Box3 für OpObject op
     * 
     * It will recursively set op.box in order to avoid repeated calculations.
     * But: Any later transformations will not update the box!!
     * 
     * TODO:lazy evaluation ist sicher sinnvoll, aber vielleicht nach transformationen wieder plattmachen?
     * TODO: XOpSymbol hat außerdem dieselbe Box wie die Referenz
     * TODO: lokale Transformation von Untersymbolen nicht berücksichtigt
     * TODO: bisher nur für 2D Elemente
     * TODO: Abhängigkeit von theApp und SVGLoader ist Mist
     * @param {*} op 
     * @returns 
     */
    static computeBox(op) {

        //TODO: bisher nur für 2D Elemente
        if (op.box === null) {
            if (op.type === 'XOpSymbol') {
                const opSym = theApp.model.symbols[op.filename];
                op.box = Geometry.computeBox(opSym);
            } else if (op.type === 'XOpGroup' || op.type === 'XOpSym') {
                op.box = new Box3();
                op.box.makeEmpty();
                op.children.forEach(child => op.box.union(Geometry.computeBox(child)));
            } else {  
                op.box = new Box3();
                op.box.makeEmpty();
                if (op['path']) {
                    const path = op['path'];
                    const shapes = CADdySVGLoader.createShapes( path );

                    for ( let j = 0; j < shapes.length; j ++ ) {
                        const shape = shapes[ j ];
                        const geometry = new ShapeGeometry( shape );
                        geometry.computeBoundingBox();
                        op.box.union(geometry.boundingBox);
                    }
                }
            }
        }

        return op.box;
    }

    /**
     * calculate the box transformed by op's transformation
     * 
     * (only in 2D)
     * @param {*} op 
     * @returns array with the corners as Vector3
     */
    static transformedBox (op) {
        const t = op.transform;
        const vertices = [];
        const box = op.computeBox();
        _v1.set(box.min.x, box.min.y, 0);
        _v1.applyMatrix4(t);
        vertices.push(_v1.clone());
        _v1.set(box.max.x, box.min.y, 0);
        _v1.applyMatrix4(t);
        vertices.push(_v1.clone());
        _v1.set(box.max.x, box.max.y, 0);
        _v1.applyMatrix4(t);
        vertices.push(_v1.clone());
        _v1.set(box.min.x, box.max.y, 0);
        _v1.applyMatrix4(t);
        vertices.push(_v1.clone());
        return vertices;                
    }
}