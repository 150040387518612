import { Cache, Loader, Texture } from "three";

class FileServiceTextureLoader extends Loader {

    constructor( manager ) {

        super( manager );

    }

    load( url, onLoad, onProgress, onError ) {

        const texture = new Texture();

        this.loadImage( url, function (image) {

            texture.image = image;
            texture.needsUpdate = true;

            if ( onLoad !== undefined ) {

                onLoad( texture );

            }

        }, onProgress, onError );

        return texture;

    }

    loadImage( url, onLoad, onProgress, onError ) {

        if ( this.path !== undefined ) url = this.path + url;

        url = this.manager.resolveURL( url );

        const scope = this;

        const cached = Cache.get( url );

        if ( cached !== undefined ) {

            scope.manager.itemStart( url );

            setTimeout( function () {
                
                if ( onLoad ) onLoad( cached );

                scope.manager.itemEnd( url );

            }, 0 );

            return cached;

        }

        const image = document.createElementNS( 'http://www.w3.org/1999/xhtml', 'img' );

        function onImageLoad() {

            removeEventListeners();

            Cache.add( url, this );

            if ( onLoad ) onLoad( this );

            scope.manager.itemEnd( url );

        }

        function onImageError( event ) {

            removeEventListeners();

            if ( onError ) onError( event );

            scope.manager.itemError( url );
            scope.manager.itemEnd( url );

        }

        function removeEventListeners() {

            image.removeEventListener( 'load', onImageLoad, false );
            image.removeEventListener( 'error', onImageError, false );

        }

        image.addEventListener( 'load', onImageLoad, false );
        image.addEventListener( 'error', onImageError, false );

        fetch( url, { headers: this.requestHeader } )
        .then( response => response.blob() )
        .then( blob => URL.createObjectURL(blob) )
        .then( blobUrl => image.src = blobUrl );

        return image;

    }

}

export { FileServiceTextureLoader };