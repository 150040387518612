import JsonPath from '@/frame/JsonPath';

/**
 * convenience methods to work with configuration objects, which are shaped according to
 * an options pool paradigma
 * 
 * settings (opts)
 *  |___ options pool
 *          |___  named options     (provided property 'id')
 *          |__  ...
 * 
 * remark: As enum like type, we prefer snake_case ids, e.g.
 *   grid_number_row, category_price
 * 
 *  Usually the option should also have a property name, which may serve as
 *  friendly name, potentially appearing in the GUI. 
 * 
 *  TODO: Lateron this should be handled as internationalized message. 
 */
export default class Options {

    static getOption(opts, pool, id, path, forUndefined = undefined) {
        const options = JsonPath.getValue(opts, pool);
        const option = options.find(opt => opt.id === id);
        const value = JsonPath.getValue(option, path);
        return value ? value : forUndefined;
    }

    static setOption(opts, pool, id, path, value) {
        const options = JsonPath.getValue(opts, pool);
        const option = options.find(opt => opt.id === id);
        JsonPath.setValue(option, path, value);
    }
}
