import OpObject from '@/visual-events/model/OpObject'

export const TextAnchor = Object.freeze({
    start: 'start',
    middle: 'middle',
    end: 'end'
});

export const BaseLine = Object.freeze({
    baseline: 'baseline',
    middle: 'middle',
    hanging: 'hanging'
});

export default class OpText extends OpObject {

    constructor (text, fontSize, fontFamily, textAnchor = TextAnchor.start, baseLine = BaseLine.baseline) {

        super ('XOpText2', 'text');

        this.text = text;
        this.fontSize = fontSize;
        this.fontFamily = fontFamily; 
        this.textAnchor = textAnchor;
        this.baseLine = baseLine; 
        this.style = { fill: '#000000', fillOpacity: 1, stroke: '#000000', strokeLineCap: 'but', strokeLineJoin: 'miter', strokeMiterLimit: 4, strokeOpactity: 0, strokeWidth: 1}
    }

    copy () {

        const copy = new OpText(this.text, this.fontSize, this.fontFamily, this.textAnchor, this.baseLine);

        copy.setTransform(this.transform.clone());

        copy.xref = this.xref;
        
        if (this['path'])
            copy['path'] = this['path'].clone();
        if (this['style'])
            copy['style'] = Object.assign( {}, this['style'] ); // clone style

        return copy;
    }

    attachToAttribute(opId, jsonPath) {
        // const subject = findOpObjectById(opId);
        // subject.subscribe(this.id);
        // this.xref = jsonPath;
    }
}