import OpObject from '@/visual-events/model/OpObject'

export default class OpShapePath extends OpObject {

    constructor (subtype, path, style) {
        super (subtype, '');

        this.path = path;
        this.style = style;
    }

    copy () {

        const path = this.path.clone();
        const style = Object.assign( {}, this['style'] ); // clone style

        const copy = new OpShapePath(path, style);

        copy.visible = this.visible;
        copy.setTransform(this.transform);

        return copy;
    }

}
