<template>
  <div class="home-grid">
    <div class="coupled-2d-3d">
      <WebGLRenderCanvas v-bind:viewName="'2D Ansicht'">2D-Ansicht</WebGLRenderCanvas>
    </div>
    <SideMenu :panels="panels" :symbols="symbols" :model="model"></SideMenu>
  </div>
</template>

<script>
// @ is an alias to /src
import theApp from '@/frame/Application';
import Logger from '@/frame/Logger';
import WebGLRenderCanvas from '@/vuejs/components/WebGLRenderCanvas';
import SideMenu from '@/vuejs/components/SideMenu';
import Ticketing from '@/visual-events/ticketing/Ticketing';

export default {
  name: 'Home',
  props: {
    eventId: String,
    cartId: String,
    action: String,
    allStates: Boolean,
    apiKey: String,
    language: String,
    // HACK: to load the panel
    model: String,
    symbols: String,
    panels: String
  },
  components: {
    WebGLRenderCanvas,
    SideMenu
  },
  methods: {
    async load() {
      Ticketing.apiKey = this.apiKey;
      Ticketing.language = this.language;
      const urlModel = await Ticketing.getPlanForEvent(this.eventId);
      if (urlModel)
        theApp.sendCommand(`.VisualEvents.load2D "${urlModel}" ${this.action} ${this.eventId} ${this.cartId} ${this.allStates} ${this.apiKey} ${this.language}`);
    }
  },
  setup () {
    return { logger: new Logger('PlanerTicketing.vue') }
  },
  mounted () {
    this.logger.log('mounted');
    // visualEvents.init();
    this.load();
  },
  update () {
    this.logger.log('updated');
    this.load();
  },
  unmounted() {
    this.logger.log('unmounted');
  }
}
</script>

<style scoped>
* {
  margin: 0;
  box-sizing: border-box;
  scrollbar-width: thin;
}
.home {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
  height: 100vh;
}

.home-grid {
  display: grid;
  grid-template-columns: 2fr 1fr;
  overflow-x: hidden;
}

.coupled-2d-3d {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100vh;
}
</style>
