import { Vector3 } from 'three';
import Geometry from '@/visual-events/model/Geometry';
import { GridMode } from '@/visual-events/actions/GridProvider';

const rad2Deg = rad => rad * 180.0 / Math.PI; 
const deg2Rad = deg => deg * Math.PI / 180.0;

const _v = new Vector3();

export default class BlockUtils {
    
    /**
     * check, whether op is in a group structure block -> rows 
     * @param {*} op 
     * @returns group 'block xxx' or null
     */
    static findBlockGroup (op) {
        if (op.parent.type === 'XOpGroup' 
         && op.parent.name.startsWith('row')) {
            const row = op.parent;
            if (row.parent.type === 'XOpGroup' 
             && row.parent.name.startsWith('block'))
                return row.parent;
        }
        return null;
    }

    static analyseBlockGroup (block, grid) {
        const op = block.children[0].children[0];
        const info = BlockUtils.analyseSymbol(op);

        const widthX = info.widthX;
        const widthY = info.widthY;

        const cntY = block.children.length;
        const cntX = block.children.reduce((cnt, row) => Math.max(cnt, row.children.length) , 1);

        //TODO: stark vereinfacht, vgl. GridAnalyser (Lücken, Versatz...)
        let distX = grid.distX;
        let angleX = grid.angleX;
        if (cntX > 1) {
            const row1 = block.children[0];            
            const offsetX = BlockUtils.dist(row1.children[0], row1.children[1]);
            distX = offsetX - widthX;
            angleX = BlockUtils.angle(row1.children[0], row1.children[1]);
        }

        let distY = grid.distY;
        let angleY = grid.angleY;
        if (cntY > 1) {
            const row1 = block.children[0];            
            const row2 = block.children[1];            
            const offsetY = BlockUtils.dist(row1.children[0], row2.children[0]);
            distY = offsetY - widthY;
            angleY = BlockUtils.angle(row1.children[0], row2.children[0]);
        }

        if (cntX === 1 && cntY === 1) {
            grid.mode = GridMode.SINGLE;
        } else {
            grid.mode = GridMode.RECT;
            grid.cntX = Math.max(cntX, 1);
            grid.cntY = Math.max(cntY, 1);
            grid.distX = distX;
            grid.distY = distY
        }

        grid.widthX = widthX;
        grid.widthY = widthY;
        grid.angleX = angleX;
        grid.angleY = angleY;

        grid.origin = { x: info.x, y: info.y };
        grid.vecX = { x: Math.cos(deg2Rad(angleX)), y: Math.sin(deg2Rad(angleX)) };
        grid.vecY = { x: Math.cos(deg2Rad(angleY)), y: Math.sin(deg2Rad(angleY)) };

        return op; //copy ??
    }

    static dist(op1, op2) {
        const info1 = BlockUtils.analyseSymbol(op1);
        const info2 = BlockUtils.analyseSymbol(op2);
        return Math.sqrt((info2.x - info1.x) * (info2.x - info1.x) + (info2.y - info1.y) * (info2.y - info1.y));
    }

    static angle(op1, op2) {
        const info1 = BlockUtils.analyseSymbol(op1);
        const info2 = BlockUtils.analyseSymbol(op2);
        const dX = info2.x - info1.x;
        const dY = info2.y - info1.y;
        return rad2Deg(Math.atan2(dY, dX));
    }

    static analyseSymbol (op) {
        const t = op.transform;
        const p = Geometry.getTranslation(t);
        const angle = Geometry.getRotationAngle(t);
        const sx = Geometry.getScaleX(t);
        
        const box = op.computeBox();
        const widthX = box.max.x - box.min.x;
        const widthY = box.max.y - box.min.y;

        return { x: p[0], y :p[1], angle: angle, scale: sx, widthX : widthX, widthY: widthY };
    }
}