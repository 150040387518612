import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { FirstPersonControlsAdv } from './FirstPersonControls';

import { Box3, Vector3, WebGLRenderer } from 'three';

import GrfOpSpace from '@/visual-events/view/GrfOpSpace'
import View from '@/frame/View';
import Logger from '@/frame/Logger';

export default class VisualEvents3DView extends View {
  constructor (name, model) {
    super(name, model);
    this.logger = new Logger('VisualEvents3DView');
    this.renderer = null;
    this.scene = null;

    this.root = null;

    // the exact knowledge about how to build and update the scene is extracted to GrfXXX objects
    this.grf = new GrfOpSpace();

    this.last = 0; // last timestamp in render

    this.toneMapping = {
      "NoToneMapping": THREE.NoToneMapping,
      "LinearToneMapping": THREE.LinearToneMapping,
      "CineonToneMapping": THREE.CineonToneMapping,
      "ReinhardToneMapping": THREE.ReinhardToneMapping,
      "ACESFilmicToneMapping": THREE.ACESFilmicToneMapping,
    };
  }
  
  init () {
    this.logger.log('VisualEvents3DView::init');
    if (!this.canvas) { return false; }

    this.camera = new THREE.PerspectiveCamera(45, 1, 1, 100000);

    this.scene = new THREE.Scene();

    this.renderer = new WebGLRenderer({
        preserveDrawingBuffer: false,
    });
    this.renderer.setClearColor(0xdddddd);
    this.renderer.outputEncoding = THREE.sRGBEncoding;
    this.renderer.physicallyCorrectLights = true;

    this.controls = new OrbitControls(this.camera, this.renderer.domElement);
    this.controls.update();

    //this.addDiagnostics();

    return true;
  }

  getRoot () {
    return this.root;
  }

  setRoot (op) {
    this.root = op;
  }

  getBoundingBox() {
    let boundingBox = new THREE.Box3();

    this.scene.traverse((child) => {
      if (child.type === 'Mesh') {
        let box = new THREE.Box3().setFromObject(child);
        boundingBox.union(box);
      }
    });

    return boundingBox;
  }

  /**
     * Adds light sources that were defined in the settings file to the scene.
     * 
     * Currently supported: DirectionalLight, PointLight, SpotLight.
     * As camera light: DirectionalLight
     */
   addLight() {

    if (this.model.light) {

        // Renderer settings

        var toneMapping = this.toneMapping[this.model.light.HDRStage["ToneMapMode:"]];
        this.renderer.toneMapping = toneMapping;
        this.renderer.toneMappingExposure = this.model.light.HDRStage["Exposure:"];
        this.renderer.toneMappingWhitePoint = this.model.light.HDRStage["WhiteLevel:"];

        this.renderer.shadowMap.enabled = this.model.light.ShadowStage.Enabled;
        this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;

        // Ambient light

        this.ambientLight = new THREE.AmbientLight(new THREE.Color("rgb(" + this.model.light.AmbientLight.color[0] + "," + this.model.light.AmbientLight.color[1] + "," + this.model.light.AmbientLight.color[2] + ")"), this.model.light.AmbientLight.intensity);
        this.scene.add(this.ambientLight);

        // this.model.light sources

        var target = null;

        this.model.light.LightSourceArray.forEach((light) => {

            if (!light.Active)
                return;

            switch (light.Type) {

                case "DirectionalLight":

                    if (light.CameraLight) {

                        if (this.cameraLight)
                            this.camera.remove(this.cameraLight);

                        this.cameraLight = new THREE.DirectionalLight(new THREE.Color("rgb(" + light.Color[0] + ", " + light.Color[1] + ", " + light.Color[2] + ")"));
                        this.scene.add(this.camera);
                        this.camera.add(this.cameraLight);

                        target = new THREE.Object3D();
                        this.camera.add(target);

                        this.cameraLight.target = target;

                        target.position.set(0, 0, -1);

                        this.cameraLight.position.copy(this.camera.position);            

                    } else {

                        var boxSize = new THREE.Vector3();
                        var center = new THREE.Vector3();
                        var boundingBox = this.getBoundingBox();
                        boundingBox.getSize(boxSize);
                        boundingBox.getCenter(center);

                        var directionalLight = new THREE.DirectionalLight(new THREE.Color("rgb(" + light.Color[0] + "," + light.Color[1] + "," + light.Color[2] + ")"));
                        directionalLight.position.copy(center);
                        directionalLight.intensity = light.Intensity;
    
                        var lightTarget = new THREE.Object3D();
                        this.scene.add(lightTarget);
                        directionalLight.target = lightTarget;

                        target = new THREE.Vector3();
                        target.copy(directionalLight.position);
                        target.add(new THREE.Vector3(light.Direction[0], light.Direction[1], light.Direction[2]));
    
                        lightTarget.position.copy(target);
                        lightTarget.updateMatrixWorld();

                        if (light.CastShadow && this.model.light.ShadowStage.Enabled) {

                            directionalLight.castShadow = true;

                            directionalLight.shadow.mapSize.width = this.model.light.ShadowStage.ShadowMapWidth;
                            directionalLight.shadow.mapSize.height = this.model.light.ShadowStage.ShadowMapHeight;
                            // directionalLight.shadow.bias = light.ShadowBias;
                            // directionalLight.shadow.radius = light.FilterRadius;

                            directionalLight.shadow.camera.near = 1;
                            directionalLight.shadow.camera.far = boxSize.y;
                            directionalLight.shadow.camera.top = boxSize.z;
                            directionalLight.shadow.camera.bottom = -boxSize.z;
                            directionalLight.shadow.camera.left = -boxSize.x;
                            directionalLight.shadow.camera.right = boxSize.x;
                            directionalLight.shadow.camera.updateProjectionMatrix();

                            // var directionalLightHelper = new THREE.CameraHelper(directionalLight.shadow.camera);
                            // this.scene.add(directionalLightHelper);

                            directionalLight.userData = 'shadow';

                        }
    
                        this.scene.add(directionalLight);
    
                    }

                    break;
                
                case "PointLight":

                    if (light.CameraLight) {

                      console.warn('VisualEvents3DView::addLightsFromSettings PointLight not supported as camera light yet');

                    } else {

                        var pointLight = new THREE.PointLight(new THREE.Color("rgb(" + light.Color[0] + "," + light.Color[1] + "," + light.Color[2] + ")"));
                        pointLight.position.set(light.Position[0], light.Position[1], light.Position[2]);
                        pointLight.power = light.Intensity;
                        pointLight.distance = light.Distance;
                        pointLight.decay = light.Decay;

                        if (light.CastShadow && this.model.light.ShadowStage.Enabled) {

                            pointLight.castShadow = true;

                            pointLight.shadow.mapSize.width = this.model.light.ShadowStage.ShadowMapWidth;
                            pointLight.shadow.mapSize.height = this.model.light.ShadowStage.ShadowMapHeight;
                            // pointLight.shadow.bias = light.ShadowBias;
                            // pointLight.shadow.radius = light.FilterRadius;

                            pointLight.shadow.camera.near = 1;
                            pointLight.shadow.camera.far = 100000;

                            // var cameraHelper = new THREE.CameraHelper(pointLight.shadow.camera);
                            // this.scene.add(cameraHelper);

                            pointLight.userData = 'shadow';

                        }

                        this.scene.add(pointLight);

                    }

                    break;

                case "SpotLight":

                    if (light.CameraLight) {

                      console.warn('VisualEvents3DView::addLightsFromSettings SpotLight not supported as camera light yet');

                    } else {

                        var spotLight = new THREE.SpotLight(new THREE.Color("rgb(" + light.Color[0] + "," + light.Color[1] + "," + light.Color[2] + ")"));
                        spotLight.power = light.Intensity;
                        spotLight.position.set(light.Position[0], light.Position[1], light.Position[2]);

                        var spotLightTarget = new THREE.Object3D();
                        this.scene.add(spotLightTarget);

                        spotLight.target = spotLightTarget;

                        target = new THREE.Vector3();
                        target.copy(spotLight.position);
                        target.add(new THREE.Vector3(light.Direction[0], light.Direction[1], light.Direction[2]));

                        spotLightTarget.position.copy(target);

                        spotLightTarget.updateMatrixWorld();

                        spotLight.angle = THREE.Math.degToRad(light.Angle);
                        spotLight.distance = light.Distance;
                        spotLight.decay = light.Decay;
                        spotLight.penumbra = light.Penumbra;

                        if (light.CastShadow && this.model.light.ShadowStage.Enabled) {

                            spotLight.castShadow = true;

                            spotLight.shadow.mapSize.height = this.model.light.ShadowStage.ShadowMapHeight;
                            spotLight.shadow.mapSize.width = this.model.light.ShadowStage.ShadowMapWidth;
                            // spotLight.shadow.bias = light.ShadowBias;
                            // spotLight.shadow.radius = light.FilterRadius;

                            spotLight.shadow.camera.near = 1;
                            spotLight.shadow.camera.far = 100000;

                            // var spotLightHelper = new THREE.CameraHelper(spotLight.shadow.camera);
                            // this.scene.add(spotLightHelper);

                            spotLight.userData = 'shadow';

                        }

                        this.scene.add(spotLight);

                    }

                    break;

            }

        });

    }

}

  clearScene () {
    const removable = [];
    this.scene.traverse(child => {
      if (child.type === 'Object3D') {
        removable.push(child);
      }
    });

    removable.forEach(item => {
      this.scene.remove(item);
    });
  }

  updateScene (time) {
    //this.logger.log(`VisualEvents3DView::updateScene`, time);
    this.grf.updateScene(this);
  }

  /**
   * look from an elevated position from z-direction into the center of the geometry box
   */
  centerCamera() {
    const boundingBox = this.getBoundingBox();
    const center = new THREE.Vector3();
    const size = new THREE.Vector3();
    boundingBox.getCenter(center);
    boundingBox.getSize(size);
    const position = new THREE.Vector3(center.x, center.y + 5*size.y, center.z + size.z);

    // position.set(0, 0, 10000);
    // center.set(0,0,0);
    this.updateCamera(position, center);
  }

  setOrbitControls() {
    this.controls = new OrbitControls(this.camera, this.renderer.domElement);
    this.centerCamera();
  }

  /**
   * 
   * @param {THREE.Object3D} mesh Camera position
   */
  setFirstPersonControls(mesh) {
    if (this.controls) {
      this.controls.dispose();
      this.controls = null;
    }
    this.controls = new FirstPersonControlsAdv(this.camera, this.renderer.domElement);
    this.controls.activeLook = false;
    this.controls.lookVertical = true;
    this.controls.movementSpeed = 0;
    this.controls.lookSpeed = 0.0000001;
    let boundingBox = new Box3().setFromObject(mesh);
    let center = new Vector3(0, 0, 0);
    boundingBox.getCenter(center);
    
    this.camera.position.set(center.x, center.y + 500, center.z);
    this.controls.lookAt(new Vector3(center.x - 1, center.y + 500, center.z));

    this.updateCamera();
  }

  /**
   * 
   * @param {THREE.Vector3} position Camera position
   * @param {THREE.Vector3} target Camera target
   */
  updateCamera (position, target) {
    if (position && target) {
      this.controls.target.copy(target);
      this.camera.position.copy(position);
    }

    this.camera.updateProjectionMatrix();
  }

  render (time) {
    // this.logger.log('VisualEvents3DView::render' + elapsed);
    const elapsed = time - this.last;
    this.last = time;

    // initialize or update the scene
    this.updateScene(time);
    this.controls.update(time);

    // let the threejs renderer display the scene
    if (this.renderer) {
      this.renderer.render(this.scene, this.camera);
    }
  }
}
