<template>
  <div class="container-fluid">
    <div class="row justify-content-center mb-3">
        <div class="col-sm-12 d-flex justify-content-center">
            <span class="display-1">Saalplaneditor</span>
        </div>
    </div>
    <div class="row justify-content-center mt-3">
        <div class="col-sm-auto d-flex align-items-end">
            <div class="card model-card" style="width: 18rem;">
                <div class="card-body">
                    <h5 class="card-title">Buchungssystem</h5>
                    <p class="card-text"> Einstein/Stuhlreihen<br>Jörg Mitscherling</p>
                    <a :href="`#/booking?eventId=5&cartId=3&action=.VisualEvents.booking`"  class="btn btn-primary stretched-link">Weiter</a>
                </div>
            </div>
        </div>
        <div class="col-sm-auto d-flex align-items-end">
            <div class="card model-card" style="width: 18rem;">
                <div class="card-body">
                    <h5 class="card-title">Buchungssystem mit 3D</h5>
                    <p class="card-text"> Einstein/Stuhlreihen<br>Jörg Mitscherling</p>
                    <a :href="`#/booking3d?eventId=30&cartId=3&action=.VisualEvents.booking`"  class="btn btn-primary stretched-link">Weiter</a>
                </div>
            </div>
        </div>
        <div class="col-sm-auto d-flex align-items-end">
            <div class="card model-card" style="width: 18rem;">
                <div class="card-body">
                    <h5 class="card-title">Buchungssystem</h5>
                    <p class="card-text">Einstein/Stuhlreihen<br>Michael Heinen</p>
                    <a :href="`#/booking?eventId=5&cartId=2&action=.VisualEvents.booking`"  class="btn btn-primary stretched-link">Weiter</a>
                </div>
            </div>
        </div>
        <div class="col-sm-auto d-flex align-items-end">
            <div class="card model-card" style="width: 18rem;">
                <div class="card-body">
                    <h5 class="card-title">Neu</h5>
                    <p class="card-text">Erstellen Sie einen neue Bestuhlungsplanung in einem leeren Saal 'Einstein'</p>
                    <a :href="`#/planer2D?model=einstein&symbols=Tischgruppen&variant=Tagungsraum&action=.VisualEvents.placeBlock`"  class="btn btn-primary stretched-link">Weiter</a>
                </div>
            </div>
        </div>
        <div class="col-sm-auto d-flex align-items-end">
            <div class="card model-card" style="width: 18rem;">
                <div class="card-body">
                    <h5 class="card-title">Neu (TicketingPlaner)</h5>
                    <p class="card-text">Erstellen Sie einen neue Bestuhlungsplanung in einem leeren Saal 'Einstein'</p>
                    <a :href="`#/planerTicketing?eventId=13&action=.VisualEvents.ticketing&model=einstein&panels=panels&symbols=Stühle und Tische`"  class="btn btn-primary stretched-link">Weiter</a>
                </div>
            </div>
        </div>
        <div class="col-sm-auto">
            <div class="card model-card" style="width: 18rem;">
                <!-- <svg class="bd-placeholder-img card-img-top" width="100%" height="180" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: Image cap" preserveAspectRatio="xMidYMid slice" focusable="false"><title>Placeholder</title><rect width="100%" height="100%" fill="#868e96"></rect></svg> -->
                <img :src="`images/Bankett.png`" height="180">
                <div class="card-body">
                    <h5 class="card-title">Einstein - Bankett</h5>
                    <p class="card-text">Bankettbestuhlung im Hotel Einstein</p>
                    <p class="card-text"><small class="text-muted">Vor 3 Minuten zuletzt bearbeitet</small></p>
                    <a :href="`#/planer?model=einstein&symbols=Tischgruppen&variant=Bankett&action=.VisualEvents.move3DObject`" class="btn btn-primary stretched-link">Öffnen</a>
                </div>
            </div>
        </div>
        <div class="col-sm-auto">
            <div class="card model-card" style="width: 18rem;">
                <!-- <svg class="bd-placeholder-img card-img-top" width="100%" height="180" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: Image cap" preserveAspectRatio="xMidYMid slice" focusable="false"><title>Placeholder</title><rect width="100%" height="100%" fill="#868e96"></rect></svg> -->
                <img :src="`images/Tischgruppen.png`" height="180">
                <div class="card-body">
                    <h5 class="card-title">Einstein - Parlamentarisch</h5>
                    <p class="card-text">Kongress Bestuhlung im Hotel Einstein</p>
                    <p class="card-text"><small class="text-muted">Vor 10 Minuten zuletzt bearbeitet</small></p>
                    <a :href="`#/planer?model=einstein&symbols=Tischgruppen&variant=Parlamentarisch&action=.VisualEvents.move3DObject`" class="btn btn-primary stretched-link">Öffnen</a>
                </div>
            </div>
        </div>
        <div class="col-sm-auto">
            <div class="card model-card" style="width: 18rem;">
                <!-- <svg class="bd-placeholder-img card-img-top" width="100%" height="180" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: Image cap" preserveAspectRatio="xMidYMid slice" focusable="false"><title>Placeholder</title><rect width="100%" height="100%" fill="#868e96"></rect></svg> -->
                <img :src="`images/Stuhlreihen.png`" height="180">
                <div class="card-body">
                    <h5 class="card-title">Einstein - Stuhlreihen</h5>
                    <p class="card-text">Kongress Bestuhlung im Hotel Einstein</p>
                    <p class="card-text"><small class="text-muted">Vor 10 Minuten zuletzt bearbeitet</small></p>
                    <a :href="`#/planer?model=einstein&symbols=Tischgruppen&variant=Stuhlreihen&action=.VisualEvents.move3DObject`" class="btn btn-primary stretched-link">Öffnen</a>
                </div>
            </div>
        </div>
        <div class="col-sm-auto d-flex align-items-end">
            <div class="card model-card" style="width: 18rem;">
                <div class="card-body">
                    <h5 class="card-title">Auswahl-Panel</h5>
                    <p class="card-text">Definieren Sie die Möbelauswahl'</p>
                    <a :href="`#/planer?model=einstein&symbols=Tischgruppen&variant=Tischgruppen&action=.VisualEvents.placeBlock`"  class="btn btn-primary stretched-link">Weiter</a>
                </div>
            </div>
        </div>
        <div class="col-sm-auto d-flex align-items-end">
            <div class="card model-card" style="width: 18rem;">
                <div class="card-body">
                    <h5 class="card-title">Testausgaben</h5>
                    <p class="card-text">Texte</p>
                    <a :href="`#/test2D?model=einstein&variant=ScaleTest&action=.VisualEvents.placeBlock`"  class="btn btn-primary stretched-link">Weiter</a>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home'
}
</script>

<style scoped>
    .model-card:hover {
        box-shadow: 0 0 2px black;
    }
</style>
