import * as THREE from 'three';

import { WebGLRenderer } from 'three';
// import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import CameraControls from 'camera-controls/dist/camera-controls.module';
import GrfOpDrawing from '@/visual-events/view/GrfOpDrawing'
import View from '@/frame/View';
import Logger from '../../frame/Logger';

const clamp = (num, a, b) => Math.max(Math.min(num, Math.max(a, b)), Math.min(a, b));

export default class VisualEvents2DView extends View {
  constructor (name, model) {
    super(name, model);

    this.logger = new Logger('VisualEvents2DView');
    
    this.renderer = null;
    this.scene = null;

    this.root = null;

    // the exact knowledge about how to build and update the scene is extracted to GrfXXX objects
    this.grf = new GrfOpDrawing();

    this.last = 0; // last timestamp in render
  }

  getRoot () {
    return this.root;
  }

  setRoot (op) {
    this.root = op;
  }

  init () {
    this.logger.log('VisualEvents2DView::init');
    if (!this.canvas) { return false; }

    const { width, height } = this.calcSize();

    // camera looks y-axis down
    this.camera = new THREE.OrthographicCamera(-width / 2, width / 2, height / 2, -height / 2, -2000, 2000);
    this.camera.position.set(0, 1000, 0);
    this.camera.lookAt(0, 0, 0);
    this.camera.up.set(0, 0, -1);
    this.camera.zoom = 1.0;

    this.scene = new THREE.Scene();
    this.scene.background = new THREE.Color(1,1,1);   

    this.renderer = new WebGLRenderer({ antialias : true });
    this.renderer.debug = false;

    window.addEventListener('resize', this.resize.bind(this), false)

    //this.addDiagnostics();

    return true;
  }

  /**
   * establish the camera-control, i.e. in Actions 
   * - allow for zooming the drawing
   * - move by dragging with the left mouse button
   * 
   * zoomMin, zoomMax sets boundaries for zooming with respect to the plan fitted into the canvas.
   * zoomMin =  1 effectivly disables zooming out.
   * 
   * attention: Both, the ActionStack and the CameraControls add event listeners to the canvas
   * The ActionStack must be first in sequence of listeners, in order to catch and - if appopriate - 
   * stop the propagation, e.g.
   * 
   * Left mouse might pick a geometry for doing something with it. In this case the CameraControls
   * must not begin dragging around. 
   * 
   * -> call this in actions during Action.actionStart, which comes later than mounting the view,
   * where the ActionStack add its listeners.
   */
  addCameraControls(zoomMin = 1, zoomMax = 50) {
    CameraControls.install({ THREE: THREE });
    this.controls = new CameraControls(this.camera, this.canvas);
    this.controls.mouseButtons.left = CameraControls.ACTION['TRUCK'];
    this.controls.mouseButtons.right = CameraControls.ACTION['NONE'];
    this.controls.touches.one = CameraControls.ACTION['TOUCH_TRUCK'];
    this.controls.mouseButtons.middle = CameraControls.ACTION['NONE'];
    //TODO: when zooming out to max, maybe center the view?
    this.controls.dollyToCursor = true;
    this.controls.minAzimuthAngle = 0;
    this.controls.maxAzimuthAngle = 0;
    this.controls.minPolarAngle = Math.PI / 2;
    this.controls.maxPolarAngle = Math.PI / 2;
    this.zoomMin = zoomMin;
    this.zoomMax = zoomMax;
    this.adaptZoomBoundaries();
  }

  /**
   * remove the camera-controls
   * 
   * call this in Action.destroyAction
   */
  removeCameraControls() {
    this.controls.removeAllEventListeners();
    this.controls = null;
  }

  /**
   * enable and disable the camera-controls, e.g. in the time
   */
  enableCameraControls() { this.controls && (this.controls.enabled = true); }
  disableCameraControls()  { this.controls && (this.controls.enabled = false); }

  /**
   * use the camera-controls to fit the grafic into a reasonable
   */
  fitToCanvas() {
    this.updateScene(); // otherwise the scene is empty and the box, too
    let boxTotal = new THREE.Box3();
    boxTotal.setFromObject(this.scene);
    if (this.controls && !boxTotal.isEmpty()) {
      this.controls.minZoom = 0.01;
      this.controls.maxZoom = Infinity;
      this.controls.fitToBox(boxTotal, false, { paddingBottom: 60, paddingLeft: 60, paddingRight: 60, paddingTop: 60 });
      this.adaptZoomBoundaries();
    }
  }

  adaptZoomBoundaries() {
    const z = this.controls._zoom;
    this.logger.log(`adaptZoomBoundaries around ${z}`)
    this.controls.minZoom = z * this.zoomMin;
    this.controls.maxZoom = z * this.zoomMax;
  }

  clearScene () {
    const removable = [];
    this.scene.traverse(child => {
      if (child.type !== 'Scene' && child.type !== 'AmbientLight') {
        removable.push(child);
      }
    });

    removable.forEach(item => {
      if (item.geometry)
         item.geometry.dispose();
      if (item.material) {
        if (Array.isArray(item.material))
          item.material.foreach(m => m.dispose());
        else
          item.material.dispose();
      }
      this.scene.remove(item);
    });
  }

  updateScene (time) {
    //this.logger.log(`VisualEvents2DView::updateScene`, time);
    this.grf.updateScene(this);
  }

  render (time) {
    // this.logger.log('VisualEvents2DView::render' + elapsed);
    const elapsed = time - this.last;
    this.last = time;

    // initialize or update the scene
    this.updateScene(time);

    if (this.controls) this.controls.update(this.last);

    // let the threejs renderer display the scene
    if (this.renderer) {
      this.renderer.render(this.scene, this.camera);
    }
  }

  // preliminary commands for camera manipulation

  /**
   *
   * @param {*} args
   */
  center (args) {
    if (!this.camera) {
      return;
    }

    if (args.length === 3) {
      // move the camera to args[1], args[2]
      const x = args[1] ? parseFloat(args[1]) : 0;
      const z = args[2] ? parseFloat(args[2]) : 0;
      this.camera.position.set(x, 1000, z);
      this.camera.lookAt(x, 0, z);
    } else if (args.length === 5) {
      // move the camera into the center of the rectangle left, top, right, bottom
      // left = args[1], args[2], args[3], args[4]
      // and zoom accordingly
      const left = args[1] ? parseFloat(args[1]) : 0;
      const top = args[2] ? parseFloat(args[2]) : 0;
      const right = args[3] ? parseFloat(args[3]) : 0;
      const bottom = args[4] ? parseFloat(args[4]) : 0;
      const x = (left + right) / 2;
      const z = (top + bottom) / 2;
      this.camera.position.set(x, 1000, z);
      this.camera.lookAt(x, 0, z);

      if (Math.abs(right - left) > Number.EPSILON && Math.abs(bottom - top) > Number.EPSILON) {
        const { width, height } = this.calcSize();
        this.camera.zoom = Math.min(
          width / Math.abs(right - left),
          height / Math.abs(bottom - top)
        );
      }
    }
    this.camera.updateProjectionMatrix();
    // if (this.controls) this.controls.update();
  }

  translate (dx, dz, minX, maxX, minZ, maxZ) {
    const x = clamp(this.camera.position.x + dx, minX, maxX);
    const z = clamp(this.camera.position.z + dz, minZ, maxZ);
    this.camera.position.set(x, 1000, z);
    this.camera.lookAt(x, 0, z);
    this.camera.updateProjectionMatrix();
  }

  resize() {
    this.camera.updateProjectionMatrix();
    this.renderer.setSize(this.canvas.offsetWidth, this.canvas.offsetHeight, true);
  }

  getMeshById(id) {
    let mesh = null;
    this.scene.traverse(child => {
      if (child.userData.opId == id)
        mesh = child;
    });
    return mesh;
  }

  static up (args) {}
  static down (args) {}
  static left (args) {}
  static right (args) {}
  static rotateLeft (args) {}
  static rotateRight (args) {}
  static zoomIn (args) {}
  static zoomOut (args) {}  

  setGrfStrategy(strategy) {
    if (this.grf)
      this.grf.setGrfStrategy(strategy);
  }

  getGrfStrategy() {
      return this.grf ? this.grf.getGrfStrategy() : null;
  }
}
