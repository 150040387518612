export default {
    // settings
    grid: {
      mode: 'SINGLE',
      cntX : 4,
      cntY : 3,
      distX : 0.0,
      distY : 500.0,
      angleX :  0.0,
      angleY : 90.0,
      alternate : false,
      keepRatioXY : false
    },
    numeration: {
      startNumber: 1,
      modeInRow: 'LEFT',
      modeAlternate: 'NORMAL',
      modeRows: 'PER_ROW'
    },
    raster: {
      enabled: true,
      size: 10
    }
  };
  