
export default class RestAPI {

    static async getResponse(endpoint, method, header) {
        const response = await fetch(
            endpoint,
            {
                method: method,
                headers: header,
            }
        );
        if (!response.ok) {
            const error = await response.json();
            throw new Error(`HTTP error! status: ${response.status} ${response.statusText} ${error}`);
        }

        const type = response.headers.get('Content-Type');
        if (type && type.includes('application/json'))
            return await response.json();

        return response;
    }

    static async postResponse(endpoint, method, header, post_data = {}) {
        const response = await fetch(
            endpoint,
            {
                method: method,
                headers: header,
                body: JSON.stringify(post_data),
            }
        );
        if (!response.ok) {
            const error = await response.json();
            throw new Error(`HTTP error! status: ${response.status} ${response.statusText}  ${error}`);
        }
        const type = response.headers.get('Content-Type');
        if (type && type.includes('application/json'))
            return await response.json();

        return null;
    }
}