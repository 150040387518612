
import * as THREE from 'three';
import GrfUtils from './GrfUtils';
import Logger from '@/frame/Logger';

import OpMesh from '@/visual-events/model/OpMesh'
import OpReference from '@/visual-events/model/OpReference'
import { OP_TO_THREE } from '@/visual-events/model/OpCoordinates.js';

export default class GrfOpSpace {
  constructor () {
    this.logger = new Logger('GrfOpSpace')
    this.logger.log('GrfOpModel constructor');

    this.currentTransform = new THREE.Matrix4();
    this.transformStack = [];

    // attach OpObject ids to the THREE.Group for picking
    // set this.id to enforce it in the subtree of XOpSymbols
    this.idStack = [];

    // reuse helper objects in order to avoid massive amounts of allocations
    this.vector = new THREE.Vector3();

    this.font = GrfUtils.loadFont('gentilis_regular.typeface.json');
  }

  updateScene (view) {
    // this.logger.log('GrfOpModel::buildScene');

    const model = view.model;

    const opRoot = view.getRoot();
    if (!opRoot)
      return;

    // preliminary: simple changed mechanism to inform views, that
    // rebuild is required
    if (model.changed3d) {
      view.clearScene(view);
      this.buildScene(view, opRoot.children);
      model.changed3d = false;
    }
  }

  //TODO: buildScene für 2D und 3D müsste eigentlich zusammengeführt werden können
  buildScene(view, children) {

      children.forEach(op => {
        
        if (op.transform) {
          this.transformStack.push(this.currentTransform);
          const t = new THREE.Matrix4();
          t.copy(this.currentTransform);
          this.currentTransform = t;
          this.currentTransform.multiply(op.transform);
        }
  
        if (op instanceof OpReference) {

          const opSym = view.model.symbols[op.filename];
          this.idStack.push(op.id);
  
          if (opSym)
            this.buildScene(view, opSym.children);

          this.idStack.pop();

        } else if (op instanceof OpMesh) {

          const mesh = op.mesh?.clone();//TODO: besser instancing

          if (mesh) {
            mesh.applyMatrix4(this.currentTransform);
            mesh.applyMatrix4(OP_TO_THREE);
            mesh.userData['opId']= this.idStack.length > 0 ? this.idStack[0] : op.id;
            view.scene.add(mesh);
          }

        } else {  //group TODO: testen, ob die transforms für 3D Gruppen (Bauteile)?
          this.buildScene(view, op.children);
        }

        if (op.transform) {
          this.currentTransform = this.transformStack.pop();
        }
    });
  
  }

  // createSceneItemInstancedMesh() {
    // var GetSceneItemTransform = function (parent, id, list) {
    //   parent.forEach((child) => {

    //     if (child.refId === id)
    //         list.push(child.transform);

    //     GetSceneItemTransform(child.children, id, list);

    //   });
    // }

    // const mesh = view.model.meshes[t.filename];

    // if (!mesh.alreadyLoaded) {
    //   var list = [];
    //   GetSceneItemTransform(view.model.space, t.refId, list);

    //   var finalObject = new THREE.Object3D();

    //   mesh.traverse((child) => {
    //     if (child.isMesh) {
    //       var inst = this.createInstancedMesh(child, list);
    //       finalObject.add(inst);
    //     }
    //   });

    //   view.scene.add(finalObject);
    //   view.model.meshes[t.filename].alreadyLoaded = true;
    // }
  // }

  createInstancedMesh(child, list) {
    var count = list.length === 0 ? 1 : list.length;

    // var objectBoundingBox = new Box3().setFromObject(child);
    // scope.boundingBox = scope.boundingBox.union(objectBoundingBox);

    var bufferGeometry = child.geometry.clone();
    var material = null;

    var castShadow = true;

    if (Array.isArray(child.material)) {

        var matArray = [];

        child.material.forEach((mat) => {
            var cloneMat = mat.clone();
            matArray.push(cloneMat);

            if (cloneMat.transparent)
                castShadow = false;
        });

        material = matArray;

    } else {

        material = child.material.clone();
        
        if (material.transparent)
            castShadow = false;

    }

    var mesh = new THREE.InstancedMesh(bufferGeometry, material, count);
    
    if (mesh) {

        mesh.frustumCulled = false;

        if (castShadow)
            mesh.castShadow = true;
            mesh.receiveShadow = true;

        // Clone matrix from GLTF imported object
        var originalMatrix = child.matrix.clone();

        var p1Matrix = new THREE.Matrix4();
        var p1MatrixInverse = new THREE.Matrix4();

        // Set rotation matrix and inverse
        var kMatrix = new THREE.Matrix4();
        kMatrix.set(1.0, 0.0, 0.0, 0.0,
                    0.0, 0.0, 1.0, 0.0,
                    0.0,-1.0, 0.0, 0.0,
                    0.0, 0.0, 0.0, 1.0);

        var kMatrixInverse = new THREE.Matrix4();
        kMatrixInverse.copy(kMatrix).invert();

        for (var i = 0; i < count; i++) {

            if (i === 0) {

                // Set the position matrix from the original object (P1) and its inverse
                var strOriginal = list[i];
                if (!strOriginal || strOriginal.length === 0)
                    continue;
                var aOriginal = strOriginal.split(',');
                p1Matrix.set( aOriginal[0], aOriginal[1], aOriginal[2], aOriginal[3],
                              aOriginal[4], aOriginal[5], aOriginal[6], aOriginal[7],
                              aOriginal[8], aOriginal[9], aOriginal[10], aOriginal[11],
                              aOriginal[12], aOriginal[13], aOriginal[14], aOriginal[15] );

                p1MatrixInverse.copy(p1Matrix).invert();

                // First object uses the matrix from GLTF
                mesh.setMatrixAt(i, originalMatrix);

            } else {

                // Clone matrix of the first object
                var originalMatrixClone = originalMatrix.clone();

                // Remove rotation then position (P1)
                originalMatrixClone.premultiply(kMatrixInverse);
                originalMatrixClone.premultiply(p1MatrixInverse);

                // Get position matrix of the next object (P2)
                var strP2Matrix = list[i];
                var aP2Matrix = strP2Matrix.split(',');
                var p2Matrix = new THREE.Matrix4();
                p2Matrix.set(aP2Matrix[0], aP2Matrix[1], aP2Matrix[2], aP2Matrix[3],
                              aP2Matrix[4], aP2Matrix[5], aP2Matrix[6], aP2Matrix[7],
                              aP2Matrix[8], aP2Matrix[9], aP2Matrix[10], aP2Matrix[11],
                              aP2Matrix[12], aP2Matrix[13], aP2Matrix[14], aP2Matrix[15] );

                // Add P2 and rotation
                originalMatrixClone.premultiply(p2Matrix);
                originalMatrixClone.premultiply(kMatrix);

                p2Matrix.premultiply(kMatrix);

                mesh.setMatrixAt(i, p2Matrix);

            }

        }

        mesh.instanceMatrix.needsUpdate = true;

    }

    return mesh;
  }
}
