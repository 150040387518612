import * as THREE from 'three';
import { CADdySVGLoader } from '@/visual-events/loader/CADdySVGLoader.js';

import Logger from '@/frame/Logger';
import OpFactory from '@/visual-events/model/OpFactory';

import { CADdyType } from '@/visual-events/loader/CADdyType'

export default class CADdy2DLoader {

    constructor() {
        this.logger = new Logger('CADdy2DLoader');

        this.opId = 0;

        // for debug output
        this.indent = '';
        this.indentStack = [];

        // prepare matrix to flip texts by y
        this.flipY = new THREE.Matrix3();
        this.flipY.scale(1,-1);

        // map xid -> OpObject for buildReferences
        this.xids = {};
    }

    /**
     * load an SVG file from 'url'
     * - onLoad (opObjects)
     *      opObjects is an array containing one drawing and all references symbols
     * - onProgress ()
     * - onError (error)
     * @param {*} url 
     */
    load(url, onLoad, onProgress, onError) {
        this.logger.log(`CADdy2DLoader.load(${url})`);

        const loader = new CADdySVGLoader();
        loader.requestHeader = this.requestHeader;

        loader.load( url, data => {

            const root = data.root;

            this.logger.log('buildObjects------------------------------------------------------------------------');
            const opObjects = this.buildOpObjects(root);
            this.logger.log('------------------------------------------------------------------------buildObjects');

            this.logger.log('buildRefences-----------------------------------------------------------------------');
            opObjects.forEach(op => this.buildReferences(op, op.children));
            this.logger.log('-----------------------------------------------------------------------buildRefences');

            onLoad(opObjects);
        }, onProgress, onError );
    }

    /**
     * Recursively, build the OpObject subtree 
     * using the structure information handed over from CADdySVGLoader.load to the callback
     * as item = {}
     *  node : related SVGElement in the SVG file
     *  path : THREE.ShapePath, if the SVGElement is path, line, rect etc.
     *  style: THREE.Style
     *  transformation : the local transformation of the node, if explicitly defined, else null meaning identity
     *  children: subtree
     * @param {*} item 
     * @returns children OpObjects
     */
     buildOpObjects(item) {

        const opObjects = [];
        const subOpObjects = [];

        const node = item.node;
        const path = item.path;
        const style = item.style;
        let transform = item.transform;

        //store svg attributes in order to attach them to the XOpDraft lateron
        if (node.nodeName === 'svg') {
            if (node.hasAttribute('width')) this.width = this.parseWithUnits(node.getAttribute('width'));
            if (node.hasAttribute('height')) this.height = this.parseWithUnits(node.getAttribute('height'));
            if (node.hasAttribute('viewBox')) this.viewBox = node.getAttribute('viewBox'); // always unitless 
        }

        if (node.hasAttribute('data-xtype')) {
            //
            // nodes with 'data-xtype' are counterparts to XOpObject items in CADdy++
            // build an OpObject for these nodes
            //

            // collect OpObject properties
            const type = node.getAttribute('data-xtype');
            const name = node.getAttribute('data-name');

            // user attributes attached to the XOpObject
            const value = node.getAttribute('data-userattributes');
            const attributes = this.prepareUserAttributes(value);

            // reference to another OpObject's user attribute
            const xid = node.getAttribute('data-xid');
            const xref = node.getAttribute('data-xref');

            // collect the children of this XOpObject in the OpModel tree
            for ( let i = 0; i < item.children.length; i ++ )
                subOpObjects.push(...this.buildOpObjects(item.children[i]));

            // logs
            //this.logger.log(`${this.indent}${node.nodeName} opId=${opId} type=${type} name=${name} refId=${refId} filename=${filename} transform=${transform ? transform.elements : null}`);
            //this.logger.log(`attributes=${value}`);


            let op;
                
            switch (type) {

                case CADdyType.GROUP: {
                    op = OpFactory.createGroup(name);
                }
                break;

                case CADdyType.SKETCHBOARD: {
                    op = OpFactory.createSketchboard(name)
                }
                break;
                
                case CADdyType.DRAWING: {
                    op = OpFactory.createDrawing(name, this.width, this.height, this.viewport)
                }
                break;

                case CADdyType.SPACE: {
                    op = OpFactory.createSpace(name)
                }
                break;

                case CADdyType.TEXT: {

                    const def = node['text'];
    
                    op = OpFactory.createText(def.text, def.fontSize, def.fontFamily, def.textAnchor, def.baseLine);
    
                    // text reference to an attribute
                    op.xref = xref;

                    //TODO: statt xref <id>/<attribute> nur Attribut bzw. JSONPath, beim Export Observer auswerten um die xrefs zu machen
                    // in Notify setAttribut abzuändern
                    // dann ist xid, xref nach dem Import komplett überflüssig
                    // während des Import (Bi-)Map halten
                    // copy wieder abändern
                    
                    op['style'] = style;
    
                    // in the svg file texts are flipped by y in order to compensate the
                    // main transform at XOpDraft. Remove this flipping from the text.
                    // Since then, the text transforms can be understood straight forward in
                    // terms of position and angle.
                    transform.multiply(this.flipY);
                }
                break;
                
                case CADdyType.SYMBOL: {
                    // for each symbol in <defs> a quasi filename is store in the id attribute
                    // this can  be referenced in <use> nodes
                    const id = node.getAttribute('id');

                    op = OpFactory.createSymbol(name, id);
                }

                break;

                case CADdyType.SYMBOL_REFERENCE: {
                    
                    // in XOpSymbols the referenced symbol name and the transform are stored in the use node below 
                    const useNodes = node.getElementsByTagName('use');
                    if (useNodes.length>0) {
                        const id = useNodes[0].getAttribute('xlink:href').substring(1);
                        //TODO: xlink:href is deprecate, use href instead (after changing export in CADdy++!), or both ..matches('.href')

                        const useItem = item.children.find(child => child.node.nodeName === 'use');

                        transform = useItem.transform;

                        op = OpFactory.createReference(name, id, this.toMatrix4(transform));
                        op.xid = xid;
                    }
                }
                break;

                case CADdyType.FACE:
                case CADdyType.POINT:
                case CADdyType.LINE:
                case CADdyType.CIRCLE:
                case CADdyType.ELLIPSE:
                case CADdyType.SPLINE:
                case CADdyType.POLYLINE: {
                    op = OpFactory.createShapePath(type, path, style);
                }
                break;

                // case CADdyType.SOLID, CADdyType.FEATURE, CADdyType.GEOMETRY: {
                //     op = OpFactory.createMesh(type, )
                // }
                //break;

                default: {
                    console.log(`CADdy2DLoader: unknown CADdy++ type ${type}`);
                }
                break;

            }

            if (op) {

                // entry into the map for buildReferences
                if (xid)
                    this.xids[xid] = op;

                if (transform)
                    op.setTransform(this.toMatrix4(transform));

                op.attributes = attributes;

                subOpObjects.forEach(child => op.add(child));

                opObjects.push(op);
            }

        } else {

            //
            // for DOMElement nodes without counterpart to XOpObject item in CADdy++ just go deeper into the tree
            //
            for ( let i = 0; i < item.children.length; i ++ ) {
                const subOpObjects = this.buildOpObjects(item.children[i]);
                opObjects.push(...subOpObjects);
            }
        }

        return opObjects;
    }

    /**
     * The CADdy++ user attributes are stored in html data attributes named 'data-userattributes'.
     * 
     * Parsing this value as json object converts it into name value pairs: 
     * 
     * {
     *      attribute1 : value1,
     *      attribute2 : value2,
     *      ...
     * } 
     * 
     * The value are either simple string values, or represent json objects.
     * 
     * prepareUserAttributes parses these values on their part in order convert the attributes into the form,
     * which is required in the OpObjects, i.e. a json in depth:
     * 
     * {
     *      attribute1 : value1, // if simple string value
     *      attribute2 : { ... }, // if json value
     *      ...
     * } 
     * 
     * @param {*} value 
     */
    prepareUserAttributes(value) {
        const attributes = {};
        
        if (value) {
            const userattributes = JSON.parse(value);
            Object.keys(userattributes).forEach(key => {
                try {
                    attributes[key] = JSON.parse(userattributes[key])
                } catch(e) { // simple values
                    attributes[key] =  userattributes[key];
                }
            });
        }

        return attributes;
    }

    /**
     * build the transform matrix in a 3D space 
     * 
     * The drawing lies on the horizontal plane, i.e. the XY plane
     * with height (Z) = 0.
     * 
     * (Do not confuse this with the transform as finally required in
     * Three.js. In Three.js the horizontal plane is XZ)
     * @param {*} mat3 
     * @returns 
     */
    toMatrix4(mat3) {
        if (!mat3)
            return null;

        const mat4 = new THREE.Matrix4();
        const a = mat3.elements;
        mat4.set(a[0],  a[3],  0,   a[6],
                 a[1],  a[4],  0,   a[7],
                 0,     0,     1,    0,
                 a[2],  a[5],  0 ,  a[8]);
        return mat4;
    }

    buildReferences(root, children) {
        children.forEach(op => {
            if (op.xref) {
                //this.logger.log(`${op.xref}`);
                const token = op.xref.split("/");
                if (token.length>1) {
                    const xid = token[0];
                    const subject = this.xids[xid];
                    if (subject) {
                        subject.subscribe(op);
                        token.splice(0, 1, subject.id);
                        op.xref = token.join('/');
                    }
                }
            }
            this.buildReferences(root, op.children);
        })
    }

    //simplified form CADdySVGLoader
    //TODO: not completely testet
    parseWithUnits(string) {

		const units = [ 'mm', 'cm', 'in', 'pt', 'pc', 'px' ];
		// Conversion: [ fromUnit ][ toUnit ] (-1 means dpi dependent)
		const unitConversion = {
				'mm': 1,
				'cm': 0.1,
				'in': 1 / 25.4,
				'pt': 72 / 25.4,
				'pc': 6 / 25.4,
				'px': - 1
		};

        let theUnit = 'mm';
        let scale = undefined;

        if ( typeof string === 'string' || string instanceof String ) {
            for ( let i = 0, n = units.length; i < n; i ++ ) {
                const u = units[ i ];
                if ( string.endsWith( u ) ) {
                    theUnit = u;
                    string = string.substring( 0, string.length - u.length );
                    break;
                }
            }
        }

        scale = unitConversion[ theUnit ];
        if (scale < 0) //?
            scale = 1;

        return scale * parseFloat( string );
    }
  }