import { CommandEvent } from './Event';
import actionStack from './ActionStack';
import CommandPoolMain from './CommandPoolMain';
import Logger from './Logger';

class Application {
  constructor () {
    this.logger = new Logger('Application');
    this.model = null;
    this.views = [];
    this.gui = null;
    this.requestId = null;

    this.mounted = [];
    this.dialogs = new Map();

    // establish commands, which should be available under all circumstances as long
    // as the application singleton lives, namely activate and deactivate logging
    this.commandPoolMain = new CommandPoolMain();
  }

  setGui (gui) {
    this.logger.log(`Application.setGui(${gui}`);
    this.gui = gui;
  }

  getGui () {
    return this.gui;
  }

  //
  // open a bootstrap modal component
  //
  // - make a bootstrap vue component like this:
  // <template>
  // <div>
  //   <b-modal id="bv-modal-example" hide-footer>
  //    ... dialog layout
  //   </b-modal>
  // </div>
  // </template>
  //
  // - register and insert the component in the vue view, in which the
  // the dialog is to be called.
  //
  // - e.g. in an action, open the dialog with
  //   theApp.openDialog('bv-modal-example');
  //
  // TODO: very bootstrap-vue specific! in derives Application class
  //
  openDialog (id) {
    if (this.gui !== null) {
      this.gui.$bvModal.show(id);
    }
  }
  
  setModel (model) {
    this.logger.log(`Application.setModel(${model})`);
    this.model = model;
  }

  addView (view) {
    this.logger.log(`Application.setView(${view.name})`);
    this.views.push(view);
  }

  findViewByName(name) {
    return this.views.find(v => { return v.name === name; });
  }

  findView (canvas) {
    return this.views.filter(v => { return v.canvas === canvas; });
  }
  
  removeView (view) {
    this.views.splice(this.views.indexOf(view), 1);
  }

  //TODO: es müsste möglich sein in vuejs direkt nach einer aktuell gemounteten Component zu suchen
  addDialog (name, component) {
    this.dialogs.set(name, component);
  }

  findDialogByName(name) {
    return this.dialogs.get(name);
  }

  removeDialog (name) {
    this.dialogs.delete(name);
  }

  startAnimation () {
    this.requestId = requestAnimationFrame((time) => { this.animate(time); });
  }

  setMounted (view) {
    this.mounted.push(view);
  }

  setUnmounted (view) {
    this.mounted.splice(this.mounted.indexOf(view), 1);
  }

  findMountedViewByName(name) {
    return this.mounted.find(v => { return v.name === name; });
  }

  stopAnimation () {
    cancelAnimationFrame(this.requestId);
    this.requestId = null;
  }

  // call a command synchroniously
  executeCommand (commandLine) {
    actionStack.processEvent(new CommandEvent(commandLine));
  }

  // call a command asynchroniously
  sendCommand (commandLine) {
    actionStack.eventEmitter.emit('command', commandLine);
  }

  animate (time) {
    if (this.model) {
      this.model.move(time);
    }
    this.mounted.forEach(view => {
      view.render(time);
    });

    requestAnimationFrame((t) => { this.animate(t); });
  }
}

export default new Application();
