import theApp from '@/frame/Application';

import visualEvents from '@/visual-events/VisualEvents'

import Action from '@/frame/Action';
import JsonPath from '@/frame/JsonPath';
import Options from '@/frame/Options';
import FltPick from '@/visual-events/actions/FltPick';
import { PickFlags } from '@/visual-events/actions/FltPick';
import Logger from '@/frame/Logger';

export default class FltEditAttributes extends Action {
    constructor(view2D) {
      super();

      this.logger = new Logger('FltEditAttributes');

      this.view2D = view2D;

      this.selection = [];

      this.dialog = null;
    }

    actionStart () {
        this.logger.log('FltEditAttributes::actionStart');

        theApp.model.selectionList.setHighlight(true);

        const pickFlags = PickFlags.IGNORE_MSL;
        const pickTypes = ['XOpSymbol']

        const filter = new FltPick(pickFlags, pickTypes);
        this.addFilter(filter);

        return true;
    }

    actionDestroy () {
        this.logger.log('FltEditAttributes::actionDestroy');
        this.selection.length=0;
        theApp.model.selectionList.clear();
    }

    actionSelection (event) {
        this.logger.log('FltEditAttributes::actionSelection');
        this.selection.length=0;
        this.selection.push(...event.objects);
        this.updateSelection();
    }

    initializeDialog() {
        this.dialog.bookingModes = this.getBookingModes();
        this.dialog.bookingMode = this.dialog.bookingModes.length > 0 ? this.dialog.bookingModes[0].id : '';

        this.updateSelection();
    }

    updateSelection()
    {
        theApp.model.selectionList.clear();
        this.selection.forEach(op => theApp.model.selectionList.add(op));
        theApp.model.changed2d = true;

        this.dialog.selectionListIDs = this.getSelectionListIDs();
        this.dialog.countSelection = this.selection.length;

        this.dialog.itemId = this.getSelectionItemId();
        this.dialog.selectionProperties = this.getSelectionProperties();

        this.dialog.autoIncrement = this.getAutoIncrement();
        this.dialog.idGenerator = !this.getAutoIncrement();
        this.dialog.idGeneratorPattern = this.getIdGenerator();
    }

    clearSelection()
    {
        if (!this.collect)
            this.selection.length=0;
        this.updateSelection();
    }

    /**
     * ensure, that $ticketing.item is attached
     * 
     * $ticketing.item is attached for the first time if and only if any attribute is set
     * or ids are generated
     * @param {*} op 
     */
    ensureTicketingAttribute(op) {
        if (!op.getAttribute('"$ticketing.item"'))
            op.setAttribute('"$ticketing.item"', { version: 1, itemId: undefined, attributes: {} }); 
    }

    changeAttribute(attname, attvalue)
    {
        this.selection.forEach(op => { 
            this.ensureTicketingAttribute(op);  
            op.setAttribute(`"$ticketing.item".attributes.${attname}`, attvalue);
        });

        this.updateSelection();
    }

    takeAttributeFrom(attname, from) {
        this.selection.forEach(op => { 
            this.ensureTicketingAttribute(op);  
            op.setAttribute(`"$ticketing.item".attributes.${attname}`, op.getAttribute(from));
        });

        this.updateSelection();
    }

    deleteAttribute(attname)
    {
        this.selection.forEach(op => op.removeAttribute(`"$ticketing.item".attributes.${attname}`));

        this.updateSelection();
    }

    createAttribute(attname)
    {
        this.selection.forEach(op => { 
            this.ensureTicketingAttribute(op);  
            op.setAttribute(`"$ticketing.item".attributes.${attname}`, '');
        });

        this.updateSelection();
    }

    getSelectionItemId () {
        if (this.selection.length === 1) {
            const op = this.selection[0];
            return op.getAttribute('"$ticketing.item".itemId');
        }

        return '';
    }

    //TODO: wirklich bei Selection oder nur bei einzelnem bookableItem?
    setItemId(itemId) {

        this.selection.forEach(op => {
            this.ensureTicketingAttribute(op);  
            op.setAttribute(`"$ticketing.item".itemId`, itemId);
        }
        );

        this.updateSelection();
    }

    generateItemIds(autoIncrement, firstId, idGenerator, idGeneratorPattern) {
        for (let i=0; i < this.selection.length; i++) {
            const op = this.selection[i];
            this.ensureTicketingAttribute(op);  

            let itemId;
            if (autoIncrement)
                itemId = `${Number.parseInt(firstId) + i}`
            else if (idGenerator) 
                itemId = this.evaluateLiteral(op, idGeneratorPattern);
            if (itemId)
                op.setAttribute('"$ticketing.item".itemId', itemId);
        }

        this.updateSelection();
    }

    evaluateLiteral(op, pattern) {

        // extract ${ }
        const regexp = new RegExp('\\$' + `{.*?}`, 'g');
        const array = [...pattern.matchAll(regexp)];

        // collect the fixed portions in pattern
        const strings = array.map((re, i, a) => {
            let p = i === 0 ? 0 : a[i-1].index + a[i-1][0].length;
            return re.input.slice(p, re.index);
        });

        const re = array[array.length-1];
        const p = re.index + re[0].length;
        strings.push(pattern.substring(p));

        // collect the attribute values 
        const values = array.map(re => {
            const att = re[0].substring(2, re[0].length-1);
            return op.getAttribute(att);
        });

        // concatenate fixed portions and values
        let s = strings[0];
        for (let i = 1; i < strings.length; i++) {
            s += values[i-1];
            s += strings[i];
        }

        return s;
    }

    changeBookingMode () {
        this.updateSelection();
    }

    /**
     * for debugging purposes: build a string with the currently selected ids
     * @returns string with selected ids
     */
    getSelectionListIDs() {
        return this.selection.reduce((ids, op) => ids = ids + (ids == '' ? '' : ' ,') + op.id, '');
    }

    getBookingModes () {
        return JsonPath.getValue(visualEvents.opts, 'ticketing.bookingModes');
    }

    getDefaultProperties (mode) {
        const properties = [];

        const bookingMode = Options.getOption(visualEvents.opts, 'ticketing.bookingModes', mode, '');

        bookingMode?.defaultProperties.forEach(property => 
            properties.push(
                { 
                    name: property.name, 
                    value: property.value, 
                    from: property.attribute,
                    used: false, 
                    equal: false
                })
        );

        return properties;
    }

    getAutoIncrement () {
        return JsonPath.getValue(visualEvents.opts, 'ticketing.autoIncrement');
    }

    getIdGenerator () {
        return JsonPath.getValue(visualEvents.opts, 'ticketing.idGenerator');
    }

    setAutoIncrement (value) {
        return JsonPath.setValue(visualEvents.opts, 'ticketing.autoIncrement', value);
    }

    setIdGenerator (value) {
        return JsonPath.setValue(visualEvents.opts, 'ticketing.idGenerator', value);
    }

    /**
     * build an array of property objects as needed in the dialog:
     * 
     * {
     *   name: string,
     *   value: any,
     *   from: string,     // if defined, take the value from another source, e.g. the precalculated seat numbers
     *   used: boolean,    // true: in at least one selected element in contrast to defaults not yet filled out
     *   defined: boolean, // true: either equal in all selected elements in the beginning, or recently typed in 
     * }
     * 
     * A delete button will be available, if and only used is true
     * If the user fills out a property, it is immediately written into the respective attribute of all selected elements.
     * Thus the property becomes used and defined.
     * 
     * @returns properties array
     */
    getSelectionProperties() {
        let globalproperties = this.getDefaultProperties(this.dialog.bookingMode);
        
        for (let i=0; i<this.selection.length; i++)
        {
            let op = this.selection[i];
            const attributes =  op.getAttribute('"$ticketing.item".attributes');
            for (let attname in attributes) {

                const value = attributes[attname];

                const prop = globalproperties.find( p => p.name == attname);
                if (prop) {
                    if (prop.used) {
                        if (prop.value !== attributes[attname]) {
                            prop.equal = false;
                            prop.value = ''; 
                        }
                    } else {
                        prop.value = value;
                        prop.used = true;
                        prop.equal = true;
                    }
                } else 
                    globalproperties.push( 
                        { 
                            name: attname, 
                            value: value, 
                            from: undefined,
                            used: true, 
                            equal: true 
                        });
            }
        }

        return globalproperties;
     }
}    
