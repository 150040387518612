import Action from '@/frame/Action';
import Logger from '@/frame/Logger';

export default class FltPointDef extends Action {

    constructor (args) {
        super();

        this.logger = new Logger('FltPointDef');

        this.rasterEnabled = false;
        this.rasterSize = 10.0;
    }

    actionStart ()
    {
        this.logger.log(`FltPointDef::Start`);
        return true;
    }

    actionDestroy ()
    {
        this.logger.log(`FltPointDef::Destroy`);
    }

    actionPoint (event) {
        this.logger.log(`FltPointDef::Point`);
        return this.adaptToRaster(event);
    }

    actionDynamic (event) {
        this.logger.log(`FltPointDef::Dynamic`);
        return this.adaptToRaster(event);
    }

    actionValue (event) {

        if (event.attribute === 'rasterEnabled') {
            this.rasterEnabled = event.value === 'true';
        }
        if (event.attribute === 'rasterSize') {
            this.rasterSize = Number(event.value);
        }
        return event;
    }

    adaptToRaster (event) {
        if (this.rasterEnabled) {
            let x = Math.round(event.p[0] / this.rasterSize) * this.rasterSize;
            let y = Math.round(event.p[1] / this.rasterSize) * this.rasterSize;
            let z = Math.round(event.p[2] / this.rasterSize) * this.rasterSize;
            event.p = [x, y, z];
        }

        return event;
    }
}