import actionStack from './ActionStack';
import Logger from './Logger';

export default class Action {
  constructor () {
    this.logger = new Logger('Action');
  }

  static stopAll () {
    actionStack.stopAll();
  }

  static start (action) {
    actionStack.replaceAction(action);
  }

  static findByClassName (name) {
    return actionStack.findByClassName(name);
  }

  static findByClass (clazz) {
    return actionStack.findByClass (clazz);
  }

  /**
     * establish the 'filter' in front of this
     * @param filter
     */
  addFilter (filter) {
    this.logger.log(`${this.constructor.name}.addFilter(${filter.constructor.name})`);
    this.removeFilter();
    actionStack.addFilter(filter);
  }

  /**
     * remove any filter established in front of this
     */
  removeFilter () {
    if (this.getFilter())
      this.logger.log(`${this.constructor.name}.removeFilter(${this.getFilter().constructor.name})`);
    actionStack.removeFilter(this);
  }

  /**
     * get the filter, which is established in front of this
     * @return
     */
  getFilter () {
    return actionStack.getFilter(this);
  }

  /**
     * callback immediately called after inserting the Action to the action stack
     *
     * This is the place to do any initialization work in the action.
     *
     * Especially, if a filter is to be established, the constructor is too early, because
     * the action is not yet inserted into the action stack at that time.
     *
     * Under some circumstances, the command arguments are sufficient define the whole
     * task of a command, which otherwise needs further user interaction. In this case,
     * the task of the command is completed in this method. Then use return value 'false'
     * to indicate, that the action is to be removed immediately (no call of actionDestroy).
     *
     * @return
     *  false => do remove this action from stack
     *  true => proceed with user interaction
     */
  actionStart () {
    // override in special action
    return true;
  }

  /**
     * called, if the action is about to be removed from the action stack and destroyed
     *
     * overwrite in order to do any resource cleanup
     * 
     * Do not call removeFilter in actionDestroy!
     * In this state all filter in front of the action have already been removed.
     */
  actionDestroy () {
    // override in special action
  }

  /**
     * The intent of the break event is to actively finish the action or a sub
     * state of it, generally by typing the ESC key or pressing the right mouse
     * button.
     *
     * return 'event', if you would like to have it propagated farther into
     * the action stack and lead either to the finishing of one of the
     * parent actions (There are responsible for removing the filters, which
     * they have established) or reach the default action, which will destroy
     * this.
     * return null, in order to avoid, that the parent actions get the event.
     * This is appropriate, if ESC is only the end of a sub state.
     *
     * @param event  Event.BreakEvent
     * @return event or a replacing event or null
     */
  actionBreak (event) {
    // override in special action
    return event;
  }

  /**
     *
     * @param event Event.CommandEvent
     * @return event or a replacing event or null
     */
  actionCommand (event) {
    // override in special action
    return event;
  }

  /**
     *
     * @param event Event.ValueEvent
     * @return event or a replacing event or null
     */
  actionValue (event) {
    // override in special action
    return event;
  }

  /**
     *
     * @param event Event.DynamicEvent
     * @return event or a replacing event or null
     */
  actionDynamic (event) {
    // override in special action
    return event;
  }

  /**
     *
     * @param event Event.PointEvent
     * @return
     */
  actionPoint (event) {
    // override in special action
    return event;
  }

  /**
     *
     * @param event Event.PointEventUp
     * @return
     */
   actionPointUp (event) {
    // override in special action
    return event;
  }

  /**
     *
     * @param event Event.SelectionEvent
     * @return event or a replacing event or null
     */
  actionSelection (event) {
    // override in special action
    return event;
  }

  /**
     *
     * @param event Event.RawMouseEvent
     * @return event or a replacing event or null
     */
  actionMouse (event) {
    // override in special action
    return event;
  }

  /**
     *
     * @param event Event.RawKeyEvent
     * @return event or a replacing event or null
     */
  actionKey (event) {
    // override in special action
    return event;
  }
}
