import theApp from '@/frame/Application';

import visualEvents from '@/visual-events/VisualEvents'

import Action from '@/frame/Action';
import Options from '@/frame/Options';
import Pick from '../view/Pick';
import GridProvider from './GridProvider';
import PlaceSymbolsList from './PlaceSymbolsList';
import settings from '../data/Settings';
import { RenumberTool } from './RenumberTool';
import FltPointDef from '@/visual-events/actions/FltPointDef';
import Geometry from '@/visual-events/model/Geometry'
import BlockUtils from './BlockUtils';
import FltPick from '@/visual-events/actions/FltPick';
import { PickFlags } from '@/visual-events/actions/FltPick';
import OpFactory from '@/visual-events/model/OpFactory'
import Logger from '@/frame/Logger';

const State = Object.freeze({
  SELECT: 0,
  DRAG_IN_PANEL: 1,
  DRAG_IN_2D: 2,
  DRAG_ELSE: 3,
  DELETE: 4,
  EDIT_ATTRIBUTES: 5
});
  
export default class ActPlaceBlock extends Action {
    constructor(args) {
      super();

      this.logger = new Logger('ActPlaceBlock');

      this.args = args;
      this.state = State.SELECT;

      this.viewPanel = theApp.findViewByName('Symbole');
      this.rootPanel = this.viewPanel.getRoot();

      this.view2D = theApp.findViewByName('2D Ansicht');
      const drawing = this.view2D.getRoot();
      //TODO: Benachrichtungsmechanismus nach dem Laden nutzen, um root2D-Bestimmung und scale nachzuholen
      this.root2D = drawing.children[0];
      this.scale = Geometry.getScaleX(this.root2D.transform);

      this.grid = new GridProvider();
      
      this.grid.cntX = settings.grid.cntX;
      this.grid.cntY = settings.grid.cntY;
      this.grid.widthX = 0.0;
      this.grid.widthY = 0.0;
      this.grid.distX = settings.grid.distX;
      this.grid.distY = settings.grid.distY;

      this.placeSymbolsList = new PlaceSymbolsList(this.root2D);

      this.renumberTool = new RenumberTool();
      this.renumberTool.modeInRow = settings.numeration.modeInRow;
      this.renumberTool.modeAlternate = settings.numeration.modeAlternate;
      this.renumberTool.modeRows = settings.numeration.modeRows;
      this.renumberTool.attributeColumn = Options.getOption (
        visualEvents.opts, 
        'ticketing.computedProperties', 
        'grid_number_seat', 
        'attribute',
        this.renumberTool.attributeColumn);

      this.grafic = OpFactory.createGroup('temporary'); // temporary grafic

      this.backup = null; // selected block backup for 

      this.dialog = null;

      this.selection = [];
    }

    actionStart () {
        this.logger.log('ActPlaceBlock::Start');

        if (this.args.length > 1 && this.args[1] === 'attributes')
            this.SetAttributeState(true);
        else
        {
            const filter = new FltPointDef();
            this.addFilter(filter);
            this.state = State.SELECT;
        }

        this.view2D.addCameraControls();
        this.view2D.fitToCanvas();

        this.dialog = theApp.findDialogByName('SidePanel');
  
        return true;
    }

    actionDestroy () {
        this.logger.log('ActPlaceBlock::Destroy');
        switch (this.state) {
            case State.SELECT:
            case State.DELETE:
                break;
            case State.DRAG_IN_2D:
                this.finishGrid();
                this.hideTemporaryGrafic();
                this.view2D.enableCameraControls();
                break;
                case State.DRAG_IN_PANEL:
            case State.DRAG_ELSE:
                this.clearGrid();
                break;
            case State.EDIT_ATTRIBUTES:
                this.selection.length=0;
                theApp.model.selectionList.clear();
                break;
        }

        this.view2D.removeCameraControls();
    }

    actionBreak () {
        this.logger.log('ActPlaceBlock::Break');
        switch (this.state) {
            case State.SELECT:
            case State.DELETE:
                break;
            case State.DRAG_IN_2D:
            case State.DRAG_IN_PANEL:
            case State.DRAG_ELSE: {
                this.clearGrid();
                this.state = State.SELECT;
                return null;
            }
            case State.EDIT_ATTRIBUTES: {
                this.selection.length=0;
                break;
            }
        }

        this.handleCameraControlsEnableState();

        return true;
    }

    actionPoint (event) {
        this.logger.log(`ActPlaceBlock::Point    ${this.state}`);

        switch (this.state) {
            case State.SELECT:
                if (event.view === this.viewPanel) {
                    const hits = Pick.pick(event.view, event.raw);
                    const op = hits[0];
                    this.logger.log(op);
                    if (op) {
                        // disconnect the current block from the GridProvider
                        this.finishGrid();

                        // build a grid with the current settings and start drag&drop
                        this.createGrid(op);
                        this.state = State.DRAG_IN_PANEL;
                    }
                }
                if (event.view === this.view2D) {
                    const hits = Pick.pick(event.view, event.raw);
                    let op = hits[0];
                    this.logger.log(op);
                    if (op) {
                        if (this. placeSymbolsList.contains(op)) {
                            this.logger.log(`${op} in active block`);

                            this.adaptTemporaryGrafic();
                            this.showTemporaryGrafic();
                        } else {
                            this.logger.log(`${op} not in active block`);

                            const block = BlockUtils.findBlockGroup(op);
                            if (block) {
                                // disconnect the current block from the GridProvider
                                this.finishGrid();

                                // prepare the grid for 'block's parameters and start drag&drop
                                this.editGrid(block);

                                this.adaptTemporaryGrafic();
                                this.showTemporaryGrafic();
                            }
                        }
                        this.state = State.DRAG_IN_2D;
                        this.view2D.disableCameraControls();
                    }
                }
                break;
            case State.DRAG_IN_2D:
            case State.DRAG_IN_PANEL:
            case State.DRAG_ELSE:
                // never happens
                break;
            case State.DELETE: {
                    if (event.view === this.view2D) {
                        const hits = Pick.pick(event.view, event.raw);
                        const op = hits[0];
                        this.logger.log(op);
                        if (op) {
                            op.removeFromParent();
                        }
                        theApp.model.changed2d = true; //op;
                    }
                    break;
                }
            case State.EDIT_ATTRIBUTES: 
                {
                    if (event.view === this.view2D) 
                    {
                        const hits = Pick.pick(event.view, event.raw);
                        let op = hits[0];
                        this.logger.log(op);
                        if (op) 
                        {
                            let pos = this.selection.indexOf(op);
                            if (pos>=0)
                                this.selection.splice(pos,1);
                            else
                                this.selection.push(op);
                            const dialog = theApp.findDialogByName('SidePanel');
                            if (dialog)
                                dialog.selection = this.selection;
                        }
                    }                
                    break;
                }
            }

        this.handleCameraControlsEnableState();

        return null;
    }

    actionSelection (event)
    {
        if (this.state===State.EDIT_ATTRIBUTES)
        {
            event.objects.forEach(op => {
                this.logger.log(op);
                if (op) 
                {
                    let pos = this.selection.indexOf(op);
                    if (pos>=0)
                        this.selection.splice(pos,1);
                    else
                        this.selection.push(op);
                }
            });
            this.updateSelection();
        }                
    }

    actionDynamic (event) {
        //this.logger.log('ActPlaceBlock::Dynamic');

        switch (this.state) {
            case State.SELECT:
            case State.DRAG_IN_PANEL:
            case State.DRAG_ELSE:
                break;
            case State.DRAG_IN_2D: {
                // apply drawing scale
                let x = event.p[0] / this.scale;
                let y = event.p[1] / this.scale;

                this.grid.origin.x = x;
                this.grid.origin.y = y;
                this.placeSymbolsList.adaptSymbolPositions(this.grid);
                this.adaptTemporaryGrafic();
                theApp.model.changed2d = true; //op;
                break;
            }
            case State.DELETE: 
            case State.EDIT_ATTRIBUTES: 
                break; //nothing to do
        }

        this.handleCameraControlsEnableState();

        return null;
    }

    actionPointUp (event) {
        this.logger.log(`ActPlaceBlock::PointUp`);
        
        switch (this.state) {
            case State.SELECT:
                break;
            case State.DRAG_IN_2D: {
                    this.state = State.SELECT;
                }
                break;
            case State.DRAG_IN_PANEL: {
                    this.placeSymbolsList.resetSymbolList();
                    this.state = State.SELECT;
                }
                break;
            case State.DRAG_ELSE: {
                    this.clearGrid();
                    this.state = State.SELECT;
                }
                break;
            case State.DELETE: 
                break; //nothing to do
        }

        this.handleCameraControlsEnableState();

        return null;
    }

    actionMouse (event) {
        this.logger.log(`ActPlaceBlock::Mouse ${event.raw.type} ${this.state}`);
        
        switch (this.state) {
            case State.SELECT:
                break;
            case State.DRAG_IN_2D: {
                    if (event.raw.type === 'mouseleave') {
                        this.hideTemporaryGrafic();
                        this.removeFromModel();
                        this.state = State.DRAG_ELSE;
                    } 
                }
                break;
            case State.DRAG_IN_PANEL: {
                    if (event.raw.type === 'mouseleave') {
                        this.state = State.DRAG_ELSE;
                    }
                }
                break;
            case State.DRAG_ELSE: {
                if (event.raw.type === 'mouseenter') {
                    const [view] = theApp.findView(event.raw.currentTarget);
                    if (view === this.view2D) {
                        this.addToModel();
                        this.showTemporaryGrafic();
                        this.adaptTemporaryGrafic();
                        this.state = State.DRAG_IN_2D;
                    } else  if (view === this.viewPanel) {
                        this.state = State.DRAG_IN_PANEL;
                    } 
                }
                break;
            }
            case State.DELETE: 
            case State.EDIT_ATTRIBUTES: 
                break; //nothing to do
        }

        this.handleCameraControlsEnableState();

        return null;
    }

    actionValue (event) {
        //this.logger.log('ActPlaceBlock::Value');
        if (event.attribute === 'mode') {
            if (event.value === 'DELETE') {
                this.state = State.DELETE;
            } else {
                this.grid.mode = event.value;
                this.updateGrid();
                this.state = State.SELECT;
            }
        }

        if (event.attribute === 'cntX') {
            this.grid.cntX = event.value * 1;
            this.updateGrid();
        }

        if (event.attribute === 'cntY') {
            this.grid.cntY = event.value * 1;
            this.updateGrid();
        }
        
        if (event.attribute === 'distX') {
            this.grid.distX = event.value * 1;
            this.updateGrid();
        }

        if (event.attribute === 'distY') {
            this.grid.distY = event.value * 1;
            this.updateGrid();
        }

        if (event.attribute === 'startNumber') {
            this.renumberTool.startNumber = parseInt(event.value);
            this.updateGrid();
        }

        if (event.attribute === 'modeInRow') {
            this.renumberTool.modeInRow = event.value;
            this.updateGrid();
        }

        if (event.attribute === 'modeAlternate') {
            this.renumberTool.modeAlternate = event.value;
            this.updateGrid();
        }

        if (event.attribute === 'modeRows') {
            this.renumberTool.modeRows = event.value;
            this.updateGrid();
        }
        
        if (event.attribute === 'angleX') {
            this.grid.setAngleX(event.value * 1);
            this.updateGrid();
        }

        if (event.attribute === 'angleY') {
            //TODO: Value events mit value nicht Texte sondern im 'richtigen' Typ, z.B. hier Integer, bei alternate boolean true
            this.grid.setAngleY(event.value * 1);
            this.updateGrid();
        }

        if (event.attribute === 'alternate') {
            this.grid.alternate = event.value === 'true';
            this.updateGrid();
        }

        if (event.attribute === 'keepRatioXY') {
            this.grid.keepRatioXY = event.value === 'true';
            this.updateGrid();
        }

        if (this.state === State.EDIT_ATTRIBUTES)
        {
            // TODO: Edit/Create/Delete Attributes
        }

        this.adaptTemporaryGrafic();
    }

    /**
     * delete the current block
     */
     clearGrid() {
        this.placeSymbolsList.resetSymbolList();
        theApp.model.changed2d = true;
    }

    /**
     * finish the current block 
     */
    finishGrid() {
        this.placeSymbolsList.makeRowGroups(this.grid);
        this.placeSymbolsList.resetSymbolList();
    }

    createGrid(op) {
        this.placeSymbolsList.prototype = op;
        const box = op.computeBox();
        this.grid.widthX = box.max.x - box.min.x;
        this.grid.widthY = box.max.y - box.min.y;

        this.placeSymbolsList.adaptSymbolList(this.grid.getCntX() * this.grid.getCntY());
        this.placeSymbolsList.adaptSymbolPositions(this.grid);
        this.placeSymbolsList.adaptSymbolNumbering(this.grid, this.renumberTool);
    }

    editGrid(block) {

        const op = BlockUtils.analyseBlockGroup(block, this.grid);

        block.removeFromParent();
        this.backup = block;
        this.addToModel();

        this.placeSymbolsList.prototype = op.copy(); 

        this.placeSymbolsList.adaptSymbolList(this.grid.getCntX() * this.grid.getCntY());
        this.placeSymbolsList.adaptSymbolPositions(this.grid);
        this.placeSymbolsList.adaptSymbolNumbering(this.grid, this.renumberTool);

        theApp.model.changed2d = true; //op;

        this.dialog?.update();
    }

    updateGrid() {
        this.placeSymbolsList.adaptSymbolList(this.grid.getCntX() * this.grid.getCntY())
        this.placeSymbolsList.adaptSymbolPositions(this.grid);
        this.placeSymbolsList.adaptSymbolNumbering(this.grid, this.renumberTool);
        theApp.model.changed2d = true; //op;
    }

    addToModel() {
        this.placeSymbolsList.addToModel();
    }

    removeFromModel() {
        this.placeSymbolsList.removeFromModel();
    }

    SetAttributeState(e)
    {
        if (e)
        {
            if (this.state !== State.EDIT_ATTRIBUTES)
            {
                this.finishGrid();
                this.clearGrid();
                
                theApp.model.selectionList.setHighlight(true);

                this.removeFilter();
                const filter = new FltPick(PickFlags.IGNORE_MSL | PickFlags.NO_COLLECT);
                this.addFilter(filter);
       
                this.state = State.EDIT_ATTRIBUTES;
            }
        }
        else
        {
            if (this.state === State.EDIT_ATTRIBUTES)
            {
                this.selection.length=0;
                theApp.model.selectionList.clear();

                this.removeFilter();
                const filter = new FltPointDef();
                this.addFilter(filter);

                this.state = State.SELECT;
            }
        }
    }

    updateSelection()
    {
        const dialog = theApp.findDialogByName('SidePanel');
        if (dialog)
            dialog.selection = this.selection;

        theApp.model.selectionList.clear();
        this.selection.forEach(op => theApp.model.selectionList.add(op));
        theApp.model.changed2d = true;
    }

    clearSelection()
    {
        this.selection.length=0;
        this.updateSelection();
    }

    changeAttribute(attname, attvalue)
    {
        this.selection.forEach(op => op.setAttribute(attname, attvalue));

        this.updateSelection();
    }

    deleteAttribute(attname)
    {
        this.selection.forEach(op => op.removeAttribute(attname));

        this.updateSelection();
    }

    createAttribute(attname)
    {
        this.selection.forEach(op => op.setAttribute(attname, ''));

        this.updateSelection();
    }

    /**
     * during drag state disable the CameraControls
     */
    handleCameraControlsEnableState() {
        switch (this.state) {
            case State.DELETE:
            case State.EDIT_ATTRIBUTES:
            case State.DRAG_IN_2D: {
                this.view2D.disableCameraControls();
                break;
            }
            default: {
                this.view2D.enableCameraControls();
                break;
            }
        }
    }

    showTemporaryGrafic () {
        this.root2D.add(this.grafic);
        theApp.model.changed2d = true; //op;
    }

    hideTemporaryGrafic () {
        this.root2D.remove(this.grafic);
        theApp.model.changed2d = true; //op;
    }

    adaptTemporaryGrafic () {

        // margins relative to offsetX, offset Y
        let left = 0;
        let right = 0;
        let bottom = 0;
        let top = 0;
        if (this.placeSymbolsList.prototype) {
            const offsetX = this.grid.distX + this.grid.widthX;
            const offsetY = this.grid.distY + this.grid.widthY;

            const box = this.placeSymbolsList.prototype.computeBox();
            left = offsetX > 0 ? box.min.x / offsetX : 0;
            right = offsetX > 0 ? box.max.x / offsetX : 0;
            bottom = offsetY > 0 ? box.min.y / offsetY : 0;
            top = offsetY > 0 ? box.max.y / offsetY : 0;
        }

        const minX = left - 0.2;
        const maxX = this.grid.getCntX() - 1 + right + 0.2;
        const minY = bottom - 0.2;
        const maxY = this.grid.getCntY() - 1 + top + 0.2;
        const p0 = this.grid.getCoord( minX, minY);
        const p1 = this.grid.getCoord( maxX, minY);
        const p2 = this.grid.getCoord( maxX, maxY);
        const p3 = this.grid.getCoord( minX, maxY);
        
        const points = [ [p0[0], p0[1]], [p1[0], p1[1]], [p2[0], p2[1]], [p3[0], p3[1]] ];
        const face = OpFactory.createPolygon(points);
        face.style.fillOpacity = 0.2;

        this.grafic.children[0]?.removeFromParent();
        this.grafic.add(face);

        theApp.model.changed2d = true; //op;
    }
}    
