<template>
    <div class="side-menu">
        <div class="menu-content">
            <component :is="currentTab" :model="model" :symbols="symbols" :panels="panels"></component>
        </div>
        <div class="button-panel">
            <button
                v-for="(value, key) in tabs"
                :key="key"
                :class="['tab-button', { active: currentTab === key }]"
                @click=onSelectTab(key)
                :name="value.name"
            >
                <i :class="value.icon"></i>
            </button>
        </div>
    </div>
</template>

<script>
import theApp from '@/frame/Application';

import EditObjectPanel from '@/vuejs/components/EditObjectPanel';
import SymbolLibraryPanel from '@/vuejs/components/SymbolLibraryPanel';
import AttributesPanel from '@/vuejs/components/AttributesPanel';
import SettingsPanel from '@/vuejs/components/SettingsPanel';

export default {
    name: 'side-menu',
    props: {
        model: String,
        symbols: String,
        panels: String
    },
    components: {
        EditObjectPanel,
        SymbolLibraryPanel,
        AttributesPanel,
        SettingsPanel
    },
    data () {
        return {
            currentTab: 'SymbolLibraryPanel',
            tabs: { 
                    'SymbolLibraryPanel': { name: 'Symbole', icon: 'fa-solid fa-chair' }, 
                    'EditObjectPanel': { name: 'Bearbeiten', icon: 'fa-solid fa-pen'}, 
                    'AttributesPanel': { name: 'Eigenschaften', icon: 'fa-solid fa-table-list'}, 
                    'SettingsPanel': { name: 'Einstellungen', icon: 'fa-solid fa-gear'}, 
            }
        }
    },
    methods: {
        onSelectTab(key) {
            this.currentTab = key;
            switch(key) {
                case 'SymbolLibraryPanel':  theApp.executeCommand('.ActTicketing.placeBlocks');     break;
                case 'EditObjectPanel':     theApp.executeCommand('.ActTicketing.placeBlocks');     break;
                case 'AttributesPanel':     theApp.executeCommand('.ActTicketing.editAttributes');  break;
                case 'SettingsPanel':       theApp.executeCommand('.ActTicketing.placeBlocks');     break;
            }
            
        }   
    }
}
</script>

<style scoped>
.side-menu {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: row;
}
.button-panel {
    height: calc(100% - 5px);
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    margin-right: 5px;
    margin-left: 5px;
    margin-top: 5px;
    /*box-shadow: 1px 1px 15px 0px rgba(0,0,0,0.35)*/
}
.menu-content {
    height: 100%;
    width: 100%;
}
.tab-button {
    width: 48px;
    height: 48px;
    padding: 12px;
    border: none;
    text-align: center;
    background-color: grey;
    border-radius: 4px;
}
.tab-button.active {
    background-color: lightblue;
}

</style>