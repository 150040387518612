import * as THREE from 'three';
import * as dat from 'dat.gui';

import actionStack from './ActionStack';
import theApp from './Application';
import Logger from './Logger';

export default class View {
  constructor (name, model) {
    this.logger = new Logger('View');
    this.name = name;
    this.model = model;
    this.canvas = null;
    this.camera = null;
    this.initialized = false;
  }

  /**
   * override this in the derived class to do any specific initialization work
   * - create the camera
   * - establish a renderer
   *
   * init() is called when it is mounted for the first time
   *
   * return true, if successfully initialized, else false
   */
  init () {
    this.logger.log('override View.init()');
    return false;
  }

  /**
   * override this in the derived class to render the frame at a point of time
   *
   * This might involve
   * - changing the camera, e.g. if the camera is moving by itself
   * - consider model changes, e.g. load a scene graph for the first time or update
   *   the scene graph
   * - doing animation effects directly in the grafic, e.g. blinking or waving elements
   *
   * render(time) is called usually by an animator request
   * @param {} time point of time in ms
   */
  render (time) {
    this.logger.log('override View.render(time');
    // if you need the elapsed time since the last frame instead of the absolute point of time
    // define this.last and calculate:
    // const elapsed = time - this.last;
    // this.last = time;
  }

  /**
   * when a view is mounted on a page
   * it receives the dom element, where the output is displayed, usually a <div>, in argument 'viewport'
   *
   * Only with the knowledge about the canvas size it is possible to define the camera frustrum
   * correctly. Usually a renderer needs the information, too.
   *
   * Finally
   * - the callback, which reacts on resize
   * - and the listeners on mouse, keyboard etc events are established
   * - the application needs to keep the view in a list in order to dispatch the events and render calls
   */
  mount (viewport) {
    this.logger.log('View::mount');
    this.canvas = viewport;

    if (!this.initialized) {
      this.initialized = this.init();
    }

    if (this.canvas) {
      // in case of a SVG renderer the outer <div> element can be looked upon as the canvas
      // this.renderer.domElement will become a <svg> element
      // (for GL renderer the constructor makes a <canvas> = this.renderer.domElement)
      this.canvas.appendChild(this.renderer.domElement);

      this.onWindowResize();

      window.addEventListener('resize', () => { return this.onWindowResize(); }, false);
      actionStack.addUIListeners(this.canvas);
      theApp.setMounted(this);
    }
  }

  /**
   * when a view is unmounted from a page
   * the calls must be removed and the view is taken from the list of managed views in the application
   */
  unmount () {
    this.logger.log('View::unmount');
    if (this.canvas) {
      window.removeEventListener('resize', () => { return this.onWindowResize(); }, false);
      actionStack.removeUIListeners(this.canvas);
      theApp.setUnmounted(this);
    }
  }

  // TODO: setze Camera interface voraus! vielleicht setAspect
  onWindowResize () {
    this.logger.log('onWindowResize');
    if (this.renderer != null) {
      var { width, height } = this.calcSize();

      if (this.camera.aspect) { // perspectiveCamera
        this.camera.aspect = width / height;
      } else {
        // this.logger.log(`[${this.camera.left},${this.camera.right}]x[${this.camera.top},${this.camera.bottom}]`);
        this.camera.left = -width / 2;
        this.camera.right = width / 2;
        this.camera.top = height / 2;
        this.camera.bottom = -height / 2;
      }
      this.camera.updateProjectionMatrix();

      this.renderer.setSize(width, height);
    }
  }

  calcSize () {
    var positionInfo = this.canvas.getBoundingClientRect();
    var width = positionInfo.width;
    var height = positionInfo.height;
    return { width, height };
  }

  // TODO: make Camera interface to avoid THREE import
  /**
   * helper function to calculate world coordinates for a given client position
   * with respect to the canvas
   *
   * E.g. for picking world elements, the mouse provides event client coordinates,
   * and we can use the world coordinates to search for the picked element.
   * @param {*} x
   * @param {*} y
   * @param {*} z
   */
  unproject (raw) {
    let [x, y, z] = [0, 0, 0];

    if (this.camera) {
      const rect = this.canvas.getBoundingClientRect();
      const u = (raw.clientX - rect.left) / rect.width * 2 - 1;
      const v = (raw.clientY - rect.top) / rect.height * -2 + 1; // flip y

      const t = this.camera.matrix.clone();
      t.multiply(this.camera.projectionMatrixInverse);

      const p = new THREE.Vector4(u, v, 0, 1);
      p.applyMatrix4(t);
      [x, y, z] = [p.x, p.y, p.z];

      this.setMouseEventDiagnostics(raw, u, v, x, y, z, t.elements);
    }

    return [x, y, z];
  }

  /**
   * calculate relative coordinates in [-1,1]x[-1,1] as used in the raycaster
   * @param {*} raw 
   * @returns 
   */
  getRelative (raw) {
    const rect = this.canvas.getBoundingClientRect();
    const u = (raw.clientX - rect.left) / rect.width * 2 - 1;
    const v = (raw.clientY - rect.top) / rect.height * -2 + 1; // flip y
    return { x: u, y: v};
  }

  addDiagnostics () {
    // diagnostic
    this.diagnostics = {
      clientX: 0,
      clientY: 0,
      // offsetX: 0,
      // offsetY: 0,
      screenX: 0,
      screenY: 0,
      u: 0.01,
      v: 0.01,
      x: 0.1,
      y: 0.1,
      z: 0.1,
      a11: 0.001,
      a12: 0.001,
      a13: 0.001,
      a14: 0.001,
      a21: 0.001,
      a22: 0.001,
      a23: 0.001,
      a24: 0.001,
      a31: 0.001,
      a32: 0.001,
      a33: 0.001,
      a34: 0.001,
      a41: 0.001,
      a42: 0.001,
      a43: 0.001,
      a44: 0.001
    };

    this.gui = new dat.GUI({ autoPlace: true });
    const f1 = this.gui.addFolder('mouse event');
    f1.add(this.diagnostics, 'screenX').listen();
    f1.add(this.diagnostics, 'screenY').listen();
    f1.add(this.diagnostics, 'clientX').listen();
    f1.add(this.diagnostics, 'clientY').listen();
    // f1.add(this.diagnostics, 'offsetX').listen();
    // f1.add(this.diagnostics, 'offsetY').listen();
    f1.add(this.diagnostics, 'u').listen();
    f1.add(this.diagnostics, 'v').listen();
    f1.add(this.diagnostics, 'x').listen();
    f1.add(this.diagnostics, 'y').listen();
    f1.add(this.diagnostics, 'z').listen();

    const f2 = this.gui.addFolder('camera');
    f2.add(this.camera.position, 'x').listen();
    f2.add(this.camera.position, 'y').listen();
    f2.add(this.camera.position, 'z').listen();
    f2.add(this.camera, 'zoom').listen();

    const f3 = this.gui.addFolder('projection');
    f3.add(this.diagnostics, 'a11').listen();
    f3.add(this.diagnostics, 'a12').listen();
    f3.add(this.diagnostics, 'a13').listen();
    f3.add(this.diagnostics, 'a14').listen();

    f3.add(this.diagnostics, 'a21').listen();
    f3.add(this.diagnostics, 'a22').listen();
    f3.add(this.diagnostics, 'a23').listen();
    f3.add(this.diagnostics, 'a24').listen();

    f3.add(this.diagnostics, 'a31').listen();
    f3.add(this.diagnostics, 'a32').listen();
    f3.add(this.diagnostics, 'a33').listen();
    f3.add(this.diagnostics, 'a34').listen();

    f3.add(this.diagnostics, 'a41').listen();
    f3.add(this.diagnostics, 'a42').listen();
    f3.add(this.diagnostics, 'a43').listen();
    f3.add(this.diagnostics, 'a44').listen();
  }

  setMouseEventDiagnostics (raw, u, v, x, y, z, m) {

    if (!this.diagnostics)
      return;

    this.diagnostics.screenX = raw.screenX;
    this.diagnostics.screenY = raw.screenY;
    this.diagnostics.clientX = raw.clientX;
    this.diagnostics.clientY = raw.clientY;
    // offset is experimental, problems with FireFox
    // this.diagnostics.offsetX = raw.offsetX;
    // this.diagnostics.offsetY = raw.offsetY;

    this.diagnostics.u = u;
    this.diagnostics.v = v;
    this.diagnostics.x = x;
    this.diagnostics.y = y;
    this.diagnostics.z = z;

    this.diagnostics.a11 = m[0];
    this.diagnostics.a12 = m[1];
    this.diagnostics.a13 = m[2];
    this.diagnostics.a14 = m[3];

    this.diagnostics.a21 = m[4];
    this.diagnostics.a22 = m[5];
    this.diagnostics.a23 = m[6];
    this.diagnostics.a24 = m[7];

    this.diagnostics.a31 = m[8];
    this.diagnostics.a32 = m[9];
    this.diagnostics.a33 = m[10];
    this.diagnostics.a34 = m[11];

    this.diagnostics.a41 = m[12];
    this.diagnostics.a42 = m[13];
    this.diagnostics.a43 = m[14];
    this.diagnostics.a44 = m[15];
  }
}
