<template>
    <div class="edit-object-panel">
        <div class="d-flex flex-column border" style="height: 100%; width: 100%; overflow: hidden;">
            <div class="h-100">
                <div style="padding-left: 25px; padding-top: 10px; padding-bottom: 10px; font-size: 20px; background-color: lightblue;">
                    Sitzplätze
                </div>
                <div class="col-sm-12">
                    <ul class="nav nav-tabs nav-fill" id="sidepanel-tab" role="tablist">
                        <li class="nav-item" role="presentation">
                            <button class="nav-link active" id="placement-tab" data-bs-toggle="tab" data-bs-target="#placement" type="button" role="tab" aria-controls="placement" aria-selected="true">Allgemein</button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" id="numeration-tab" data-bs-toggle="tab" data-bs-target="#numeration" type="button" role="tab" aria-controls="numeration" aria-selected="false">Nummerierung</button>
                        </li>
                    </ul>
                    <div class="tab-content p-2" id="sidepanel-tab-content">
                        <div class="tab-pane fade show active" id="placement" role="tabpanel" aria-labelledby="placement-tab">
                            <div class="row">
                                <div class="col-sm-3 mb-3">
                                    <div>Form</div>
                                </div>
                                <div class="col-sm-9 mb-3">
                                    <div class="d-flex gap-1 justify-content-start">
                                        <input type="radio" class="btn-check" name="options-placement" id="placement-single" autocomplete="off" v-model="mode" value="SINGLE" @change="onChangePlacingMode">
                                        <label class="btn btn-outline-primary" for="placement-single" title="Einzel"><i class="fa-solid fa-square"></i></label>

                                        <input type="radio" class="btn-check" name="options-placement" id="placement-rect" autocomplete="off" v-model="mode" value="RECT" @change="onChangePlacingMode">
                                        <label class="btn btn-outline-primary" for="placement-rect" title="Rechteck"><i class="fa-solid fa-bars"></i></label>

                                        <input type="radio" class="btn-check" name="options-placement" id="placement-slanted" autocomplete="off" v-model="mode" value="SLANTED" @change="onChangePlacingMode">
                                        <label class="btn btn-outline-primary" for="placement-slanted" title="Schräg"><i class="fa-solid fa-signal" style="transform: rotate(-90deg);"></i></label>

                                        <input type="radio" class="btn-check" name="options-placement" id="placement-arena" autocomplete="off" v-model="mode" value="ARENA" @change="onChangePlacingMode">
                                        <label class="btn btn-outline-primary" for="placement-arena" title="Arena"><i class="fa-solid fa-cubes-stacked" style="transform: rotate(180deg);"></i></label>

                                        <input type="radio" class="btn-check" name="options-placement" id="placement-round" autocomplete="off" v-model="mode" value="ROUND" @change="onChangePlacingMode">
                                        <label class="btn btn-outline-primary" for="placement-round" title="Rund"><i class="fa-solid fa-wifi"></i></label>

                                        <input type="radio" class="btn-check" name="options-placement" id="placement-circle" autocomplete="off" v-model="mode" value="CIRCLE" @change="onChangePlacingMode">
                                        <label class="btn btn-outline-primary" for="placement-circle" title="Vollkreis"><i class="fa-solid fa-circle"></i></label>

                                        <input type="radio" class="btn-check" name="options-placement" id="placement-half-circle" autocomplete="off" v-model="mode" value="HALF_CIRCLE" @change="onChangePlacingMode">
                                        <label class="btn btn-outline-primary" for="placement-half-circle" title="Teilkreis"><i class="fa-solid fa-circle-half-stroke"></i></label>

                                        <input type="checkbox" class="btn-check" name="options-placement" id="placement-delete" autocomplete="off" v-model="deleteMode" @change="onToggleDeleteMode">
                                        <label class="btn btn-outline-danger" style="margin-left: auto;" for="placement-delete" title="Löschen"><i class="fa-solid fa-trash"></i></label>
                                    </div>   
                                </div>

                                <div class="col-sm-3 mb-3">
                                    <div>Ausrichtung</div>
                                </div>
                                <div class="col-sm-9 mb-3">
                                    <fieldset>
                                        <div class="d-flex gap-1 justify-content-start">
                                            <input type="radio" class="btn-check" name="options-offset" id="offset-none" autocomplete="off" value = "SHIFT_NONE" v-model = "shiftMode" @click="onClickShiftMode('SHIFT_NONE')">
                                            <label class="btn btn-outline-primary" for="offset-none" title="Kein Versatz"><i class="fa-solid fa-xmark"></i></label>

                                            <input type="radio" class="btn-check" name="options-offset" id="offset-half" autocomplete="off"  value = "SHIFT_ALTERNATE" v-model = "shiftMode"  @click="onClickShiftMode('SHIFT_ALTERNATE')">
                                            <label class="btn btn-outline-primary" for="offset-half" title="Halb Versetzt"><i class="fa-solid fa-bars-staggered"></i></label>

                                            <input type="radio" class="btn-check" name="options-offset" id="offset-triangle" autocomplete="off"  value = "SHIFT_TRIANGLE" v-model = "shiftMode"  @click="onClickShiftMode('SHIFT_TRIANGLE')">
                                            <label class="btn btn-outline-primary" for="offset-triangle" title="Dreiecksanordnung"><i class="fa-solid fa-play" style="transform: rotate(-90deg)"></i></label>
                                        </div>
                                    </fieldset>
                                </div>
                                
                                <div class="col-sm-12">
                                    <div class="form-group mt-2">
                                        <label for="object-title" class="form-label">Titel</label>
                                        <input type="text" class="form-control" id="object-title">
                                    </div>
                                </div>
                                <div class="col-sm-12">
                                    <div class="form-group mt-2">
                                        <label for="object-category" class="form-label">Kategorie</label>
                                        <select class="form-control" id="object-category">
                                            <option value="" selected></option>
                                            <option value="A">Block A</option>
                                            <option value="B">Block B</option>
                                            <option value="C">Block C</option>
                                            <option value="D">Block D</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group mt-2">
                                        <label for="object-count" class="form-label">Anzahl pro Reihe</label>
                                        <input type="number" min="1" class="form-control" id="object-count" v-model="cntX" @input="onChangeCntX">
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group mt-2">
                                        <label for="object-rows" class="form-label">Reihen</label>
                                        <input type="number" min="1" class="form-control" id="object-rows" v-model="cntY" @input="onChangeCntY">
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group mt-2">
                                        <label for="object-spacing" class="form-label">Abstand Objekte</label>
                                        <input type="number" min="0" max="50" step="10" class="form-control" id="object-spacing" v-model="distXDisplay" @input="onChangeDistX">
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group mt-2">
                                        <label for="object-row-spacing" class="form-label">Abstand Reihen</label>
                                        <input type="number" min="0" max="50" step="10" class="form-control" id="object-row-spacing" v-model="distYDisplay" @input="onChangeDistY">
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group mt-2">
                                        <label for="object-angle-x" class="form-label">Winkel</label>
                                        <input type="number" min="0" max="360" class="form-control" id="object-angle-x" v-model="angleXDisplay" @input="onChangeAngleX">
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group mt-2">
                                        <label for="object-angle-shear" class="form-label">Winkel Schäge</label>
                                        <input type="number" min="-90" max="90" class="form-control" id="object-angle-shear" v-model="shearDisplay" @input="onChangeAngleShear">
                                    </div>
                                </div>
                                <div class="col-sm-12">
                                    <div class="form-check form-check-inline mt-2">
                                        <input class="form-check-input" type="checkbox" id="object-count-per-row" value="true">
                                        <label class="form-check-label" for="object-count-per-row">Anzahl pro Reihe anpassen</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="numeration" role="tabpanel" aria-labelledby="numeration-tab">
                            <div class="col-sm-12">
                                <div class="input-group mt-3">
                                    <span class="input-group-text" id="starting-number-addon">Startnummer</span>
                                    <input type="number" class="form-control" aria-label="Count" aria-describedby="starting-number-addon" v-model="startNumber" @input="onChangeStartNumber">
                                </div>
                                <hr>
                            </div>   
                            <div class="col-sm-12">
                                <fieldset>
                                    <legend>In der Reihe</legend>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="numeration-row" id="numeration-row-left" v-model="modeInRow" value="LEFT" @change="onChangeModeInRow">
                                        <label class="form-check-label" for="numeration-row-left">
                                            Von links
                                        </label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="numeration-row" id="numeration-row-right" v-model="modeInRow" value="RIGHT" @change="onChangeModeInRow">
                                        <label class="form-check-label" for="numeration-row-right">
                                            Von rechts
                                        </label>
                                    </div>
                                </fieldset>
                            </div> 
                            <div class="col-sm-12">
                                <fieldset>
                                    <legend>Alternierend</legend>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="numeration-alternating" id="numeration-alternating-none" v-model="modeAlternate" value="NORMAL" @change="onChangeModeAlternate">
                                        <label class="form-check-label" for="numeration-alternating-none">
                                            Nicht (fortlaufend)
                                        </label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="numeration-alternating" id="numeration-alternating-outside" v-model="modeAlternate" value="OUTER" @change="onChangeModeAlternate">
                                        <label class="form-check-label" for="numeration-alternating-outside">
                                            Von außen
                                        </label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="numeration-alternating" id="numeration-alternating-inside" v-model="modeAlternate" value="INNER" @change="onChangeModeAlternate">
                                        <label class="form-check-label" for="numeration-alternating-inside">
                                            Von innen
                                        </label>
                                    </div>
                                </fieldset>
                            </div> 
                            <div class="col-sm-12">
                                <fieldset>
                                    <legend>Reihen</legend>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="numeration-rows" id="numeration-rows-front" v-model="modeRows" value="FORWARD" @change="onChangeModeRows">
                                        <label class="form-check-label" for="numeration-rows-front">
                                            Von vorne
                                        </label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="numeration-rows" id="numeration-rows-back" v-model="modeRows" value="BACKWARD" @change="onChangeModeRows">
                                        <label class="form-check-label" for="numeration-rows-back">
                                            Von hinten
                                        </label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="numeration-rows" id="numeration-rows-per-row" v-model="modeRows" value="PER_ROW" @change="onChangeModeRows">
                                        <label class="form-check-label" for="numeration-rows-per-row">
                                            Pro Reihe
                                        </label>
                                    </div>
                                </fieldset>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import theApp from '@/frame/Application';
import Action from '@/frame/Action'

import settings from '@/visual-events/data/Settings';

import FltPlaceBlock from '@/visual-events/actions/FltPlaceBlock';

export default {
  name: 'EditObjectPanel',
  data () {
    return {
        deleteMode: false,

        //TODO: evtl besser direkt mit der Action verbinden? und Settings lesen und schreiben von settings in der action?
        //TODO: settings Persistenz?
        mode : settings.grid.mode,
        cntX : settings.grid.cntX,
        cntY : settings.grid.cntY,
        distX : settings.grid.distX / 100.,
        distY : settings.grid.distY / 100.,
        angleX : settings.grid.angleX,
        angleY : settings.grid.angleY,
        shear : 90.0 - (settings.grid.angleY*1 - settings.grid.angleX*1) % 180.0,

        alternate: settings.grid.alternate,
        keepRatioXY: settings.grid.keepRatioXY,

        startNumber : settings.numeration.startNumber,
        modeInRow : settings.numeration.modeInRow,
        modeAlternate : settings.numeration.modeAlternate,
        modeRows : settings.numeration.modeRows
    }
  },
  computed: {
    distXDisplay: {
        get() {
            return this.distX.toFixed(2);
        },
        set(newValue) {
            this.distX = newValue;
        },
    },
    distYDisplay: {
        get() {
            return this.distY.toFixed(2);
        },
        set(newValue) {
            this.distY = newValue;
        },
    },
    angleXDisplay: {
        get() {
            return this.angleX.toFixed(0);
        },
        set(newValue) {
            this.angleX = newValue;
        },
    },
    angleYDisplay: {
        get() {
            return this.angleY.toFixed(0);
        },
        set(newValue) {
            this.angleY = newValue;
        },
    },
    shearDisplay: {
        get() {
            return this.shear.toFixed(0);
        }
    },
    shiftMode: {
        get() {
            if (!this.alternate && !this.keepRatioXY)
                return 'SHIFT_NONE'
            else if (this.alternate)
                return 'SHIFT_ALTERNATE'  
            else if (this.keepRatioXY)
                return 'SHIFT_TRIANGLE'
            else
                return 'SHIFT_UNKNOWN'
            },
        set(newValue) {
            switch (newValue) {
                case 'SHIFT_NONE': this.alternate = false; this.keepRatioXY = false; break;
                case 'SHIFT_ALTERNATE': this.alternate = true; this.keepRatioXY = false; break;
                case 'SHIFT_TRIANGLE': this.alternate = true; this.keepRatioXY = true; break;
            }
        }
    },
  },
  mounted () {
    theApp.addDialog('EditObjectPanel', this);
    const action = Action.findByClass(FltPlaceBlock)
    if (action) 
        action.dialog = this;
  },
  unmounted () {
    theApp.removeDialog('EditObjectPanel');
    const action = Action.findByClass(FltPlaceBlock)
    if (action) 
        action.dialog = null;
  },
  methods: {
    update() {
        const action = Action.findByClass(FltPlaceBlock)
        if (!action)
            return;

        this.mode = action.grid.mode
        this.cntX = action.grid.cntX
        this.cntY = action.grid.cntY
        this.distX = action.grid.distX / 100.
        this.distY = action.grid.distY / 100.
        this.angleX = action.grid.angleX
        this.angleY = action.grid.angleY
        this.shear = 90.0 - (action.grid.angleY*1 - action.grid.angleX*1) % 180.0

        this.alternate =  action.grid.alternate,
        this.keepRatioXY = action.grid.keepRatioXY

        this.startNumber = action.renumberTool.startNumber,
        this.modeInRow = action.renumberTool.modeInRow,
        this.modeAlternate = action.renumberTool.modeAlternate,
        this.modeRows = action.renumberTool.modeRows

    },
    onToggleDeleteMode() {
        theApp.executeCommand(`.ActTicketing.setDeleteMode  ${this.deleteMode}`)
    }, 
    onChangePlacingMode() {
        // if deleting mode is active, stop it
        if (this.deleteMode) {
            this.deleteMode = false;
            theApp.executeCommand(`.ActTicketing.setDeleteMode  ${this.deleteMode}`)
        }
        theApp.executeCommand(`val.val "mode" ${this.mode}`)
    },
    onChangeCntX () {
      theApp.executeCommand(`val.val "cntX" ${this.cntX}`)
    },
    onChangeCntY () {
      theApp.executeCommand(`val.val "cntY" ${this.cntY}`)
    },
    onChangeDistX () {
        //TODO: wieso so ein Faktor, steht in Zusammenhang mit dem Range im input tag!
      theApp.executeCommand(`val.val "distX" ${this.distX*100.0}`)
    },
    onChangeDistY () {
      theApp.executeCommand(`val.val "distY" ${this.distY*100.0}`)
    },
    onChangeStartNumber() {
      theApp.executeCommand(`val.val "startNumber" ${this.startNumber}`)
    },
    onChangeModeInRow () {
      theApp.executeCommand(`val.val "modeInRow" ${this.modeInRow}`)
    },
    onChangeModeAlternate () {
      theApp.executeCommand(`val.val "modeAlternate" ${this.modeAlternate}`)
    },
    onChangeModeRows () {
      theApp.executeCommand(`val.val "modeRows" ${this.modeRows}`)
    },
    onChangeAngleX () {
      this.angleY = (this.angleX*1 + 90.0 - this.shear*1) % 360.0
      theApp.executeCommand(`val.val "angleX" ${this.angleX}`)
      theApp.executeCommand(`val.val "angleY" ${this.angleY}`)
    },
    onChangeAngleY () {
      this.shear = 90.0 - (settings.grid.angleY*1 - settings.grid.angleX*1) % 180.0
      theApp.executeCommand(`val.val "angleY" ${this.angleY}`)
    },
    onChangeAngleShear () {
      this.angleY = (this.angleX*1 + 90.0 - this.shear*1) % 360.0
      theApp.executeCommand(`val.val "angleY" ${this.angleY}`)
    }, 
    onClickShiftMode (mode) {
        let alternate = false;
        let keepRatioXY = false;
        switch (mode) {
            case 'SHIFT_ALTERNATE': 
                alternate = true;
                break;
            case 'SHIFT_TRIANGLE': 
                alternate = true;
                keepRatioXY = true;
                break;
        }
        theApp.executeCommand(`val.val "alternate" ${alternate}`)
        theApp.executeCommand(`val.val "keepRatioXY" ${keepRatioXY}`)
    }
  }
}
</script>

<style scoped>
.edit-object-panel {
    height: 100%;
}
#object-spacing::after {
    content: 'cm';
}
</style>
