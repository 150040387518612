import Model from '@/frame/Model';
import SelectionList from './SelectionList';

export default class OpModel extends Model {
    constructor () {
        super();

        this.changed2d = true;
        this.changedPanel = true;
        this.changed3d = false;

        this.drawing = [];
        this.symbols = {};
        this.space = null; //TODO: evtl. mehrere 3D space zulassen analog drawings
        this.light = {};

        this.selectionList = new SelectionList();
    }

}
  