import { Matrix4, Path, ShapePath } from 'three';
import { CADdySVGLoader } from '@/visual-events/loader/CADdySVGLoader.js';

import OpObject from '@/visual-events/model/OpObject'
import { CADdyType } from '@/visual-events/loader/CADdyType'

import OpSymbol from '@/visual-events/model/OpSymbol';
import OpReference from '@/visual-events/model/OpReference';

import OpShapePath from '@/visual-events/model/OpShapePath';
import OpMesh from '@/visual-events/model/OpMesh';

import OpText from '@/visual-events/model/OpText'
import { TextAnchor, BaseLine } from '@/visual-events/model/OpText'

export default class OpFactory {

    //-----------------------------------------------------------------------------------------------------------------
    // containers:
    //-----------------------------------------------------------------------------------------------------------------

    static createDrawing (name, width, height, viewbox) {
        const op = new OpObject(CADdyType.DRAWING, name);
        op['width'] = width;
        op['height'] = height;
        op['viewBox'] = viewbox; 
        return op;
    }

    static createSpace (name) {
        return new OpObject(CADdyType.SPACE, name);
    }

    static createSketchboard (name) {
        return new OpObject(CADdyType.SKETCHBOARD, name);
    }

    static createGroup (name) {
        return new OpObject(CADdyType.GROUP, name);
    }

   //-----------------------------------------------------------------------------------------------------------------
    // instancing
    //-----------------------------------------------------------------------------------------------------------------

    static createSymbol(name, id) {
        return new OpSymbol(name, id);
    }

    static createReference(name, id, transform) {
        return new OpReference(name, id, transform);
    }

    //-----------------------------------------------------------------------------------------------------------------
    // shape path objects incl. texts
    //-----------------------------------------------------------------------------------------------------------------

    static createLine ( x1, y1, x2, y2) {
        const op =  new OpObject(CADdyType.LINE, 'line');

        const path = new ShapePath();
        path.moveTo( x1, y1 );
        path.lineTo( x2, y2 );
        path.currentPath.autoClose = false;

        op['path'] = path;
        op['style'] = { fill: 'none', fillOpacity: 1, stroke: '#000000', strokeLineCap: 'but', strokeLineJoin: 'miter', strokeMiterLimit: 4, strokeOpactity: 1, strokeWidth: 1}

        return op;
    }

    static createPolygon ( points ) {

        if (points.length < 3)
            return null;

        const op =  new OpObject(CADdyType.FACE, 'polygon');

        const path = new ShapePath();

        path.moveTo( points[0][0], points[0][1] );
        for (let i = 1; i < points.length; i++) 
            path.lineTo( points[i][0], points[i][1] );
        path.currentPath.autoClose = true;

        op['path'] = path;
        op['style'] = { fill: '#000000', fillOpacity: 1, stroke: 'none', strokeLineCap: 'but', strokeLineJoin: 'miter', strokeMiterLimit: 4, strokeOpactity: 0, strokeWidth: 1}
        
        return op;
    } 

    static createCircle (cx, cy, r) {
        const op =  new OpObject(CADdyType.CIRCLE, 'circle');
        const subpath = new Path();
        subpath.absarc( cx, cy, r, 0, Math.PI * 2 );

        const path = new ShapePath();
        path.subPaths.push( subpath );

        op['path'] = path;
        op['style'] = { fill: '#000000', fillOpacity: 1, stroke: '#000000', strokeLineCap: 'but', strokeLineJoin: 'miter', strokeMiterLimit: 4, strokeOpactity: 0, strokeWidth: 1}
        path.userData = { node: null, style: op['style'] };

        return op;
    }

    static createFromSVG (svg) {
        // const op =  new OpObject(CADdyType.FACE, '');

        // const loader = new CADdySVGLoader();
        // const path = loader.parse (svg);

        // op['path'] = path;
        // op['style'] = { fill: '#000', fillOpacity: 0.2, stroke: '#000000', strokeLineCap: 'but', strokeLineJoin: 'miter', strokeMiterLimit: 4, strokeOpactity: 0, strokeWidth: 1}
        // path.userData = { node: null, style: op['style'] };

        // return op;
    }

    static createShapePath (type, path, style) {
        const op =  new OpShapePath(type, path, style);
        op.path = path;
        op.style = style;
        return op;
    }

    static createText (text, fontSize, fontFamily = undefined, textAnchor = TextAnchor.start, baseLine = BaseLine.baseLine) {
        const op = new OpText(text, fontSize, fontFamily, textAnchor, baseLine);

        return op;
    }

   //-----------------------------------------------------------------------------------------------------------------
   // mesh objects
   //-----------------------------------------------------------------------------------------------------------------

    static createMesh(type, name, mesh) {
        const op =  new OpMesh(type, name, mesh);

        return op;
   }

}