import theApp from '@/frame/Application';

//nur wegen XOpGroup
import OpFactory from '@/visual-events/model/OpFactory'
import { Matrix4 } from 'three';

export default class PlaceSymbolsList {

    constructor (target) {
        this.target = target;
        this.group = OpFactory.createGroup('block');
        this.prototype = null;
        this.symbols = [];
        // working matrix
        this.t = new Matrix4();
    }

    contains(op) {
        const find = this.symbols.filter(child => child === op);   
        if(find.length > 0) return true;
            return false;
    }

    adaptSymbolList(newCnt) {
        if (this.prototype === null)
            return;

        const cnt = this.symbols.length;

        if (newCnt > cnt) {
            for (let i = cnt; i < newCnt; i++) {
                const op = this.prototype.copy();
                this.symbols.push(op);
                this.group.add(op);
            }
        } else if (newCnt < cnt) {
            for (let i = cnt; i > newCnt; i--) {
                const op = this.symbols.pop();
                this.group.remove(op);
            }
        }
    }

    adaptSymbolPositions(grid) {
        for (let j = 0; j < grid.getCntY(); j++) {
            for (let i = 0; i < grid.getCntX(); i++) {
                const [x, y] = grid.getCoord(i,j);
                const op = this.symbols[j * grid.getCntX() + i];
                const [u, v] = grid.getVectorX(i,j);
                const a = Math.atan2(v,u);
                if (op) {
                    this.t.makeRotationZ(a).setPosition(x, y, 0);
                    op.setTransform(this.t);
                }
            }
        }
    }

    adaptSymbolNumbering(grid, renumberTool) {
        let arrayCntX = [];
        let cntAll = 0;

        let cntY = Math.abs(grid.getCntY());
        for (let j = 0; j < cntY; j++) {
            // TODO: maybe something for clipped symbols but we don't have that yet

            let cntX = Math.abs(grid.getCntX());
            arrayCntX.push(cntX);

            cntAll += cntX;
        }

        renumberTool.RenumberTexts(this.symbols, cntAll, grid.getCntX(), cntY, arrayCntX);
    }

    /**
     * reset the symbols list into the inial empty state
     * 
     * delete the symbols, if the group has no parent, i.e. it is not in the model op tree
     * or elsewhere
     */
    resetSymbolList() {
        if (!this.group.parent)
            this.adaptSymbolList(0);
        this.prototype = null;
        this.symbols = [];
        this.group = OpFactory.createGroup('block');
    }

    addToModel() {
        this.target.add(this.group);
        theApp.model.changed2d = true; //op;
    }

    removeFromModel() {
        this.target.remove(this.group);
        theApp.model.changed2d = true; //op;
    }

    /**
     * distribute the symbols in XOpGroups representing the rows 
     * @param {*} grid
     */
    makeRowGroups(grid) {
        if (this.prototype === null)
            return;

        let cntY = Math.abs(grid.getCntY());
        for (let j = 0; j < cntY; j++) {
            const children = [];
            const cntX = grid.getCntX();
            for (let i = 0; i < cntX; i++) {
                const op = this.symbols[j * grid.getCntX() + i];
                children.push(op);
            }
            const row = OpFactory.createGroup(`row ${j}`);
            children.forEach(op => row.add(op));
            this.group.add(row);
        }
    }

    /**
     * flatten the row groups below XOpGroup block
     * @returns an array of all symbols in XOpGroup block
     */
    dissolveRowGroups() {
        const items = [];
        this.group.children.forEach(group => { 
                if (group.type === 'XOpGroup') { 
                    this.group.remove(group);
                    group.children.forEach(op => {
                        items.push(op);
                        group.remove(op);
                    }) 
                }
            });
        items.forEach(op => this.group.add(op));  
        return items;          
    }


}