import theApp from '@/frame/Application';
import Action from '@/frame/Action';
import * as Event from '@/frame/Event';

import FltHover from '@/visual-events/actions/FltHover';

import Pick from '@/visual-events/view/Pick';
import SelectionFrame from '@/visual-events/view/SelectionFrame';
import { MouseButtonFlags } from '@/visual-events/view/SelectionFrame';
import { SelectionBox     } from 'three/examples/jsm/interactive/SelectionBox';
import Logger from '../../frame/Logger';

const State = Object.freeze({
    NONE:      0,
    SELECT:    1,
    SELECTING: 2,
    PICK:      3,
    FINISHED:  4
});

//
// MSL stands for Model Selection List
//
export const PickFlags = Object.freeze({
    IGNORE_MSL:             0x0001, // Do not modify the model selection list; IMPLIES NO_HIGHLIGHT.
    NO_CLEAR_MSL:           0x0002, // The model selection list is not cleared at the beginning of the action.
    NO_HIGHLIGHT:           0x0004, // The model selection list does not highlight its content.
    NO_DRAG:                0x0008, // Do not allow dragging operation drag, that is only picking.
    NO_COLLECT:             0x0010, // No collection of objects, e.g. MSL is emptied on entering states SELECT or PICK.
    DYNAMIC_SELECT:         0x0020, // If set, the model selection list is updated dynamically.
});
  
export default class FltPick extends Action {

    constructor(pick_flags = 0, pick_types = []) {
        super();

        this.logger = new Logger('FltPick');
        this.view   = null;  // see function _initializeSelection. Not used in case of NO_DRAG.
        this.flags  = pick_flags;
        this.types  = new Set(pick_types);
        this.state  = State.NONE;
    }
    
    setTypes (types) {
        this.types = new Set(types);
        this.types
    }

    addType (type) {
        this.types.add(type);
    }

    setFlags (flags) {
        this.flags = flags;
    }

    setFlag (flag) {
        this.flags = this.flags | flag;
    }

    unsetFlag (flag) {
        this.flags = this.flags & ~flag;
    }

    hasFlag(flag) {
        return ((this.flags & flag) === flag);
    }

    selection () {
        return this.selected;
    }

    actionStart ()
    {
        this.logger.log(`FltPick::Start   ${this.state}`);

        this.point    = [0,0];
        this.selected = [];

        let app = theApp;

        const hoverFilter = new FltHover();
        this.addFilter(hoverFilter);

        if (this.hasFlag(PickFlags.NO_DRAG))
            this.state = State.PICK;
        else
            this.state = State.SELECT;

        if (this.hasFlag(PickFlags.IGNORE_MSL) === false)
        {
            if (this.hasFlag(PickFlags.NO_HIGHLIGHT))
                theApp.model.selectionList.setHighlight(false); 
            else
                theApp.model.selectionList.setHighlight(true); 

            if (this.hasFlag(PickFlags.NO_CLEAR_MSL) === false)
                theApp.model.selectionList.clear();
        }

        return true;
    }

    actionDestroy ()
    {
        this.logger.log(`FltPick::Destroy ${this.state}`);
    }

    actionBreak (event)
    {
        //     this.logger.log(`FltPick::Break   ${this.state}`);
        // this.state = State.FINISHED;
        return event;
    }

    actionPoint (event)
    {
        this.logger.log(`FltPick::Point   ${event.raw.type} ${this.state}`);

        switch (this.state) {
            case State.SELECT:
                this._initializeEntryStates(event);
                this._startSelection(event);
                this.state = State.SELECTING;
                break;
            case State.SELECTING:
                //
                // If we get into this branch, a failure has happend. E.g. the mouse  up operation has happend
                // in another window context and the user reselected (i.e. pressed the left mouse button) in
                // the original view. To get back to correct operation we just restart the selection process.
                //
                this._initializeEntryStates(event);
                this._startSelection(event);
                this.state = State.SELECTING;
                break;
            case State.PICK:
            {
                this._initializeEntryStates(event);
                let result = this._startPick(event);
                this._evaluateResult(result);
                this.state = State.PICK;

                return new Event.SelectionEvent(this.selected);
            }

            case State.FINISHED:
                break;
        }

        return null;
    }

    actionPointUp (event)
    {
        this.logger.log(`FltPick::PointUp ${event.raw.type} ${this.state}`);

        switch (this.state) {
            case State.SELECT:
                break;
            case State.SELECTING:
            {
                //
                // Three.js does not support simultaneous selection over multiple views.
                // We therefore do not evaluate the event content but we return the
                // selection that has been (dynamically) found and return it to the
                // parent action. We also switch back to the entry SELECT state in order
                // to not break the Drag/Drop mouse handling.
                //
                if (this.view === event.view)
                {
                    if (this._comparePoint(event.p))
                    {
                        let result = this._startPick(event);
                        this._evaluateResult(result);
                    }
                    else
                    {
                        let result = this._collectObjects(event);
                        this._evaluateResult(result);
                    }
                }

                this.selectionFrame.onSelectOver();
                this.state = State.SELECT;
                return new Event.SelectionEvent(this.selected);
            }
                
            case State.PICK:
                break;
            case State.FINISHED:
                break;
        }

        return null
    }

    actionDynamic (event)
    {
        this.logger.log(`FltPick::Dynamic ${event.raw.type} ${this.state} ${event.raw.buttons}`);

        let result = null;

        switch (this.state) {
            case State.SELECT:
                break;
            case State.SELECTING:

                if (this.view === event.view)
                {
                    this.selectionFrame.onSelectMove(event.raw);

                    if (event.raw.buttons & this.selectionFrame.mouseBtnFlags)
                    {
                        let result = this._collectObjects(event);

                        if (this.hasFlag(PickFlags.DYNAMIC_SELECT))
                            this._evaluateResult(result);
                    }
                    else
                    {
                        this.selectionFrame.onSelectOver();
                        this.state = State.SELECT;
                    }
                }
                break;
            case State.PICK:
                result = event;
                break;
            case State.FINISHED:
                break;
        }

        return result;
    }

    _comparePoint(p)
    {
        return (this.point[0] === p[0] 
             && this.point[1] === p[1] 
             && this.point[2] === p[2]);
    }

    _initializeEntryStates(event)
    {
        this.point = event.p;

        if (this.hasFlag(PickFlags.NO_COLLECT) || !event.raw.ctrlKey)
        {
            if (this.hasFlag(PickFlags.IGNORE_MSL) === false)
                theApp.model.selectionList.clear();

            this.selected = [];
        }
    }

    _initializeSelection(event)
    {
        this.view = event.view;

        let scene    = this.view.scene;
        let camera   = this.view.camera;
        let renderer = this.view.renderer;

        if (this.hasFlag(PickFlags.NO_DRAG) === false)
        {
            this.selectionBox   = new SelectionBox   ( camera, scene );
            this.selectionFrame = new SelectionFrame ( this.selectionBox, renderer, 'selectBox', MouseButtonFlags.LEFT_BUTTON, false );
        }
    }

    _startPick(event)
    {
        return Pick.pick(event.view, event.raw, Array.from(this.types));
    }

    _startSelection(event)
    {
        this._initializeSelection(event);

        const raw = event.raw;

        const coord = this.view.getRelative(raw);

        this.selectionBox.startPoint.set(
            coord.x,
            coord.y,
            0.5 );
        
        this.selectionFrame.onSelectStart(raw);
    }

    _collectObjects(event)
    {
        const raw = event.raw;

        const coord = this.view.getRelative(raw);

        this.selectionBox.endPoint.set(
            coord.x,
            coord.y,
            0.5 );

        const allSelected = this.selectionBox.select();

        let objects = [];

        const opRoot = event.view.getRoot();

        for ( let i = 0; i < allSelected.length; i++ )
        {
            let selected = allSelected[i];

            if (selected.parent && selected.parent.type === 'Group') {
                let group = selected.parent;
                if (group.userData['opId']) {
                    let opId = group.userData['opId'];
                    if (opId)
                    {
                        const op = opRoot.findOpObjectById(opId);
                        if (op)
                        {
                            if (this.types.size === 0 || this.types.has(op.type))
                            {
                                objects.push(op);
                            }
                        }
                    }
                }
            }
        }

        return objects;
    }

    _evaluateResult(result)
    {
        if (result.length === 0)
            return;

        let current  = new Set(this.selected);
        let selected = [];

        for ( let i = 0; i < result.length; i++ )
        {
            let op = result[i];

            if (!current.has(op))
            {
                this.selected.push(op);
                selected.push(op);
                current.add(op);
            }
         }

        this._updateSelectionList(selected);

        this._logSelection();
    }

    _updateSelectionList(selected)
    {
        if (this.hasFlag(PickFlags.IGNORE_MSL) === false)
        {
            selected.forEach(op => {
                theApp.model.selectionList.add(op);
            })
        }
    }

    _logSelection()
    {
        this.logger.log(`FltPick::_logSelection: N_selected = ${this.selected.length}`);
        this.selected.forEach(op => {
            this.logger.log(`   op = { ${op.id}, ${op.type}, ${op.name}, ${op.filename} }`);
        })

        theApp.model.selectionList.log();
    }
}    
