export default class CommandPool {
    static root = new CommandPool(null, 'Root');

    // TODO: avoid name duplicates?
    constructor (pool, name, cmds = []) {
      this.name = name;
      this.children = [];
      if (pool) { pool.children[name] = this; }
      this.addCommands(cmds);
    }

    addCommand (cmd) {
      this.children[cmd.name] = cmd;
    }

    addCommands (cmds) {
      cmds.forEach(cmd => this.addCommand(cmd));
    }

    findCommand (name) {
      let cmd = null;
      let parent = CommandPool.root;
      const work = name;

      let p = work.indexOf('.') + 1; // skip root
      while (p > 0) {
        const q = name.indexOf('.', p);

        if (q > 0) {
          const poolName = name.substring(p, q);
          const child = parent.children[poolName];
          if (child === null || !(child instanceof CommandPool)) { return null; } else { parent = child; }
        } else {
          const cmdName = name.substring(p);
          const child = parent.children[cmdName];
          if (child == null || child instanceof CommandPool) { return null; } else { cmd = child; }
        }
        p = q + 1;
      }

      return cmd;
    }
}
