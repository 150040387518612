<template>
    <div class="settings-panel border">
        <div class="d-flex flex-column border" style="height: 100%; width: 100%; overflow: hidden;">
            <div class="h-100">
                <div style="padding-left: 25px; padding-top: 10px; padding-bottom: 10px; font-size: 20px; background-color: lightblue;">
                    Einstellungen
                </div>
                <div class="col-sm-12">
                    <legend>Raster</legend>
                    <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" role="switch" id="raster-enabled" v-model="rasterEnabled" @change="onChangeRasterEnabled">
                        <label class="form-check-label" for="raster-enabled">Raster aus/ein</label>
                    </div>
                </div>
                <div class="col-sm-12">
                    <div class="input-group mt-3">
                        <label for="range-col-offset" class="form-label">Rastergröße {{rasterSize}}</label>
                        <input type="range" v-model="rasterSize" min="1" max="50" @input="onChangeRasterSize"  class="form-range" id="range-col-offset">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import theApp from '@/frame/Application';
import settings from '@/visual-events/data/Settings';

export default {
  name: 'SettingsPanel',
  data () {
    return {
        rasterEnabled : settings.raster.enabled,
        rasterSize : settings.raster.size
    }
  },
  methods: {
    onChangeRasterEnabled () {
        theApp.executeCommand(`val.val "rasterEnabled" ${this.rasterEnabled}`)
    },
    onChangeRasterSize () {
        theApp.executeCommand(`val.val "rasterSize" ${this.rasterSize}`)
    },
  }
}
</script>
