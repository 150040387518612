import theApp from '@/frame/Application';

import visualEvents from '@/visual-events/VisualEvents'

import Action from '@/frame/Action';
import Options from '@/frame/Options';
import Pick from '@/visual-events/view/Pick';
import GridProvider from './GridProvider';
import PlaceSymbolsList from './PlaceSymbolsList';
import settings from '../data/Settings';
import { RenumberTool } from './RenumberTool';
import FltPointDef from '@/visual-events/actions/FltPointDef';
import Geometry from '@/visual-events/model/Geometry'
import BlockUtils from './BlockUtils';
import OpFactory from '@/visual-events/model/OpFactory'
import CommandLineScanner from '@/frame/CommandLineScanner';
import Logger from '@/frame/Logger';

const State = Object.freeze({
  SELECT: 0,
  EDIT_BLOCK: 1,
  DRAG_IN_PANEL: 2,
  DRAG_IN_2D: 3,
  DRAG_ELSE: 4
});
  
export default class FltPlaceBlock extends Action {
    constructor(view2D, viewPanel) {
      super();

      this.logger = new Logger('FltPlaceBlock');

      this.state = State.SELECT;

      this.viewPanel = viewPanel;
      this.rootPanel = this.viewPanel.getRoot();

      this.view2D = view2D;
      const drawing = this.view2D.getRoot();
      //TODO: Benachrichtungsmechanismus nach dem Laden nutzen, um root2D-Bestimmung und scale nachzuholen
      this.root2D = drawing.children[0];
      this.scale = Geometry.getScaleX(this.root2D.transform);

      this.grid = new GridProvider();
      
      this.grid.cntX = settings.grid.cntX;
      this.grid.cntY = settings.grid.cntY;
      this.grid.widthX = 0.0;
      this.grid.widthY = 0.0;
      this.grid.distX = settings.grid.distX;
      this.grid.distY = settings.grid.distY;

      this.placeSymbolsList = new PlaceSymbolsList(this.root2D);

      this.renumberTool = new RenumberTool();
      this.renumberTool.modeInRow = settings.numeration.modeInRow;
      this.renumberTool.modeAlternate = settings.numeration.modeAlternate;
      this.renumberTool.modeRows = settings.numeration.modeRows;
      this.renumberTool.startNumber = settings.numeration.startNumber;
      this.renumberTool.attributeColumn = Options.getOption (
        visualEvents.opts, 
        'ticketing.computedProperties', 
        'grid_number_seat', 
        'attribute',
        this.renumberTool.attributeColumn);

      this.grafic = OpFactory.createGroup('temporary'); // temporary grafic

      this.backup = null; // selected block backup for 

      this.dialog = theApp.findDialogByName('EditObjectPanel');
    }

    actionStart () {
        this.logger.log('FltPlaceBlock::actionStart');

        this.addFilter(new FltPointDef());
        this.state = State.SELECT;
        return true;
    }

    actionDestroy () {
        this.logger.log('FltPlaceBlock::actionDestroy');
        switch (this.state) {
            case State.SELECT:
                this.state = State.SELECT;
                break;
            case State.EDIT_BLOCK:
                this.finishGrid();
                this.hideTemporaryGrafic();
                break;
            case State.DRAG_IN_2D:
                this.finishGrid();
                this.hideTemporaryGrafic();
                this.view2D.enableCameraControls();
                break;
            case State.DRAG_IN_PANEL:
            case State.DRAG_ELSE:
                this.clearGrid();
                break;
        }
    }

    actionBreak () {
        this.logger.log('FltPlaceBlock::actionBreak');
        switch (this.state) {
            case State.SELECT:
                break;
            case State.EDIT_BLOCK:
                this.clearGrid();
                this.hideTemporaryGrafic();
                this.state = State.SELECT;
                return null;
            case State.DRAG_IN_2D:
                this.clearGrid();
                this.hideTemporaryGrafic();
                this.view2D.enableCameraControls();
                this.state = State.SELECT;
                return null;
            case State.DRAG_IN_PANEL:
            case State.DRAG_ELSE: {
                this.clearGrid();
                this.state = State.SELECT;
                return null;
            }
        }

        return true;
    }

    actionPoint (event) {
        this.logger.log(`FltPlaceBlock::actionPoint    ${this.state}`);

        switch (this.state) {
            case State.SELECT:
            case State.EDIT_BLOCK:
                if (event.view === this.viewPanel) {
                    const hits = Pick.pick(event.view, event.raw, ['XOpSymbol']);
                    const op = hits[0];
                    if (op) {
                        // disconnect the current block from the GridProvider
                        this.finishGrid();

                        // build a grid with the current settings and start drag&drop
                        this.createGrid(op);
                        this.state = State.DRAG_IN_PANEL;
                    }
                }
                if (event.view === this.view2D) {
                    const hits = Pick.pick(event.view, event.raw, ['XOpSymbol']);
                    let op = hits[0];
                    if (op) {
                        if (this. placeSymbolsList.contains(op)) {
                            this.logger.log(`${op} in active block`);

                            this.adaptTemporaryGrafic();
                            this.showTemporaryGrafic();
                            this.state = State.DRAG_IN_2D;
                            this.view2D.disableCameraControls();
                        } else {
                            this.logger.log(`${op} not in active block`);

                            const block = BlockUtils.findBlockGroup(op);
                            if (block) {
                                this.logger.log(`picked other block`);

                                // disconnect the current block from the GridProvider
                                this.finishGrid();

                                // prepare the grid for 'block's parameters and start drag&drop
                                this.editGrid(block);

                                this.adaptTemporaryGrafic();
                                this.showTemporaryGrafic();
                                this.state = State.DRAG_IN_2D;
                                this.view2D.disableCameraControls();
                            } else {
                                this.logger.log(`picked but no block`);

                                this.hideTemporaryGrafic();
                                this.state = State.SELECT;
                                this.view2D.enableCameraControls();
                            }
                        }
                    } else {
                        this.logger.log(`picked in empty space`);

                        this.hideTemporaryGrafic();
                        this.state = State.SELECT;
                        this.view2D.enableCameraControls();
                    }
                }
                break;
            case State.DRAG_IN_2D:
            case State.DRAG_IN_PANEL:
            case State.DRAG_ELSE:
                // never happens
                break;
            }

        return null;
    }

    actionDynamic (event) {
        //this.logger.log('FltPlaceBlock::actionDynamic');

        switch (this.state) {
            case State.SELECT:
            case State.EDIT_BLOCK:
            case State.DRAG_IN_PANEL:
            case State.DRAG_ELSE:
                break;
            case State.DRAG_IN_2D: {
                // apply drawing scale
                let x = event.p[0] / this.scale;
                let y = event.p[1] / this.scale;

                this.grid.origin.x = x;
                this.grid.origin.y = y;
                this.placeSymbolsList.adaptSymbolPositions(this.grid);
                this.adaptTemporaryGrafic();
                theApp.model.changed2d = true; //op;
                break;
            }
        }

        return null;
    }

    actionPointUp (event) {
        this.logger.log(`FltPlaceBlock::PointUp`);
        
        switch (this.state) {
            case State.SELECT:
            case State.EDIT_BLOCK:
                break;
            case State.DRAG_IN_2D: {
                    this.state = State.EDIT_BLOCK;
                    this.view2D.enableCameraControls();
                }
                break;
            case State.DRAG_IN_PANEL: {
                    this.placeSymbolsList.resetSymbolList();
                    this.hideTemporaryGrafic();
                    this.state = State.SELECT;
                }
                break;
            case State.DRAG_ELSE: {
                    this.clearGrid();
                    this.hideTemporaryGrafic();
                    this.state = State.SELECT;
                }
                break;
        }

        return null;
    }

    actionMouse (event) {
        this.logger.log(`FltPlaceBlock::Mouse ${event.raw.type} ${this.state}`);
        
        switch (this.state) {
            case State.SELECT:
            case State.EDIT_BLOCK:
                break;
            case State.DRAG_IN_2D: {
                    if (event.raw.type === 'mouseleave') {
                        this.hideTemporaryGrafic();
                        this.removeFromModel();
                        this.view2D.enableCameraControls();
                        this.state = State.DRAG_ELSE;
                    } 
                }
                break;
            case State.DRAG_IN_PANEL: {
                    if (event.raw.type === 'mouseleave') {
                        this.state = State.DRAG_ELSE;
                    }
                }
                break;
            case State.DRAG_ELSE: {
                if (event.raw.type === 'mouseenter') {
                    const [view] = theApp.findView(event.raw.currentTarget);
                    if (view === this.view2D) {
                        this.addToModel();
                        this.showTemporaryGrafic();
                        this.adaptTemporaryGrafic();
                        this.state = State.DRAG_IN_2D;
                        this.view2D.disableCameraControls();
                    } else  if (view === this.viewPanel) {
                        this.state = State.DRAG_IN_PANEL;
                    } 
                }
                break;
            }
        }

        return null;
    }

    actionCommand (event) {
    // val-Events?
        const scanner = new CommandLineScanner(event.commandLine);

        const cmd = scanner.getCommand();

        //remove temporary grafic before svg export
        if (cmd === '.VisualEvents.save2D' 
            && this.state === State.EDIT_BLOCK) {
            this.hideTemporaryGrafic();
            this.state = State.SELECT;
        }

        return event;
    }    

    actionValue (event) {
        //this.logger.log('FltPlaceBlock::Value');
        if (event.attribute === 'mode') {
            this.grid.mode = event.value;
            this.updateGrid();
        }

        if (event.attribute === 'cntX') {
            this.grid.cntX = event.value * 1;
            this.updateGrid();
        }

        if (event.attribute === 'cntY') {
            this.grid.cntY = event.value * 1;
            this.updateGrid();
        }
        
        if (event.attribute === 'distX') {
            this.grid.distX = event.value * 1;
            this.updateGrid();
        }

        if (event.attribute === 'distY') {
            this.grid.distY = event.value * 1;
            this.updateGrid();
        }

        if (event.attribute === 'startNumber') {
            this.renumberTool.startNumber = parseInt(event.value);
            this.updateGrid();
        }

        if (event.attribute === 'modeInRow') {
            this.renumberTool.modeInRow = event.value;
            this.updateGrid();
        }

        if (event.attribute === 'modeAlternate') {
            this.renumberTool.modeAlternate = event.value;
            this.updateGrid();
        }

        if (event.attribute === 'modeRows') {
            this.renumberTool.modeRows = event.value;
            this.updateGrid();
        }
        
        if (event.attribute === 'angleX') {
            this.grid.setAngleX(event.value * 1);
            this.updateGrid();
        }

        if (event.attribute === 'angleY') {
            //TODO: Value events mit value nicht Texte sondern im 'richtigen' Typ, z.B. hier Integer, bei alternate boolean true
            this.grid.setAngleY(event.value * 1);
            this.updateGrid();
        }

        if (event.attribute === 'alternate') {
            this.grid.alternate = event.value === 'true';
            this.updateGrid();
        }

        if (event.attribute === 'keepRatioXY') {
            this.grid.keepRatioXY = event.value === 'true';
            this.updateGrid();
        }

        this.adaptTemporaryGrafic();
    }

    /**
     * delete the current block
     */
    clearGrid() {
        this.placeSymbolsList.resetSymbolList();
        theApp.model.changed2d = true;
    }

    /**
     * finish the current block 
     */
    finishGrid() {
        this.placeSymbolsList.makeRowGroups(this.grid);
        this.placeSymbolsList.resetSymbolList();
    }

    createGrid(op) {
        this.placeSymbolsList.prototype = op;
        const box = op.computeBox();
        this.grid.widthX = box.max.x - box.min.x;
        this.grid.widthY = box.max.y - box.min.y;
        this.placeSymbolsList.adaptSymbolList(this.grid.getCntX() * this.grid.getCntY());
        this.placeSymbolsList.adaptSymbolPositions(this.grid);
        this.placeSymbolsList.adaptSymbolNumbering(this.grid, this.renumberTool);
    }

    editGrid(block) {

        const op = BlockUtils.analyseBlockGroup(block, this.grid);

        block.removeFromParent();
        this.backup = block;
        this.addToModel();

        this.placeSymbolsList.prototype = op.copy(); 

        this.placeSymbolsList.adaptSymbolList(this.grid.getCntX() * this.grid.getCntY());
        this.placeSymbolsList.adaptSymbolPositions(this.grid);
        this.placeSymbolsList.adaptSymbolNumbering(this.grid, this.renumberTool);

        theApp.model.changed2d = true; //op;

        this.dialog?.update();
    }

    updateGrid() {
        this.placeSymbolsList.adaptSymbolList(this.grid.getCntX() * this.grid.getCntY())
        this.placeSymbolsList.adaptSymbolPositions(this.grid);
        this.placeSymbolsList.adaptSymbolNumbering(this.grid, this.renumberTool);
        theApp.model.changed2d = true; //op;
    }

    addToModel() {
        this.placeSymbolsList.addToModel();
    }

    removeFromModel() {
        this.placeSymbolsList.removeFromModel();
    }

    showTemporaryGrafic () {
        this.root2D.add(this.grafic);
        theApp.model.changed2d = true; //op;
    }

    hideTemporaryGrafic () {
        this.root2D.remove(this.grafic);
        theApp.model.changed2d = true; //op;
    }

    adaptTemporaryGrafic () {

        // margins relative to offsetX, offset Y
        let left = 0;
        let right = 0;
        let bottom = 0;
        let top = 0;
        if (this.placeSymbolsList.prototype) {
            const offsetX = this.grid.distX + this.grid.widthX;
            const offsetY = this.grid.distY + this.grid.widthY;

            const box = this.placeSymbolsList.prototype.computeBox();
            left = offsetX > 0 ? box.min.x / offsetX : 0;
            right = offsetX > 0 ? box.max.x / offsetX : 0;
            bottom = offsetY > 0 ? box.min.y / offsetY : 0;
            top = offsetY > 0 ? box.max.y / offsetY : 0;
        }

        const minX = left - 0.2;
        const maxX = this.grid.getCntX() - 1 + right + 0.2;
        const minY = bottom - 0.2;
        const maxY = this.grid.getCntY() - 1 + top + 0.2;
        const p0 = this.grid.getCoord( minX, minY);
        const p1 = this.grid.getCoord( maxX, minY);
        const p2 = this.grid.getCoord( maxX, maxY);
        const p3 = this.grid.getCoord( minX, maxY);
        
        const points = [ [p0[0], p0[1]], [p1[0], p1[1]], [p2[0], p2[1]], [p3[0], p3[1]] ];
        const face = OpFactory.createPolygon(points);
        face.style.fillOpacity = 0.2;

        this.grafic.children[0]?.removeFromParent();
        this.grafic.add(face);

        theApp.model.changed2d = true; //op;
    }
}    
