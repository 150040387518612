import CommandPool from '@/frame/CommandPool';

import theApp from '@/frame/Application';

export default class CommandPoolView extends CommandPool {
  constructor () {
    super(CommandPool.root, 'view', [
      // new Command('center', args => this.doCenter(args)),
      // new Command('up', args => doUp(args)),
      // new Command('down', args => doDown(args)),
      // new Command('left', args => doLeft(args)),
      // new Command('right', args => doRight(args)),
      // new Command('rotateLeft', args => doRotateLeft(args)),
      // new Command('rotateRight', args => doRotateRight(args)),
      // new Command('zoomIn', args => doZoomIn(args)),
      // new Command('zoomOut', args => doZoomOut(args))
    ]);
  }

  doCenter (args) { 
    if (args.length < 1)
      return;
    const view = theApp.findViewByName(args[0]);
    if (view)
      view.center(args); 
  }
}
