export const GridMode = Object.freeze({
    SINGLE: 'SINGLE',
    RECT: 'RECT',
    SLANTED: 'SLANTED',
    ARENA: 'ARENA',
    ROUND: 'ROUND',
    CIRCLE: 'CIRCLE',
    HALF_CIRCLE: 'HALF_CIRCLE'
});

export default class GridProvider {
    
    constructor () {
        this.mode = GridMode.SINGLE;
        this.cntX = 1;
        this.cntY = 1;
        this.widthX = 500.0;
        this.widthY = 500.0;
        this.distX = 0.0;
        this.distY = 500.0;
        this.angleX = 0.0;
        this.angleY = 90.0;

        this.origin = { x: 0.0, y: 0.0 };
        this.vecX = { x: 1.0, y: 0.0 };
        this.vecY = { x: 0.0, y: 1.0 };

        this.alternate = false;
        this.shift = 0.5;
        this.ratioXY = Math.sin(Math.PI / 3); // 60°
        this.keepRatioXY = false;
    }

    setAngleX(a) {
        this.angleX = a;
        this.vecX.x = Math.cos(a*Math.PI/180.0);
        this.vecX.y = Math.sin(a*Math.PI/180.0);
    }

    setAngleY(a) {
        this.angleY = a;
        this.vecY.x = Math.cos(a*Math.PI/180.0);
        this.vecY.y = Math.sin(a*Math.PI/180.0);
    }

    correct(i, j) {
        if (!this.alternate)
            return 0.0; //nothing to do

            let diff = this.angleY -this.angleX;

            //case near rectangle: do StartRatio forth and back
            //if (Math.abs(90.0 - diff) < 1.0)
                return (j % 2 == 0) ? 0.0 : this.shift; 

            /* TODO: in schrägen Aufstellungen Korrektur
            //calculate a correction, which brings the symbol into the closest

            //grid point
            //avoid divide by zero problems near diff = 0.0 
            if (Math.abs(diff) < 1.0)
                diff = (diff < 0) ? -1.0 : 1.0;

            //bias is the delta x caused by the difference Angle, only
            const bias = j * Math.tan((90.0 - diff) * Math.PI / 180.0);  //cot

            return (j * this.shift - bias) - Math.trunc(j * this.shift - bias);
            */
    }
    
    getCntX() {
        return this.mode == GridMode.SINGLE ? 1 : this.cntX;
    }

    getCntY() {
        return this.mode == GridMode.SINGLE ? 1 : this.cntY;
    }

    getCoord(i, j) {
        const offsetX = this.distX + this.widthX;
        const offsetY = this.keepRatioXY ? this.ratioXY * offsetX : this.distY + this.widthY;
        const u = (i + this.correct(i, j)) * offsetX;
        const v = j * offsetY;
        return this.F(u,v);
    }

    getVectorX(i, j) {
        const offsetX = this.distX + this.widthX;
        const offsetY = this.keepRatioXY ? this.ratioXY * offsetX : this.distY + this.widthY;
        const u = (i + this.correct(i, j)) * offsetX;
        const v = j * offsetY;
        return this.dFdu(u,v);
    }

    getVectorY(i, j) {
        const offsetX = this.distX + this.widthX;
        const offsetY = this.keepRatioXY ? this.ratioXY * offsetX : this.distY + this.widthY;
        const u = (i + this.correct(i, j)) * offsetX;
        const v = j * offsetY;
        return this.dFdv(u,v);
    }

    F(u, v) {
        return [this.origin.x + u * this.vecX.x + v *this.vecY.x, this.origin.y + u * this.vecX.y + v *this.vecY.y];
    }

    dFdu(u,v) {
       return [this.vecX.x, this.vecX.y]; 
    }

    dFdv(u,v) {
        return [this.vecY.x, this.vecY.y];
     }
 }