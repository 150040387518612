import * as THREE from 'three';

import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js';

import visualEvents from '@/visual-events/VisualEvents';
import Logger from '../../frame/Logger';
import { font } from '../../assets/fonts/gentilis_regular.typeface';

/**
 * utility class with convenience methods to create three.js 3D objects
*/
export default class GrfUtils {

  static logger = new Logger('GrfUtils');

  static addSquare (view, size) {
    const geometry = new THREE.BufferGeometry();
    // create a simple square shape. We duplicate the top left and bottom right
    // vertices because each vertex needs to appear once per triangle.
    const vertices = new Float32Array([
      -1.0, -1.0, 1.0,
      1.0, -1.0, 1.0,
      1.0, 1.0, 1.0,

      1.0, 1.0, 1.0,
      -1.0, 1.0, 1.0,
      -1.0, -1.0, 1.0
    ]);

    // itemSize = 3 because there are 3 values (components) per vertex
    geometry.setAttribute('position', new THREE.BufferAttribute(vertices, 3));

    const material = new THREE.MeshBasicMaterial({ color: 0xff00ff });
    const mesh = new THREE.Mesh(geometry, material);
    mesh.scale.x = size;
    mesh.scale.y = size;
    mesh.scale.z = size;
    view.scene.add(mesh);

    const edges = new THREE.EdgesGeometry(geometry);
    const line = new THREE.Line(edges, new THREE.LineBasicMaterial({ color: 0xffffff, linewidth: 1 }));
    mesh.scale.x = size;
    mesh.scale.y = size;
    mesh.scale.z = size;
    view.scene.add(line);
  }

  static addLine (view, point1, point2, style) {
    const points = [point1, point2];
    const geometryPoints = new THREE.BufferGeometry().setFromPoints(points);

    const line = new THREE.Line(geometryPoints, new THREE.LineBasicMaterial(style));

    view.scene.add(line);
  }

  /**
   * create a hexagonal shape in x,z plane
   * @param {*} view
   * @param {*} position
   * @param {*} size
   * @param {*} OFFSETX
   * @param {*} OFFSETZ
   * @param {*} color
   */
  static addHexagon (view, position, size, OFFSETX, OFFSETZ, styleArea, styleOutline) {
    const shape = new THREE.Shape()
      .moveTo(-size, 0)
      .lineTo(-OFFSETX, OFFSETZ)
      .lineTo(OFFSETX, OFFSETZ)
      .lineTo(size, 0)
      .lineTo(OFFSETX, -OFFSETZ)
      .lineTo(-OFFSETX, -OFFSETZ)
      .lineTo(-size, 0); // close path

    const material = new THREE.MeshBasicMaterial(styleArea);
    const geometry = new THREE.ShapeBufferGeometry(shape);

    geometry.rotateX(-Math.PI / 2);

    const mesh = new THREE.Mesh(geometry, material);

    mesh.position.x = position.x;
    mesh.position.y = position.y;
    mesh.position.z = position.z;

    view.scene.add(mesh);

    shape.autoClose = true;
    const points = shape.getPoints();
    const geometryPoints = new THREE.BufferGeometry().setFromPoints(points);

    // rotate into xz-plane
    geometryPoints.rotateX(-Math.PI / 2);

    // solid line
    const line = new THREE.Line(geometryPoints, new THREE.LineBasicMaterial(styleOutline));

    line.position.x = position.x;
    line.position.y = position.y + 1; // avoid z-fighting lines with faces
    line.position.z = position.z;

    view.scene.add(line);
  }

  static addCircle (view, position, size, styleArea, styleOutline) {
    const radius = size / 2;
    var shape = new THREE.Shape()
      .moveTo(0, radius)
      .absarc(0, 0, radius, 0, 2 * Math.PI, true);

    const material = new THREE.MeshBasicMaterial(styleArea);
    const geometry = new THREE.ShapeBufferGeometry(shape);

    geometry.rotateX(-Math.PI / 2);

    const mesh = new THREE.Mesh(geometry, material);

    mesh.position.x = position.x;
    mesh.position.y = position.y;
    mesh.position.z = position.z;

    view.scene.add(mesh);

    shape.autoClose = true;
    const points = shape.getPoints();
    const geometryPoints = new THREE.BufferGeometry().setFromPoints(points);

    // rotate into xz-plane
    geometryPoints.rotateX(-Math.PI / 2);

    // solid line
    const line = new THREE.Line(geometryPoints, new THREE.LineBasicMaterial(styleOutline));

    line.position.x = position.x;
    line.position.y = position.y + 1; // avoid z-fighting lines with faces
    line.position.z = position.z;

    view.scene.add(line);
  }

  /**
   * addLabel and addText require a font, which should be loaded only once in the beginning
   */
  static font = null;

  //TODO: FontManager, dictionary mit geladenen Fonts
  static loadFont (name) {

    const urlFont = visualEvents.urlFileService + '/files/fonts/' + name;

    GrfUtils.font = undefined;
    const loader = new FontLoader();
    loader.requestHeader = {
      'x-api-key': visualEvents.apiKey,
      'VisualEvents-ApiKey': visualEvents.apiKey,
      'VisualEvents-Language': visualEvents.language
    };

    loader.loadAsync(urlFont).then (
      font => {
        GrfUtils.logger.log(`font ${name} loaded`);
        GrfUtils.font = font;
      }
    ).catch (
      err => {
        console.log( `could not load font ${name}:\n${err.message}`);
        GrfUtils.font = undefined;
      }
    )
  }

  static isWaitingForFont () {
    return GrfUtils.font === undefined;
  }

  /**
   * create a shape text in the x,z-plane
   * @param {*} view
   * @param {*} text
   * @param {*} position
   * @param {*} size
   * @param {*} color
   */
  static addLabel (view, text, position, size, styleArea, styleOutline) {
    // GrfUtils.logger.log(`addLabel ${position.x} ${position.y} ${position.z}`)

    const [mesh, lineText] = GrfUtils.createLabel(text, position, size, styleArea, styleOutline);
    if (mesh) {
      mesh.rotateX(-Math.PI / 2);
      view.scene.add(mesh);
    }
    if (lineText) {
      lineText.rotateX(-Math.PI / 2);
      view.scene.add(lineText);
    }
  }

  /**
   * TODO: Reconsider the GrfUtils design: factory class(es)? E.g. avoid area/outline generation for labels, if not required
   * 
   * @param {*} text 
   * @param {*} position 
   * @param {*} size 
   * @param {*} styleArea 
   * @param {*} styleOutline 
   * @param {*} textAnchor 
   * @param {*} baseLine 
   * @returns 
   */
  static createLabel (text, position, size, style, textAnchor='start', baseLine='baseline') {
    // GrfUtils.logger.log(`addLabel ${position.x} ${position.y} ${position.z}`)
  
    if (!GrfUtils.font) {
      GrfUtils.logger.log('createLabel  Font not yet available. Using fallback');
      const loader = new FontLoader();
      GrfUtils.font = loader.parse(font);
    }

    style = style || { fill: 0x000000, strokeWidth: 1, stroke: 'none' };

    const [x, y, z] = [position.x, position.y, position.z];

    const matLine = GrfUtils.createLineBasicMaterialForSVGStyle(style);

    const matArea = GrfUtils.createMeshBasicMaterialForSVGStyle(style);

    const shapes = GrfUtils.font.generateShapes(text, size);

    const geometry = new THREE.ShapeBufferGeometry(shapes);

    geometry.computeBoundingBox();

    let xOffset = 0;
    switch (textAnchor) {
      case 'start':
        break;
      case 'middle':
        xOffset = -0.5 * (geometry.boundingBox.max.x - geometry.boundingBox.min.x);
        break;
      case 'end':
        xOffset = - (geometry.boundingBox.max.x - geometry.boundingBox.min.x);
        break;
    }

    //TODO: dominantBaseline auto, mathematical, text-top u.a. fehlen, auto hängt vom writing-mode ab
    let yOffset = 0;
    switch (baseLine) {
      default:
      case 'baseline':  //TODO: gibt es eigentlich nicht, CADdy++ SVG Export fehlerhaft?
        break;
      case 'middle':
        yOffset = - 0.5 * (geometry.boundingBox.max.y - geometry.boundingBox.min.y);
        break;
      case 'hanging':
        yOffset = - (geometry.boundingBox.max.y - geometry.boundingBox.min.y)
        break;
    }    
    
    geometry.translate(x + xOffset, y + yOffset, 10); // z=10 for higher priority

    const mesh = matArea ? new THREE.Mesh(geometry, matArea) : null;

    const lineText = matLine ? new THREE.Object3D() : null;
    
    if (matLine) {
      const holeShapes = [];

      for (var i = 0; i < shapes.length; i++) {
        const shape = shapes[i];

        if (shape.holes && shape.holes.length > 0) {
          for (var j = 0; j < shape.holes.length; j++) {
            const hole = shape.holes[j];
            holeShapes.push(hole);
          }
        }
      }

      shapes.push.apply(shapes, holeShapes);

      for (let i = 0; i < shapes.length; i++) {
        const shape = shapes[i];

        const points = shape.getPoints();
        const geometry = new THREE.BufferGeometry().setFromPoints(points);

        geometry.translate(x + xOffset, y + yOffset, 20); // z=20 for higher priority

        const mesh = new THREE.Line(geometry, matLine);
        lineText.add(mesh);
      }
    }

    return [mesh, lineText];
  }

  /**
   * convert SVGStyles as imported by CADdySVGLoader into feasible three.js materials
   * @param {*} SVG style 
   * @returns 
   */
  static createMeshBasicMaterialForSVGStyle(style) {

    if (style.fill === 'none')
      return null;

    if (style.visibility == 'hidden')
      return null;

    return new THREE.MeshBasicMaterial({
      color: new THREE.Color().setStyle( style.fill ),
      opacity: style.fillOpacity,
      transparent: style.fillOpacity !== 1
    });
  }

  static createLineBasicMaterialForSVGStyle(style) {
    if (style.stroke === 'none')
      return null;

    return new THREE.LineBasicMaterial({
      color: 0x000000,
      linewidth: style.strokeWidth,
      linecap: style.strokeLineCap, // e.g.'round', ignored by WebGLRenderer
      linejoin:  style.strokeLineJoin, // e.g. 'round', ignored by WebGLRenderer
    });
  }

  /**
   * create 3D solids with text shape
   * @param {*} view 
   * @param {*} text 
   * @param {*} position 
   * @param {*} height 
   * @param {*} size 
   * @returns 
   */
  static addText (view, text, position, size, height) {
    // GrfUtils.logger.log(`add3DText ${position.x} ${position.y} ${position.z}`)
    if (GrfUtils.font === null) {
      GrfUtils.logger.log('waiting for font');
      return;
    }

    const [x, y, z] = [position.x, position.y, position.z];

    const geometry = new THREE.TextGeometry(text, {
      size: size,
      height: height, // extrusion
      curveSegments: 6,
      font: GrfUtils.font
    });

    geometry.rotateX(-Math.PI / 2);

    const color = new THREE.Color();
    color.setRGB(200, 0, 0);
    const material = new THREE.MeshBasicMaterial({ color: color });
    const mesh = new THREE.Mesh(geometry, material);

    mesh.position.x = x;
    mesh.position.y = y;
    mesh.position.z = z;

    view.scene.add(mesh);
  }
}
