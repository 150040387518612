export default class Command {
  constructor (name, onExecute) {
    this.name = name;
    this.onExecute = onExecute;
  }

  // args are any objects
  execute (...args) {
    return this.onExecute(args);
  }

  onExecute (...args) {
    // override
  }
}
