import RestAPI from '@/visual-events/RestAPI/RestAPI';

import axios from 'axios';
import Logger from '../../frame/Logger';

export default class FileService {

    constructor (urlFileService, apiKey, language) {
      this.logger = new Logger('FileService');
      this.urlFileService = urlFileService;
      this.apiKey = apiKey;
      this.language = language;
    }
    
    async subFolders(parentPath) {
      const search = {
        folder: 'folder',
        parentPath: parentPath
      }
      const query = `?${new URLSearchParams(search).toString()}`;
      const endpoint = `${this.urlFileService}/nodes${query}`;
      const method = 'GET';
      const header = new Headers({ Accept: 'application/json', 'x-api-key': this.apiKey, 'VisualEvents-ApiKey': this.apiKey, 'VisualEvents-Language': this.language});

      try {
        const json = await RestAPI.getResponse(endpoint, method, header);
        this.logger.log(`${json}`)
        return json;
      } catch(e) {
        this.logger.error(`Exception:${method} ${endpoint}:\n${e.message}`);
      }
    }

    async download(parentPath, filename) {
        const endpoint = `${this.urlFileService}/files/${parentPath}/${filename}`;
        const method = 'GET';
        const header = new Headers({'x-api-key': this.apiKey, 'VisualEvents-ApiKey': this.apiKey, 'VisualEvents-Language': this.language});
  
        try {
          const response = await RestAPI.getResponse(endpoint, method, header);
          return response;
        } catch(e) {
          this.logger.error(`Exception:${method} ${endpoint}:\n${e.message}`);
        }
    }

    async upload(parentPath, filename, content, mime) {
        
        const endpoint = `${this.urlFileService}/nodes`;
        const method = 'POST';
        const headers = { Accept: 'application/json', 'x-api-key': this.apiKey, 'VisualEvents-ApiKey': this.apiKey, 'VisualEvents-Language': this.language };

        const data = new FormData();
        data.append('type', 'file');
        data.append('name',`${filename}`);
        data.append('parentPath',`${parentPath}`);
        const blob = new Blob([content], { type: `${mime}`});
        data.append('file', blob);

        try {
            axios.request({
                            url: endpoint, 
                            method: method,
                            headers: headers,
                            data:  data
                          })
              .then(function (response) {
                return response;
              })
              .catch(function (error) {
                this.logger.error(error);
              });    
        } catch(e) {
          this.logger.error(`Exception:${method} ${endpoint}:\n${e.message}`);
        }
    }

    //TODO:upload with fetch API instead of axios
    //
    // open questions:
    // 
    // We use Content-Type multipart/form-data
    // The items are separated by an deliberately choosen boundary/delimiter, which must be stated in the Content-Type
    // and the correctly calculated length of the body
    //
    // Content-Type: multipart/form-data; boundary=---------------------------735323031399963166993862150
    // Content-Length: 834
    // -----------------------------735323031399963166993862150
    // Content-Disposition: form-data; name="text1"
    // text default
    // -----------------------------735323031399963166993862150
    // Content-Disposition: form-data; name="text2" 
    // ...
    //
    // FormData is available in browser to build up the payload.
    //
    // Obviously, axios under the hood creates the correct headers for us, but not so does the fetch api
    // We don't know how to correctly calcalute the Content-Length.
    //
    // Some forums say it's something like
    //
    // const getFormDataSize = formData => [...formData].reduce((size, [name, value]) => size + (typeof value === 'string' ? value.length : value.size), 0);
    //
    // But it is not clear whether the delimeter text and item headers should be considered, too.
    //
    // static async uploadFetch(parentPath, name, content, mime) {
    //
    //         const endpoint = `${VisualEvents.urlFileService}/nodes`;
    //         const method = 'POST';
    //
    //         const data = new FormData();
    //         data.append('type', 'file');
    //         data.append('name',`${name}`);
    //         data.append('parentPath',`${parentPath}`);
    //         const blob = new Blob([content], { type: `${mime}`});
    //         data.append('file', blob);
    //         const length = getFormDataSize(data);
    //         // in headers omit 'Content-Type':  multipart/form-data; boundary=---------------------------735323031399963166993862150 ? Which delimiter?
    //         const headers = new Headers({ 'Content-Length': length, Accept: 'application/json', 'VisualEvents-ApiKey': VisualEvents.apiKey, 'VisualEvents-Language': VisualEvents.language });
    //         const length = getFormDataSize(data);
    //         const response = await fetch(
    //             endpoint,
    //             {
    //                 method: method,
    //                 headers: headers,
    //                 body: data,
    //             }
    //         );
    //         if (!response.ok) {
    //             throw new Error(`HTTP error! status: ${response.status} ${response.statusText}`);
    //         }
    //         const type = response.headers.get('Content-Type');
    //         if (type && type.includes('application/json'))
    //             return await response.json();
    //         return null;
    //     }
    }

    