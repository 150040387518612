<template>
  <div class="home-grid">
    <div class="coupled-2d-3d">
      <WebGLRenderCanvas v-bind:viewName="'3D Ansicht'">3D-Ansicht</WebGLRenderCanvas>
      <WebGLRenderCanvas v-bind:viewName="'2D Ansicht'">2D-Ansicht</WebGLRenderCanvas>
    </div>
    <div class="sidepanel">
      <SidePanel></SidePanel>
      <WebGLRenderCanvas v-bind:viewName="'Symbole'" style="height: 50vh" class="border">Symbole</WebGLRenderCanvas>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import WebGLRenderCanvas from '@/vuejs/components/WebGLRenderCanvas';
import SidePanel from '@/vuejs/components/SidePanel';
import theApp from '@/frame/Application';
import visualEvents from '@/visual-events/VisualEvents'
import Logger from '@/frame/Logger';

export default {
  name: 'Home',
  setup () {
    return {
      logger: new Logger('Planer')
    }
  },
  props: {
    model: String,
    symbols: String,
    variant: String,
    action: String
  },
  components: {
    WebGLRenderCanvas,
    SidePanel
  },
  methods: {
    load() {
      const urlModel = visualEvents.urlFileService + "/files/models/" + this.model + '/' + this.variant;
      //TODO:bessere Methode finden als action-aufruf after load
      this.logger.log(`.VisualEvents.load ${urlModel} ${this.action}`);
      theApp.sendCommand(`.VisualEvents.load ${urlModel} ${this.action}`);

      const urlPanel = visualEvents.urlFileService + "/files/models/" + this.model + '/' + this.symbols;
      this.logger.log('.VisualEvents.loadPanel ' + urlPanel);
      theApp.sendCommand('.VisualEvents.loadPanel ' + urlPanel)
      //theApp.sendCommand(this.action)
    },
    save() {
      const urlModel = visualEvents.urlFileService + "/files/models/" + this.model + '/' + this.variant;
      theApp.sendCommand('.VisualEvents.save ' + urlModel);
    }
  },
  mounted () {
    // visualEvents.init();
    this.load();
  },
  updated () {
    this.load();
  }
}
</script>

<style scoped>
* {
  margin: 0;
  box-sizing: border-box;
  scrollbar-width: thin;
}
.home {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
  height: 100vh;
}

.home-grid {
  display: grid;
  grid-template-columns: 2fr 1fr;
  overflow-x: hidden;
}

.coupled-2d-3d {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100vh;
}

.sidepanel {
  height: 100vh;
}
</style>
