import Command from '@/frame/Command';
import CommandPool from '@/frame/CommandPool';

import theApp from '@/frame/Application';
import Action from '@/frame/Action';

import ActMove3DObject from '@/visual-events/actions/ActMove3DObject';
import ActPlaceBlock from '@/visual-events/actions/ActPlaceBlock';
import ActBooking from '@/visual-events/actions/ActBooking';
import ActTicketing from '@/visual-events/actions/ActTicketing';
import CADdy2DLoader from '@/visual-events/loader/CADdy2DLoader';
import CADdy3DLoader from '@/visual-events/loader/CADdy3DLoader';

import VisualEvents from '@/visual-events/VisualEvents';
import CADdySVGExporter from './loader/CADdySVGExporter';
import FileService from './RestAPI/FileService';
import Ticketing from './ticketing/Ticketing';
import Logger from '../frame/Logger';

export default class CommandPoolVisualEvents extends CommandPool {
  constructor () {
    super(CommandPool.root, 'VisualEvents');
    this.logger = new Logger('CommandPoolVisualEvents');
    this.addCommands([
      new Command('load', args => this.doLoad(args)),
      new Command('save', args => this.doSave(args)),
      new Command('load2D', args => this.doLoad2D(args)),
      new Command('save2D', args => this.doSave2D(args)),
      new Command('createItems', args => this.doCreateItems(args)),
      new Command('loadPanel', args => this.doLoadPanel(args)),
      new Command('move3DObject', args => this.doMove3DObject(args)),
      new Command('placeBlock', args => this.doPlaceBlock(args)),
      new Command('booking', args => this.doBooking(args)),
      new Command('ticketing', args => this.doTicketing(args))
    ]);
  }

  load2D (baseUrl, afterLoadAction) {
    let drawingUrl = baseUrl + '/drawing.svg';
    this.logger.log(`load2D(${drawingUrl}, ${afterLoadAction})`);

    let view2d = theApp.findViewByName('2D Ansicht');

    const loader = new CADdy2DLoader(this);
    loader.requestHeader = {
      'x-api-key': VisualEvents.apiKey,
      'VisualEvents-ApiKey': VisualEvents.apiKey,
      'VisualEvents-Language': VisualEvents.language
    };
    loader.load(drawingUrl, opObjects => {
      this.logger.log('VisualEvents::init loaded 2D');
      const symbols = opObjects.filter(op => op.type === 'XOpSym');
      symbols.forEach(s => {
          theApp.model.symbols[s.filename] = s;
      });

      const drawings = opObjects.filter(op => op.type === 'XOpDraft');
      theApp.model.drawing.push(...drawings);

      view2d.setRoot(drawings[0]);
       
      theApp.model.changed2d = true;

      if ( afterLoadAction)
           theApp.sendCommand(afterLoadAction);
    });
  }

  save2D (parentPath, filename) {
    this.logger.log(`save2d(${parentPath}, ${filename})`);

    const view2d = theApp.findViewByName('2D Ansicht');
    const drawing = view2d.root;

    const exporter = new CADdySVGExporter();
    const svg = exporter.write(theApp.model.symbols, drawing);

    const fileService = new FileService(VisualEvents.urlFileService, VisualEvents.apiKey, VisualEvents.language);
    
    fileService.upload(parentPath, filename, svg, 'image/svg+xml')

    //then   fileService.download('parentPath, filename);
  }

  doLoad2D (args) {
    this.logger.log('CommandPoolVisualEvents::doLoad2D');
    
    let baseUrl = args[1];
    let afterLoadAction = args.slice(2).join(' ');

    this.load2D(baseUrl, afterLoadAction);
  }

  doSave2D (args) {
    this.logger.log('CommandPoolVisualEvents::doSave2D');
    
    if (args.length == 3) {
      const parentPath = args[1];
      const filename = args[2];

      this.save2D(parentPath, filename);
    }
  }

  doCreateItems (args) {
    this.logger.log('CommandPoolVisualEvents::doCreateItems');

    if (args.length == 2) {
      const eventId = args[1];
      Ticketing.createBookableItems(eventId);
    }
  }

  doLoad (args) {
    this.logger.log('CommandPoolVisualEvents::doLoad');
    
    let baseUrl = args[1];
    let afterLoadAction = args.slice(2).join(' ');

    this.load2D(baseUrl, null);

    let view3d = theApp.findViewByName('3D Ansicht');

    CADdy3DLoader.load3D(theApp.model, baseUrl).then(() => {
      this.logger.log('VisualEvents::init loaded 3D');

      view3d.setRoot(theApp.model.space);

      theApp.model.changed3d = true;

      if (theApp.findMountedViewByName('3D Ansicht')) {
        view3d.updateScene();
        view3d.centerCamera();
        view3d.addLight();
      }
      
      if (afterLoadAction)
        theApp.sendCommand(afterLoadAction);
    });
  }

  buildCommandline(tokens) {
    tokens.forEach
  }

  doLoadPanel (args) {
    this.logger.log('CommandPoolVisualEvents::doLoadPanel');

    let baseUrl = args[1];
    let symbolsUrl = baseUrl + '/drawing.svg';

    let panel = theApp.findViewByName('Symbole');

    const loader = new CADdy2DLoader(this);
    loader.requestHeader = {
      'x-api-key': VisualEvents.apiKey,
      'VisualEvents-ApiKey': VisualEvents.apiKey,
      'VisualEvents-Language': VisualEvents.language
    };
    loader.load(symbolsUrl, opObjects => {
      this.logger.log('VisualEvents::init loaded 2D Symbole');
      const symbols = opObjects.filter(op => op.type === 'XOpSym');
      symbols.forEach(s => {
          theApp.model.symbols[s.filename] = s;
      });

      const drawings = opObjects.filter(op => op.type === 'XOpDraft');
      theApp.model.drawing.push(...drawings);

      panel.setRoot(drawings[0]);

      panel.camera.zoom = 4;  // TODO: zoom ausrechnen, einpassen
      const cx = 150/2;
      const cy = -100/2;
      panel.center([this.name, cx, cy]); 
  
      theApp.model.changedPanel = true;

      panel.updateScene();
    });
  }

  doSave(args) {
    this.logger.log('CommandPoolVisualEvents::doSave ' + args);

    let filename = args[1] + '/model.json';
    let buffer = Buffer.from(JSON.stringify(theApp.model.space));
    let content = buffer.toString('base64');

    let json = {
        filename: filename,
        content: content
    };

    let request = new XMLHttpRequest();

    request.onreadystatechange = function () {
      if (this.readyState == 4 && this.status == 200) {
        this.logger.log(this.response);
      }
    }

    request.open('POST', 'scripts/upload.php');
    request.setRequestHeader("Content-Type", "application/json");
    request.send(json);
  }

  doMove3DObject (args) {
    this.logger.log('CommandPoolVisualEvents::doMove3DObject');
    Action.start(new ActMove3DObject(args));
  }

  doPlaceBlock(args) {
    this.logger.log('CommandPoolVisualEvents::doPlaceBlock');
    Action.start(new ActPlaceBlock(args));
  }

  doBooking(args) {
    this.logger.log('CommandPoolVisualEvents::doBooking');
    Action.start(new ActBooking(args));
  }

  doTicketing(args) {
    this.logger.log('CommandPoolVisualEvents::doTicketing');
    Action.start(new ActTicketing(args));
  }
}
