<template>
    <div class="attributes-panel border">
        <div class="d-flex flex-column border" style="height: 100%; width: 100%; overflow: hidden;">
            <div class="h-100">
                <div style="padding-left: 25px; padding-top: 10px; padding-bottom: 10px; font-size: 20px; background-color: lightblue;">
                    Eigenschaften
                </div>
                <!-- <div class="col-sm-12">
                    <label>Ausgewählte Objekte: {{selectionListIDs}}</label>
                </div> -->

                <div class="m-2 prop-sub-heading">Einstellungen</div>

                <div id="prop-settings" class="m-3">
                    <div class="row mb-3">
                        <label class="col-sm-3 col-form-label">Buchungsmodus</label>
                        <div class="col-sm-9">
                            <select class="form-select col-sm-8" v-model="bookingMode" @change="onChangeBookingMode">
                                <option id="bookingModes" v-for="mode in bookingModes" :key="mode.id" :value="mode.id">
                                    {{ mode.name }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" id="auto-increment" v-model="autoIncrement" @change="onChangeAutoIncrement">
                                <label class="col-sm-9 form-check-label" for="auto-increment">Ids als fortlaufende Nummern erstellen ab</label>
                                <input class="col-sm-3 ds-prop-edit" type="text" v-model="firstId" @input="onChangeItemId" >
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" id="idGenerator" v-model="idGenerator" @change="onChangeIdGenerator">
                                <label class="col-sm-3 form-check-label" for="idGenerator">Bildungsmuster</label>
                                <input class="col-sm-9 ds-prop-edit" type="text" v-model="idGeneratorPattern" @input="onChangeIdGeneratorPattern" >
                            </div>
                        </div>
                    </div>
                </div>

                <hr />

                <table class="table table-borderless">
                    <thead>
                        <tr>
                            <th>Eigenschaft</th>
                            <th>Wert</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="align-middle">itemId</td>
                            <td><input class="w-100 ds-prop-edit" type="text" v-model="itemId" @input="onChangeItemId" :disabled="countSelection !== 1"></td>
                            <td class="align-middle"><i class="fa-solid fa-laptop-code" @click="onGenerateItemIds" role="button" title="Generieren"></i></td>
                            <td></td>
                        </tr>
                        <tr v-for="prop in selectionProperties" :key="prop.name">
                            <td class="align-middle">{{prop.name}}</td>
                            <td><input class="w-100 ds-prop-edit" type="text" v-model="prop.value" @input="onChangeAttribute(prop.name)" :placeholder="countSelection === 1 ? labelUndefined : labelDiffering" ></td>
                            <td class="align-middle"><i class="fa-solid fa-reply" v-if="prop.from" @click="onTakeAttributeFrom(prop.name, prop.from)" role="button" title="Übernehmen"></i></td>
                            <td class="align-middle"><i class="fa-solid fa-trash" v-if="prop.used" @click="onDeleteAttribute(prop.name)" role="button" title="Löschen"></i></td>
                        </tr>
                    </tbody>
                </table>

                <div v-if="!showCreateAttribute">
                    <button class="btn btn-primary m-3" @click="showCreateAttribute = true"><i class="fa-solid fa-plus"></i> Neue Eigenschaft</button>
                </div>
                <div v-if="showCreateAttribute">
                    <table class="table table-borderless">
                        <thead>
                            <tr>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="align-middle">Name</td>
                                <td><input class="w-100 ds-prop-edit" type="text" v-model="newAttributeName"/></td>
                                <td class="align-middle"><i class="fa-solid fa-check" @click="onCreateAttribute()" role="button"></i></td>
                                <td class="align-middle"><i class="fa-solid fa-xmark" @click="showCreateAttribute = false" role="button"></i></td>
                            </tr>
                        </tbody>
                    </table>
                </div>   
            </div>
        </div>
    </div>
</template>

<script>
import Action from '@/frame/Action'
import Logger from '@/frame/Logger';
import FltEditAttributes from '@/visual-events/actions/FltEditAttributes';

export default {
  name: 'AttributesPanel',
  setup () {
    return {
        logger: new Logger('AttributesPanel')
    }
  },
  data () {
    return {
        bookingModes: [],
        bookingMode: '',
        countSelection: 0,
        selectionListIDs: '',
        showCreateAttribute: false,
        newAttributeName: '',
        selectionProperties: [],
        itemId: '',
        autoIncrement: false,
        firstId: 1,
        idGenerator: false,
        idGeneratorPattern: ''
    }
  },
  computed: {
    labelUndefined() {return 'undefiniert'},
    labelDiffering() {return 'unterschiedlich'}
  },
  mounted () {
    const action = Action.findByClass(FltEditAttributes)
    if (action) 
        action.dialog = this
    Action.findByClass(FltEditAttributes)?.initializeDialog()
  },
  unmounted () {
    const action = Action.findByClass(FltEditAttributes)
    if (action) 
        action.dialog = null;
  },
  methods: {
    onChangeBookingMode() {
        this.logger.log(`change booking mode`);
        Action.findByClass(FltEditAttributes)?.changeBookingMode()
    },
    onChangeAttribute(name) {
        this.logger.log(`change Attribute`);
        const value = this.selectionProperties.find(el => el.name==name).value
        Action.findByClass(FltEditAttributes)?.changeAttribute(name, value)
    },
    onTakeAttributeFrom(name, from) {
        this.logger.log(`take Attribute from`);
        Action.findByClass(FltEditAttributes)?.takeAttributeFrom(name, from)
    },
    onDeleteAttribute(attname) {
        this.logger.log(`delete Attribute`);
        Action.findByClass(FltEditAttributes)?.deleteAttribute(attname)
    },
    onCreateAttribute() {
        this.logger.log(`create Attribute`);
        Action.findByClass(FltEditAttributes)?.createAttribute(this.newAttributeName)
        this.showCreateAttribute = false
    },
    onChangeItemId() {
        Action.findByClass(FltEditAttributes)?.setItemId(this.itemId);
    },
    onGenerateItemIds() {
        Action.findByClass(FltEditAttributes)?.generateItemIds(this.autoIncrement, this.firstId, this.idGenerator, this.idGeneratorPattern);
    },
    onChangeAutoIncrement() {
        if (this.autoIncrement)
            this.idGenerator = false;
        Action.findByClass(FltEditAttributes)?.setAutoIncrement(this.autoIncrement);
    },
    onChangeIdGenerator() {
        if (this.idGenerator)
            this.autoIncrement = false;
        Action.findByClass(FltEditAttributes)?.setAutoIncrement(!this.idGenerator);
    },
    onChangeIdGeneratorPattern() {
        Action.findByClass(FltEditAttributes)?.setIdGeneratorPattern(this.idGeneratorPattern);
    }
  }
}
</script>

<style scoped>
.prop-sub-heading {
    font-size: 18px;
    font-weight: bold;
}
.ds-prop-edit {
    background-color: #e9e3e3;

    /* outline: none; 
  
    border-bottom: 2px solid transparent;
    border-image-source: linear-gradient(to right, black, black);
    border-image-slice: 1; */
    border-radius: 5px !important;
    border: none; 
    padding: 5px;
    transition: border-image 0.5s;
}
.ds-prop-edit:hover {
    background-color: rgb(228, 220, 220);
}
.ds-prop-edit:focus {
    /* outline: black solid 1px; */
    outline: none;
    background-color: rgb(247, 243, 243);
    border-image-source: linear-gradient(to right, rgb(97, 163, 185), rgb(97, 163, 185));
}
</style>