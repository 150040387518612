<template>
  <div class='threejs-viewport' ref='viewport'/>
</template>

<script>
import theApp from '@/frame/Application'
import Logger from '@/frame/Logger';

export default {
  props: {
    viewName: String
  },
  setup () {
    return { logger: new Logger('WebGLRenderCanvas') }
  },
  mounted () {
    this.logger.log(`mounted ${this.viewName}`)
    const view = theApp.findViewByName(this.viewName)
    // get the div with ref='viewport' in the component
    view.mount(this.$refs.viewport)
  },
  beforeUnmount () {
    this.logger.log(`beforeUnmount ${this.viewName}`)
    const view = theApp.findViewByName(this.viewName)
    view.unmount()
  }
}
</script>

<style>
.threejs-viewport {
  width: 100%;
  height: 100%;
}

.selectBox {
  border: 1px solid #55aaff;
  background-color: rgba(75, 160, 255, 0.3);
  position: fixed;
}
</style>

