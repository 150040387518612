import Command from '@/frame/Command';
import CommandPool from '@/frame/CommandPool';

import Logger from './Logger';

/**
 * this command pool contains commands, which should be available under all circumstances as long
 * as the application singleton lives, namely diagnostic on the framework like logging
 */
export default class CommandPoolMain extends CommandPool {
    constructor() {
        super(CommandPool.root, 'main');
        this.addCommands([
            new Command('activateLoggers', args => this.doActivateLoggers(args)),
            new Command('deactivateLoggers', args => this.doDeactivateLoggers(args))
        ]);
    }

    doActivateLoggers(args) {
        let contexts = args.length > 1 ? args.splice(1) : null;
        Logger.activate(contexts);
    }

    doDeactivateLoggers(args) {
        let contexts = args.length > 1 ? args.splice(1) : null;
        Logger.deactivate(contexts);
    }
}