import { createApp } from 'vue'
import App from './vuejs/App.vue'
import router from './vuejs/router'

import visualEvents from '@/visual-events/VisualEvents'
import theApp from '@/frame/Application'
import Logger from '@/frame/Logger';

const logger = new Logger('main');

window.startVisualEvents = function (rootContainer, opts={}) {
    logger.log('startVisualEvents called');

    const old = theApp.getGui();
    if (old)
        old.unmount();

    const vue = createApp(App);
    vue.use(router).mount(rootContainer);

    // store the vue instance in the application singleton
    // e.g. in order to the access bootstrap modals $bvModal
    theApp.setGui(vue);

    // init addins, here just one: VisualEvents
    visualEvents.init(opts);

    const route = opts.route || '';
    
    router.push(`${route}`);

    return theApp;
}

window.sendCommand = function (cmd, app = theApp) {
    app.sendCommand(cmd);
}

window.stopVisualEvents = function (app = theApp) {
    
    visualEvents.exit();

    const old = app.getGui();
    if (old)
        old.unmount();

    app.setGui(null);
}
