import Action from '@/frame/Action';
import theApp from '@/frame/Application';
import FltPick from '@/visual-events/actions/FltPick';
import { PickFlags } from '@/visual-events/actions/FltPick';
import Geometry from '@/visual-events/model/Geometry'
import Logger from '@/frame/Logger';

const State = Object.freeze({
  SELECT: 0,
  MOVE: 1
});
  
export default class ActMove3DObject extends Action {
    constructor(args) {
      super();

      this.logger = new Logger('ActMove3DObject');

      this.args = args;
      this.pointStart = null;
      this.objects3D = [];
      this.objects2D = [];
      this.state = State.SELECT;

      this.view2D = theApp.findViewByName('2D Ansicht');
      const drawing = this.view2D.getRoot();
      //TODO: Benachrichtungsmechanismus nach dem Laden nutzen, um root2D-Bestimmung und scale nachzuholen
      this.root2D = drawing.children[0];
      this.scale = Geometry.getScaleX(this.root2D.transform);
    }

    actionStart () {
        this.logger.log('ActMove3DObject::Start');

        this.addPickFilter();

        this.view2D.addCameraControls();
        this.view2D.fitToCanvas();
        
        return true;
    }

    actionDestroy () {
        this.logger.log('ActMove3DObject::Destroy');
        
        switch (this.state) {
            case State.SELECT:
                break;
            case State.MOVE:
                break;
        }
        
        this.view2D.removeCameraControls();
    }

    actionBreak () {
        this.logger.log('ActMove3DObject::Break');

        switch (this.state) {
            case State.SELECT:
                break;
            case State.MOVE:
                //TODO: reset positions
                this.objects2D = [];
                this.objects3D = [];
                return false;
        }

        this.handleCameraControlsEnableState();

        return true;
    }

    actionSelection (event) {
        this.logger.log('ActMove3DObject::Selection');

        switch (this.state) {
            case State.SELECT: {

                this.objects2D.push(...event.objects);
                this.pointStart = this.objects2D[0].transform.elements.slice(12);
                this.logger.log(`${this.pointStart}`);

                const op2D = this.objects2D[0];
                const op3D = this.findPlacingCounterpart(op2D); 

                if (op3D) {
                    this.objects3D.push(op3D); 

                    this.removeFilter();
                    this.state = State.MOVE;
                }
                //TODO: else show error message ? or ignore?

                break;
            }
            case State.MOVE:
                // never happens
                break;
        }

        this.handleCameraControlsEnableState();

        return null;
    }

    actionPoint (event) {
        this.logger.log('ActMove3DObject::Point');

        switch (this.state) {
            case State.SELECT:
                break;
            case State.MOVE:
                if (event.view.name === '2D Ansicht') {
                    // apply drawing scale
                    let x = event.p[0] / this.scale;
                    let y = event.p[1] / this.scale;

                    this.move3D(this.objects3D[0], x, y, 0);
                    this.move2D(this.objects2D[0], x, y, 0);

                    this.objects2D = [];
                    this.objects3D = [];
    
                    this.addPickFilter();

                    this.state = State.SELECT;
                }
                break;
        }

        this.handleCameraControlsEnableState();

        return null;
    }

    actionDynamic (event) {
        //this.logger.log('ActMove3DObject::Dynamic');

        switch (this.state) {
            case State.SELECT:
            break;

            case State.MOVE: {
                if (this.pointStart && event.view.name == '2D Ansicht') {

                    // apply drawing scale
                    let x = event.p[0] / this.scale;
                    let y = event.p[1] / this.scale;

                    this.move3D(this.objects3D[0], x, y, 0);
                    this.move2D(this.objects2D[0], x, y, 0);
                }
                break;
            }
        }

        this.handleCameraControlsEnableState();

        return null;
    }

    //TODO: OpObject interfaces: modify, usw.
    move2D(op, x, y) {
        this.logger.log(`move2D(${op.id}, ${x}, ${y})`)
        op.transform.setPosition(x, y, 0);
        theApp.model.changed2d = true; //op;
    }

    move3D(op, x, y, z) {
        op.transform.setPosition(x, y, 0);
        theApp.model.changed3d = true;
    } 

    /**
     * find the 3D part which is coupled to the 2D symbol and vice versa
     * 
     * The coupling is modeled by json attributes '@Event3DPlacing' attached to
     * the 2D and the 3D counterparts, which contain the same id. 
     * @param {*} op 
     */
    findPlacingCounterpart(op) {
        const placingId = op.getAttribute('@Event3DPlacing.id');
        if (placingId) {
            this.logger.log(`found placing.id = ${placingId}`);
            const item = this.findByPlacingId(theApp.model.space.children, placingId);
            return item;
        }
        return null;
    }

    /**
     * TODO: mit OpObject.traverse (oder find? oder forEach..) realisieren
     * @param {OpObject[]} children Model to search
     * @param {number} id Placing Id to search
     * @returns {(OpObject|null)} Returns the found OpObject or null
     */
    findByPlacingId(children, id) {
        let result = null;

        let iterateModel = (children, id) => {
            children.some((child) => {
                let placingId = child.getAttribute('@Event3DPlacing.id');
                if (placingId && placingId == id) {
                    result = child;
                    return true;
                }
                iterateModel(child.children, id);
            });
        }

        iterateModel(children, id);
        return result;
    }

    addPickFilter() {
        let pick_flags = PickFlags.IGNORE_MSL | PickFlags.NO_DRAG | PickFlags.NO_HIGHLIGHT;
        const filter = new FltPick(pick_flags);
        this.addFilter(filter);
    }

    /**
     * during drag state disable the CameraControls
     */
    handleCameraControlsEnableState() {
        switch (this.state) {
            case State.MOVE: {
                this.view2D.disableCameraControls();
                break;
            }
            default: {
                this.view2D.enableCameraControls();
                break;
            }
        }
    }
}