import * as THREE from 'three';
import { CADdySVGLoader } from '@/visual-events/loader/CADdySVGLoader.js';
import Geometry from '@/visual-events/model/Geometry.js';
import { OP_TO_THREE } from '@/visual-events/model/OpCoordinates.js';

import theApp from '@/frame/Application';
import GrfStrategy from './GrfStrategy';
import GrfUtils from './GrfUtils';
import Logger from '@/frame/Logger';


export default class GrfOpDrawing  {

  toFixed2(mat4) { mat4.elements.map(n => Number(n.toFixed(2))); }

  constructor () {
    this.logger = new Logger('GrfOpDrawing');
    this.logger.log('GrfOpModel constructor');

    this.currentTransform = new THREE.Matrix4();
    this.transformStack = [];

    // attach OpObject ids to the THREE.Group for picking
    // set this.id to enforce it in the subtree of XOpSymbols
    this.idStack = [];

    this.grfStrategy = new GrfStrategy()

    // for debug logs indentation
    this.indent = '';
    this.indentStack = [];

    // diagnostic grafic, e.g. watermark
    // reuse helper objects in order to avoid massive amounts of allocations
    this.vector = new THREE.Vector3();
    this.font = GrfUtils.loadFont('gentilis_regular.typeface.json');
  } 

  setGrfStrategy(strategy) {
      this.grfStrategy = strategy;
  }

  getGrfStrategy() {
    return this.grfStrategy;
  }

  updateScene (view) {
    //this.logger.log('GrfOpDrawing::updateScene');

    const model = view.model;

    const opRoot = view.getRoot();
    if (!opRoot)
      return;

    if (model.modifiedIn2D) {
      model.modifiedIn2D.forEach(op => this.updateItem(view, op));
      model.modifiedIn2D = [];
    }

    let view2d = theApp.findViewByName('2D Ansicht');
    let panel = theApp.findViewByName('Symbole');

    if (view === view2d && model.changed2d || view === panel && model.changedPanel) {

      view.clearScene(view);
      this.currentTransform = new THREE.Matrix4();  // identity
      this.transformStack = [];

      this.logger.log('buildScene------------------------------------------------------------------------');
      this.buildScene(view, opRoot.children);
      this.logger.log('------------------------------------------------------------------------buildScene');

      if (view === view2d)
        model.changed2d = false;

      if (view === panel)
        model.changedPanel = false;
    }
  }

  //TODO: performantere Lösung für findSceneItem suchen, mind. abbrechbares traverse (nach GrfUtils o.ä.)
  findSceneItem (view, op) {

    let found = null;
    view.scene.traverse(child => {
      if (child.userData.opId === op.id)
        found = child;
    });
    return found;
  }

  updateItem(view, op) {  

    this.logger.log(`updateItem(${op.id} ${op.type} ${op.filename})`);

    let item = this.findSceneItem(view, op);

    if (item) {

      this.logger.log(`${item.id} ${item.children.length}`);

      //terrible HACK: Symbole bekommen manchmal eine Gruppe zusätzlich (falls Attributtexte dran sind oder nicht?)
      //Stuhl -> eine Stufe weiter
      //Loge -> keine Stufe weiter
      //vielleicht weil XOpGroup im Scene-Graphen an sich  nicht abgebildet werden?
      if (item.children.length > 0)
        item = item.children[0];

      if (!op.attributes)
        return;

      //HACK: beruht auf zu vielen impliziten Voraussetzungen:
      // - op ist ein Symbol
      // - die Fläche ist opSym.children[0].children[0]
      // Die Umrandungskontur kann so gar nicht berücksichtigt werden
      this.grfStrategy.push(op);
      const opSym = view.model.symbols[op.filename];
      const fillColor = this.grfStrategy.fillColor(opSym.children[0].children[0])
      item.material.color = new THREE.Color(fillColor);
      const fillOpacity = this.grfStrategy.fillOpacity(opSym.children[0].children[0]);
      item.material.opacity = fillOpacity;
      this.grfStrategy.pop();
      
      //TODO: funktioneirt nicht richtig
      // child.matrix.copy(op.transform);
      // child.applyMatrix4(this.caddy);

    }
  }
  
  buildScene(view, children) {

    //const scope = this;

    children.forEach(op => {
      
      // the XOpDraft transform in the svg file must be neglected in the transform stack
      // it is only for correcting the coordinates in the context of 2d grafic
      // here the matrix this.caddy does the job
      //TODO: possibly the transform at XOpDraft should be removed already in CADdy2DLoader
      if (op.type != 'XOpDraft' && op.transform) {
        this.transformStack.push(this.currentTransform);
        const t = new THREE.Matrix4();
        t.copy(this.currentTransform);
        this.currentTransform = t;
        this.currentTransform.multiply(op.transform);
      }

      //this.logger.log(`${this.indent}${op.type} ${this.currentTransform.elements.map(n => Number(n.toFixed(2)))}`);
      this.indentStack.push(this.indent);
      this.indent += '  ';

      switch(op.type) {

        case 'XOpSymbol': {
          const opSym = view.model.symbols[op.filename];
          this.idStack.push(op.id);
          this.grfStrategy.push(op);

          this.buildScene(view, opSym.children);

          this.grfStrategy.pop(op);
          this.idStack.pop();

          // HACK: The transform stack is on the the level of the use-Tag, but the direct children
          // of XOpSymbol are not underlying the transform of the symbol reference!
          // TODO: find better solution for XOpSymbol children (either move OpObjects one level up on loading, 
          // or refactor transformation stack handling)
          if (op.transform) {
            this.currentTransform = this.transformStack.pop();
          }

          this.buildScene(view, op.children);
          
          if (op.transform) {
            this.transformStack.push(this.currentTransform);
            const t = new THREE.Matrix4();
            t.copy(this.currentTransform);
            this.currentTransform = t;
            this.currentTransform.multiply(op.transform);
          }
          
          break;
        }

        case 'XOpText2': {
          let origin = new THREE.Vector3();
          const [mesh, outline] = GrfUtils.createLabel(op.text, origin, op.fontSize, op.style, op.textAnchor, op.baseLine);    
          if (mesh) {
            mesh.applyMatrix4(this.currentTransform);
            mesh.applyMatrix4(OP_TO_THREE);
            mesh.userData['opId']= this.idStack.length > 0 ? this.idStack[0] : op.id;
            view.scene.add(mesh);
          }
          if (outline) {
            outline.applyMatrix4(this.currentTransform);
            outline.applyMatrix4(OP_TO_THREE);
            outline.userData['opId']= this.idStack.length > 0 ? this.idStack[0] : op.id;
            view.scene.add(outline);
          }
          break;
        }

        default: {
          const scale = Geometry.getScaleX(this.currentTransform);
          const group = this.createShapePath(op, scale);
          if (group) {
            group.applyMatrix4(this.currentTransform);
            // flip the XY-plane into the XZ-plane
            //group.rotateX(-Math.PI / 2);
            group.applyMatrix4(OP_TO_THREE);

            group.userData['opId']= this.idStack.length > 0 ? this.idStack[0] : op.id;
            view.scene.add(group);
          }

          this.buildScene(view, op.children);
          break;
        }
      }

      if (op.transform) {
        this.currentTransform = this.transformStack.pop();
      }
      this.indent = this.indentStack.pop();
    }, this);
  }

  createMaterial (color, opacity) {
    return new THREE.MeshBasicMaterial( {
        color: new THREE.Color().setStyle( color ),
        opacity: opacity,
        transparent: true,
        side: THREE.DoubleSide,
        depthWrite: false,
        wireframe: false
    } );
  }

  createShapePath(op, scale) {
    const path = op.path;
    if (!path)
      return null;

    const fillColor = this.grfStrategy.fillColor(op);
    const fillOpacity = this.grfStrategy.fillOpacity(op);

    const strokeColor = this.grfStrategy.strokeColor(op);
    const strokeOpacity = this.grfStrategy.strokeOpacity(op);

    // generate the meshes making up the grafic of each OpObject
    const group = new THREE.Group();

    if (fillColor && fillColor !== 'none' && fillOpacity && fillOpacity !== 'none') {
        const fillMaterial = this.createMaterial(fillColor, fillOpacity);

        const shapes = CADdySVGLoader.createShapes( path );

        for ( let j = 0; j < shapes.length; j ++ ) {
            const shape = shapes[ j ];
            const geometry = new THREE.ShapeGeometry( shape );
            const mesh = new THREE.Mesh( geometry, fillMaterial );
            group.add( mesh );
        }
    }

    if (strokeColor && strokeColor !== 'none' && strokeOpacity && strokeOpacity !== 'none') {
        //not LineBasicMaterial: outlines are also triangled faces created in SVGLoader.pointsToStroke
        const strokeMaterial = this.createMaterial(strokeColor, strokeOpacity);

        for ( let j = 0, jl = path.subPaths.length; j < jl; j ++ ) {
            const subPath = path.subPaths[ j ];
            // to achieve a scale independent stroke-width, apply current scale and restore afterwards
            const strokeWidth = op.style.strokeWidth;
            op.style.strokeWidth = strokeWidth / scale * 0.2; //apply magic number, too ugly otherwise
            const geometry = CADdySVGLoader.pointsToStroke( subPath.getPoints(), op.style );
            op.style.strokeWidth = strokeWidth;
            if ( geometry ) {
                const mesh = new THREE.Mesh( geometry, strokeMaterial );
                group.add( mesh );
            }
        }
    }

    return group;
  }
}
