import OpObject from '@/visual-events/model/OpObject'
import { CADdyType } from '@/visual-events/loader/CADdyType'
import OpText from '@/visual-events/model/OpText'

export default class OpReference extends OpObject {

    constructor (name, id, transform) {
        super(CADdyType.SYMBOL_REFERENCE, name);
        this.filename = id;
        this.transform = transform;
    }

    copy () {

        const copy = new OpReference(this.name, this.filename, this.transform.clone());

        //copy attribute texts
        this.children.forEach(child => {
            if (child instanceof OpText) {
                const copyText = child.copy();
                copy.add(copyText);
                if (this.observers.includes(child))
                    copy.subscribe(copyText);
            }
        });

        return copy;
    }

}
