/**
 *
 *  <>, "" or ''
 *
 * @author Mi
 */
export default class CommandLineScanner {
  constructor (commandLine) {
    this.commandLine = commandLine;
    this.args = [];

    // example:
    // commandLine = " abc.d '<xy> z' \"<z'z>\" <\"> ";
    // abc.d
    // <xy> z
    // <z'z>
    // "

    const tokens = [];

    let i = this.findStart(commandLine, 0);
    while (i !== commandLine.length) {
      let s = commandLine.charAt(i);
      let e = ' ';
      switch (s) {
        case '<': e = '>'; i++; break;
        case '\'': e = '\''; i++; break;
        case '"': e = '"'; i++; break;
        default: s = ' '; break;
      }
      const j = this.findEnd(commandLine, i, s, e);

      const token = commandLine.substring(i, j);
      tokens.push(token);

      i = this.findStart(commandLine, (s === ' ') ? j : (j + 1));
    }

    this.args = tokens;
  }

  // find first character, which is not whitespace
  // This is either an opening bracket < or " or ' or the first character of
  // the next argument
  findStart (text, from) {
    for (let i = from; i < text.length; i++) {
      const c = text.charAt(i);
      if (!this.isWhiteSpace(c)) {
        return i;
      }
    }
    return text.length;
  }

  // find the closing bracket e resp. in case of whitespace the next whitespace
  // In any case it is the position 1 behind the argument.
  findEnd (text, from, s, e) {
    if (s === ' ') {
      // skip white space
      for (let i = from; i < text.length; i++) {
        const c = text.charAt(i);
        if (this.isWhiteSpace(c)) { return i; }
      }
    } else {
      // find closing bracket
      for (let i = from; i < text.length; i++) {
        const c = text.charAt(i);
        if (c === e) {
          return i;
        }
      }
    }
    return text.length;
  }

  isWhiteSpace (c) {
    return c === ' ' || c === '\t' || c === '\n';
  }

  getCommand () { return this.args.length > 0 ? this.args[0] : null; }
  getArgs () { return this.args; }

  getArg (i) {
    if (i >= 0 && i < this.args.length) { return this.args[i]; }
    return null;
  }
}
