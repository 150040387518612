import Action from './Action.js';
import theApp from './Application.js';
import CommandLineScanner from './CommandLineScanner.js';
import * as Event from './Event.js';
import Logger from './Logger.js';

export default class FltDefault extends Action {
  constructor () {
    super();
    this.logger = new Logger('FltDefault');
  }

  actionKey (event) {
    if (!(event instanceof Event.RawKeyEvent)) { return event; }

    const raw = event.raw;

    // ESCAPE has fixed meaning 'break current actionstate'
    if (raw.type === 'keydown' && raw.key === 'Escape') { return new Event.BreakEvent(); }

    // ggfs. Hotkeys in Command-Events umwandeln
    return event;
  }

  //
  // convert RawMouseEvent to an CAD event
  actionMouse (event) {
    if (!(event instanceof Event.RawMouseEvent)) { return event; }

    const raw = event.raw;

    if (raw.type === 'mousemove') {
      const [view] = theApp.findView(raw.currentTarget);
      if (view) {
        const [x, y, z] = view.unproject(raw);
        // the CADdy++ coordinates are flipped into the XY plane
        return new Event.DynamicEvent(raw, view, x, -z, y);
      }
    }

    // left button clicked
    if (raw.type === 'mousedown' && raw.button === 0) {
      const [view] = theApp.findView(raw.currentTarget);
      if (view) {
        const [x, y, z] = view.unproject(raw);
        // the CADdy++ coordinates are flipped into the XY plane
        return new Event.PointEvent(raw, view, x, -z, y);
      }
    }

    // left button released 
    if (event.raw.type === 'mouseup' && raw.button === 0) {
      const [view] = theApp.findView(raw.currentTarget);
      if (view) {
        const [x, y, z] = view.unproject(raw);
        // the CADdy++ coordinates are flipped into the XY plane
        return new Event.PointUpEvent(raw, view, x, -z, y);
      }
    }

    // Pressing the right mouse button has fixed meaning 'break current actionstate'
    if (raw.type === 'mousedown' && raw.button === 2) { return new Event.BreakEvent(); }

    return event;
  }

  actionCommand (event) {
  // val-Events?
    const scanner = new CommandLineScanner(event.commandLine);

    const cmd = scanner.getCommand();

    if (cmd === 'val.val') { return new Event.ValueEvent(scanner.getArg(1), scanner.getArg(2)); }
    if (cmd === 'val.break') { return new Event.BreakEvent(); }
    if (cmd === 'val.point') {
      try {
        return new Event.PointEvent(
          null,
          null, // TODO:find focussed view??
          Number(scanner.getArg(1)),
          Number(scanner.getArg(2)),
          Number(scanner.getArg(3))
        );
      } catch (ex) {
        this.logger.error(event.commandLine + '\n' + ex.getMessage());
      }
    }

    return event;
  }
}
