import theApp from '@/frame/Application';

export default class GrfStrategy {

    constructor () {
        
        this.opSymbolStack = [];
        this.suppressHighlight = false;
    }

    isHighlighted(op) {
        return !this.suppressHighlight && this.bottom() ? theApp.model.selectionList.has(this.bottom()) : theApp.model.selectionList.has(op);  
    }

    fillColor(op) {
        return this.isHighlighted(op) ? theApp.model.selectionList.highlightFillColor : op.style.fill;
    }

    strokeColor(op) {
        return this.isHighlighted(op) ? theApp.model.selectionList.highlightStrokeColor : op.style.stroke;
    }

    fillOpacity(op) {
        return op.style.fillOpacity;
    }

    strokeOpacity(op) {
        return op.style.strokeOpacity;
    }

    push(op) {
        this.opSymbolStack.push(op);
    }

    pop() {
        if (this.opSymbolStack.length === 0)
            throw new RangeError("GrfStrategy.Pop: symbol stack is empty!");

        return this.opSymbolStack.pop();
    }

    top() {
        if (this.opSymbolStack.length === 0)
            return null;

        return this.opSymbolStack[this.opSymbolStack-length - 1];
    }

    bottom() {
        if (this.opSymbolStack.length === 0)
            return null;

        return this.opSymbolStack[0];
    }

}
