import { Vector2 } from 'three';

export const MouseButtonFlags = Object.freeze({
    LEFT_BUTTON:    0x0001,
    RIGHT_BUTTON:   0x0002,
    MIDDLE_BUTTON:  0x0004,
});

export default class SelectionFrame {

    constructor(selectionBox, renderer, cssClassName, mouseBtnFlags, conncet = true) {

        this.element = document.createElement( 'div' );
        this.element.classList.add( cssClassName );
        this.element.style.pointerEvents = 'none';

        this.renderer = renderer;

        this.startPoint = new Vector2();
        this.pointTopLeft = new Vector2();
        this.pointBottomRight = new Vector2();

        this.mouseBtnFlags = mouseBtnFlags;
        this.conncet = conncet

        this.isDown = false;

        this.onPointerDown = function ( event ) {

            if (this.checkMouse(event))
            {
                this.isDown = true;
                this.onSelectStart( event );
            }
        }.bind( this );

        this.onPointerMove = function ( event ) {

            if ( this.isDown )
            {
                this.onSelectMove( event );
            }
        }.bind( this );

        this.onPointerUp = function ( ) {

            if ( this.isDown )
            {
                this.isDown = false;
                this.onSelectOver();
            }
        }.bind( this );

        if (this.conncet)
        {
            this.renderer.domElement.addEventListener( 'pointerdown', this.onPointerDown );
            this.renderer.domElement.addEventListener( 'pointermove', this.onPointerMove );
            this.renderer.domElement.addEventListener( 'pointerup',   this.onPointerUp );
        }
    }

    dispose() {
        if (this.conncet)
        {
            this.renderer.domElement.removeEventListener( 'pointerdown', this.onPointerDown );
            this.renderer.domElement.removeEventListener( 'pointermove', this.onPointerMove );
            this.renderer.domElement.removeEventListener( 'pointerup',   this.onPointerUp );
        }
    }

    checkMouse(event) {
        return (event.buttons & this.mouseBtnFlags);
    }

    onSelectStart( event ) {
        this.renderer.domElement.parentElement.appendChild( this.element );

        this.element.style.left   = event.clientX + 'px';
        this.element.style.top    = event.clientY + 'px';
        this.element.style.width  = '0px';
        this.element.style.height = '0px';

        this.startPoint.x = event.clientX;
        this.startPoint.y = event.clientY;
    }

    onSelectMove( event ) {
        this.pointBottomRight.x = Math.max( this.startPoint.x, event.clientX );
        this.pointBottomRight.y = Math.max( this.startPoint.y, event.clientY );
        this.pointTopLeft.x     = Math.min( this.startPoint.x, event.clientX );
        this.pointTopLeft.y     = Math.min( this.startPoint.y, event.clientY );

        this.element.style.left   = this.pointTopLeft.x + 'px';
        this.element.style.top    = this.pointTopLeft.y + 'px';
        this.element.style.width  = ( this.pointBottomRight.x - this.pointTopLeft.x ) + 'px';
        this.element.style.height = ( this.pointBottomRight.y - this.pointTopLeft.y ) + 'px';
    }

    onSelectOver() {
        this.element.parentElement.removeChild( this.element );
    }
}

