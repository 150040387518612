export default class Logger {

    static logging = false;
    static loggers = {};

    //TODO: use static factory method instead of constructor, reuse logger with context, if exists

    constructor(context, active = undefined) {
        this.context = context;
        this.active = active === undefined ? Logger.logging : active;

        Logger.loggers[context] = this;
    }

    log(message, ...optionalParams) {
        if (this.active) console.log(`[${this.context}] ${message}`, ...optionalParams);
    }

    warn(message, ...optionalParams) {
        if (this.active) console.warn(`[${this.context}] ${message}`, ...optionalParams);
    }

    error(message, ...optionalParams) {
        if (this.active) console.error(`[${this.context}] ${message}`, ...optionalParams);
    }

    static setLogging(status) {
        Logger.logging = status;
    }

    static listLoggers() {
        console.log(Object.keys(Logger.loggers));
    }

    /**
     * Activate loggers
     * @param {string[]|null} contexts Array of contexts. If null, set status for every context
     */
    static activate(contexts = null) {
        Object.values(Logger.loggers).forEach(l => { 
            if (contexts == null || (contexts != null && contexts.includes(l.context))) {
                l.active = true;
            }
        });
    }

    /**
     * Deactivate loggers
     * @param {string[]|null} contexts Array of contexts. If null, set status for every context
     */
     static deactivate(contexts = null) {
        Object.values(Logger.loggers).forEach(l => {
            if (contexts == null || (contexts != null && contexts.includes(l.context))) {
                l.active = false;
            }
        });
    }
}