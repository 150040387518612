import Action from '@/frame/Action';

import OpModel from '@/visual-events/model/OpModel'
import VisualEvents2DView from '@/visual-events/view/VisualEvents2DView';
import VisualEvents3DView from '@/visual-events/view/VisualEvents3DView';

import CommandPoolVisualEvents from './CommandPoolVisualEvents';
import CommandPoolView from './CommandPoolView';

import theApp from '@/frame/Application';
import Logger from '@/frame/Logger';

import Ticketing from '@/visual-events/ticketing/Ticketing';
import config from '@/visual-events/ticketing/Config';

class VisualEvents {

  constructor () {
    this.logger = new Logger('VisualEvents');
    // this.urlFileService = 'https://files.datasolid.com/api';
    // this.urlTicketing = 'https://ticketing.datasolid.com/api';
    this.urlFileService = 'https://api.visualevents.app/dev';
    this.urlTicketing = 'https://api.visualevents.app/dev';
    this.apiKey = undefined;
    this.language = 'de';
    this.opts = {
      ticketing: config
    };
  }

  // interface AddIn
  init (opts) {
    this.logger.log(`VisualEvents::init(${JSON.stringify(opts)})`);

    //merge new options into the existing
    //TODO: merge partial options
    this.opts = { ...this.opts, ...opts };
    this.logger.log(`effective options:`);
    for (const [key, value] of Object.entries(this.opts))
      this.logger.log(`  ${key}: ${value}`);

    //configure the service urls to apply
    if (opts.urlTicketing)
        this.urlTicketing = opts.urlTicketing;
    if (opts.urlFileService)
        this.urlFileService = opts.urlFileService;
    if (opts.apiKey)
        this.apiKey = opts.apiKey;
    if (opts.language)
        this.language = opts.language;
    if (opts.logging)
        Logger.setLogging(opts.logging);
    if (opts.onClickBookableId)
        this.onClickBookableId = opts.onClickBookableId;
    if (opts.onHover)
        this.onHover = opts.onHover;

    if (!theApp.model) {
      const model = new OpModel();
      theApp.setModel(model);

      theApp.addView(new VisualEvents2DView('2D Ansicht', theApp.model));
      theApp.addView(new VisualEvents3DView('3D Ansicht', theApp.model));
      theApp.addView(new VisualEvents2DView('Symbole', theApp.model));

      this.commandPoolVisualEvents = new CommandPoolVisualEvents();
      this.commandPoolView = new CommandPoolView();

      Action.stopAll();

      theApp.startAnimation();
    }
  }

  async loadTicketingPlan(opts) {
    const urlModel = await Ticketing.getPlanForEvent(opts.eventId);
    if (urlModel)
      theApp.executeCommand(`.VisualEvents.load2D ${urlModel} ${opts.action} ${opts.eventId} ${opts.cartId} ${opts.allStates} ${this.apiKey} ${this.language}`);
  }

  // interface AddIn
  exit () {
    this.logger.log('VisualEvents::exit()');

    //stopAnimation(); ?

    Action.stopAll();

    // CommandPool.root.remove(this.commandPoolVisualEvents);
    // CommandPool.root.remove(this.commandPoolView);

    // remove views ?

    // remove model ?
  }
}

export default new VisualEvents();
